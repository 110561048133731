import React, { useEffect } from 'react';

import './Step4.pcss';
import { Icon } from '../../components/icons';
import { Tiles } from '../../components/Tiles';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { updateLastPageReached } from '../../store/userSlice';

const Step4Thanks: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isMobile = useIsMobile();

  // Update user state
  const lastPageReached = useSelector(
    (state: RootState) =>
      state.authenticatedUser?.installationDetails?.lastPageReached
  );

  useEffect(() => {
    if (!lastPageReached) {
      dispatch(updateLastPageReached(true));
    }
  }, [lastPageReached, dispatch]);

  const handleButtonClick = () =>
    (window.location.href = 'https://chicory.co/our-work');

  // Assembly order depends on isMobile
  const sentence1 = (
    <span className="sentence1">{`
     We will verify everything on our end and reach out to you 
     when you’re ready to start. Typically within an hour.
    `}</span>
  );
  const header = (
    <h1 className="header">{`Thank you for completing registration`}</h1>
  );
  const icon = (
    <div className="icon-holder">
      <Icon
        name="utensils"
        style={{
          width: isMobile ? '103px' : '150px',
          height: isMobile ? '103px' : '150px',
          marginRight: isMobile ? '20px' : '0',
          marginLeft: isMobile ? '5px' : '0',
        }}
      />
    </div>
  );
  // End small components

  return (
    <div id="onboarding-step4-container">
      {isMobile ? null : (
        <h2 className="getting-started__header">
          Welcome to the Chicory Network!
        </h2>
      )}
      <div className="broad-container">
        {isMobile ? null : icon}
        <div className="main-flex-column">
          {header}
          <div>
            {isMobile ? (
              <div className="mobile-icon-and-line-break">
                {icon}
                {sentence1}
              </div>
            ) : (
              sentence1
            )}
            <p style={isMobile ? { marginTop: '5px' } : {}}>
              In the meantime, check out our{' '}
              <a
                href="https://chicory.co/our-work"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'underline' }}
              >
                resources
              </a>{' '}
              for ideas on how you can earn more with your recipes.
            </p>
          </div>

          <p>
            If you have any questions or need support with installation, please
            reach out to{' '}
            <a
              href="mailto:publishers@chicory.co"
              target="_blank"
              rel="noreferrer noopener"
              className="blue-link"
            >
              publishers@chicory.co
            </a>
            .
          </p>

          <Tiles
            style={{
              width: '100%',
              height: 'auto',
              border: 'none',
              justifyContent: 'space-between',
              flexDirection: isMobile ? 'column-reverse' : 'row',
              marginTop: '20px',
              marginBottom: '90px',
            }}
          >
            <Tiles.Button
              version="darkPurple"
              style={{
                width: isMobile ? '100%' : '180px',
                height: '45px',
                fontSize: '16px',
                fontWeight: 400,
                border: 'none',
                marginTop: '15px',
              }}
              onClick={handleButtonClick}
            >
              Chicory Resources
            </Tiles.Button>
          </Tiles>
        </div>
      </div>
    </div>
  );
};

export default Step4Thanks;

import chicoryCreatorImageUrl from '../../assets/images/chicory-creator.jpg';
import chicoryLogoWhite from '../../assets/images/chicory-logo-flat-white.png';
import './main.pcss';

import React, { useEffect, useState } from 'react';
import Creators from './Creators';
import SignupForm from './Form';
import { Icon } from '../../components/icons';

import debounce from 'debounce';
import { apiUrl, cdnUrl } from '../../config';
const goToAuth0Login = debounce((email: string) => {
  window.location.href = `${apiUrl}/auth/login?login_hint=${encodeURIComponent(email)}&redirectUrl=${encodeURIComponent(cdnUrl + '/onboarding/step1')}`;
}, 500);

const SuccessfulSignupCard: React.FC<{ email: string }> = ({ email }) => {
  useEffect(() => {
    setTimeout(() => {
      goToAuth0Login(email);
    }, 2000);
  }, [email]);

  return (
    <div id="successful-signup-card">
      <Icon width="200px" height="200px" name="groceries" />
      <h1>
        Thank you for your interest!
        <span>Redirecting you to get Chicory ready on your site</span>
      </h1>
      <div id="spinner-container">
        <div id="spinner"></div>
      </div>
      <h3>
        <span
          className="link-esque"
          onClick={() => {
            goToAuth0Login(email);
          }}
        >
          or click here to proceed.
        </span>
      </h3>
    </div>
  );
};

const SignupPage: React.FC = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [signupSuccessEmail, setSignupSuccessEmail] = useState<string | null>(
    null
  );

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 200);
  }, []);

  useEffect(() => {
    document.title = 'Become a Recipe Partner';
  }, []);

  return (
    <main id="sign-up-page" style={{ opacity: loaded ? 1 : 0 }}>
      {signupSuccessEmail && (
        <section id="form-section">
          <div id="form-wrapper">
            <SuccessfulSignupCard email={signupSuccessEmail} />
          </div>
        </section>
      )}
      {!signupSuccessEmail && (
        <section id="form-section">
          <div id="form-wrapper">
            <div id="form-heading">
              <h3>Sign up</h3>
              <p>
                Sign up below to join our network. No minimum site traffic
                required.
              </p>
            </div>
            <SignupForm onSuccessfulSignup={setSignupSuccessEmail} />
            <p id="have-account-prompt">
              Already have an account?{' '}
              <a
                href="https://prod-network.chicoryapp.com/dashboard/login/"
                target="_blank"
                rel="noreferrer"
              >
                Log in
              </a>
            </p>
          </div>
        </section>
      )}
      <section id="hero-section">
        <div id="hero-image-background">
          <img src={chicoryCreatorImageUrl} />
          <div id="purple-velvet" />
        </div>
        <div id="hero-content">
          <img id="hero-chicory-logo" src={chicoryLogoWhite} />
          <h2>
            Start monetizing <br />
            your recipes today.
          </h2>
          <p>
            Join our network of food publishers and recipe sites to monetize
            your content with in-recipe ad placements and make your recipes
            shoppable at 70+ retailers.
          </p>
          <Creators />
        </div>
      </section>
    </main>
  );
};

export default SignupPage;

import { createReducer, createAsyncThunk } from '@reduxjs/toolkit';

import { RaByPublisherOrRetailer } from '../types';
import { RootState } from '.';
import { chicoryFetcher, fetchUrlFormatter, getValidPubs } from '../utils';

const initialRaByRetailer: RaByPublisherOrRetailer = [];

export const fetchRaByRetailer = createAsyncThunk<
  RaByPublisherOrRetailer,
  number,
  { state: RootState }
>('FETCH_RA_BY_RETAILER', async (limit, { getState }) => {
  const state = getState();

  const pubs = getValidPubs({
    selectedPubs: state.pubs.selectedPubs,
    availablePubs: state.pubs.availablePubs,
  });
  const path = '/reporting/ra/retailer';

  try {
    if (state.dateRange.from === null || state.dateRange.to === null) {
      throw new Error('Date range in store is unexpectedly null.');
    }
    const data = await chicoryFetcher(
      fetchUrlFormatter({
        path: path,
        from: state.dateRange.from,
        to: state.dateRange.to,
        pubs: pubs,
        limit: limit,
      })
    ).then((response) => response.json());
    return data;
  } catch (error) {
    console.error('fetchRaByRetailer error.', error);
  }
});

export const raByRetailerReducer = createReducer<RaByPublisherOrRetailer>(
  initialRaByRetailer,
  (builder) => {
    builder.addCase(fetchRaByRetailer.fulfilled, (state, action) => {
      return action.payload;
    });
  }
);

import React from 'react';
import { formatAsUsd } from '../utils';

type TooltipProps = {
  active?: boolean;
  payload?: { value: number }[];
  label?: string;
};

const DateAndCurrencyValueTooltip: React.FC = ({
  active,
  payload,
  label,
}: TooltipProps) => {
  if (label && active && payload && payload[0]) {
    return (
      <div
        style={{
          padding: '0.5rem',
          backgroundColor: 'white',
          border: '1px solid #ccc',
        }}
      >
        <span>{new Date(label).toLocaleDateString()}</span>
        <br />
        <span>{formatAsUsd(payload[0].value)}</span>
      </div>
    );
  }
  return null;
};

export default DateAndCurrencyValueTooltip;

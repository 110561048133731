import React from 'react';
import './Form.pcss';
import { ChildrenProps, FormComponent } from '../types';

// prettier-ignore
export const Form: FormComponent<ChildrenProps> = ({ children, ...props }: ChildrenProps) => {
  return <div className="form" {...props}>{children}</div>;
};
// prettier-ignore
Form.Header = function Header({ children, ...props }: ChildrenProps) {
  return <div className="form__header" {...props}>{children}</div>;
};
// prettier - ignore;
Form.HeaderLeft = function HeaderLeft({ children, ...props }: ChildrenProps) {
  return (
    <div className="form__header__left" {...props}>
      {children}
    </div>
  );
};
// prettier-ignore
Form.HeaderRight = function HeaderRight({ children, ...props }: ChildrenProps) {
  return <div className="form__header__right" {...props}>{children}</div>;
};
// prettier-ignore
Form.TitleLarge = function TitleLarge({ children, ...props }: ChildrenProps) {
  return <div className="form__title form__title--large" {...props}>{children}</div>;
}
// prettier-ignore
Form.TitleSmall = function TitleSmall({ children, ...props }: ChildrenProps) {
  return <div className="form__title form__title--small" {...props}>{children}</div>;
}
// prettier-ignore
Form.Body = function Body({ children, ...props }: ChildrenProps) {
  return <div className="form__body" {...props}>{children}</div>;
}
// prettier-ignore
Form.Label = function Label({ children, ...props }: ChildrenProps) {
  return <div className="form__label" {...props}>{children}</div>;
}
// prettier-ignore
Form.InputContainer = function Input({ children, ...props }: ChildrenProps) {
  return <div className="form__input-container" {...props}>{children}</div>;
}

import './CheckboxSquare.pcss';

import React from 'react';
export const CheckboxSquare: React.FC<{
  toggleSelect: () => void;
  selected: boolean;
}> = ({ toggleSelect, selected }) => {
  return (
    <div
      className={
        selected ? 'chicory-checkbox checkbox--checked' : 'chicory-checkbox'
      }
      onClick={() => {
        toggleSelect();
      }}
    />
  );
};

import {
  createReducer,
  createAction,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { AdsByHistorical } from '../types';
import { RootState } from '.';

import { chicoryFetcher, fetchUrlFormatter, getValidPubs } from '../utils';

// Action to update Store when fetching in components.
export const setAdsByHistorical = createAction<
  AdsByHistorical,
  'SET_ADS_BY_HISTORICAL'
>('SET_ADS_BY_HISTORICAL');

const initialAdsByHistorical: AdsByHistorical = [];

// Thunk.
export const fetchAdsHistorical = createAsyncThunk<
  AdsByHistorical,
  number,
  { state: RootState }
>('FETCH_ADS_HISTORICAL', async (limit, { getState }) => {
  const state = getState();
  // TODO local storage optimization?

  const pubs = getValidPubs({
    selectedPubs: state.pubs.selectedPubs,
    availablePubs: state.pubs.availablePubs,
  });

  if (state.dateRange.from === null || state.dateRange.to === null) {
    throw new Error('Date range in store is unexpectedly null.');
  }
  const path = '/reporting/ads/historical';
  const data: AdsByHistorical = await chicoryFetcher(
    fetchUrlFormatter({
      path,
      from: state.dateRange.from,
      to: state.dateRange.to,
      pubs: pubs,
      limit: limit,
    })
  ).then((response) => response.json());
  return data;
});

export const adsByHistoricalReducer = createReducer<AdsByHistorical>(
  initialAdsByHistorical,
  (builder) => {
    builder.addCase(fetchAdsHistorical.fulfilled, (state, action) => {
      return action.payload;
    });
  }
);

import React, { useMemo } from 'react';

import { VictoryPie } from 'victory';

import './PieChart.pcss';
import { AdsByPlacement } from '../types';
import { formatAsPercentNoDecimals } from '../utils';

export const PieChart: React.FC<{ data: AdsByPlacement }> = ({ data }) => {
  const colors = [
    '#6AE5BD',
    '#EB4978',
    '#F7CC53',
    '#9C27B0',
    '#3F51B5',
    '#673AB7',
    '#2196F3',
    '#E91E63',
    '#FFC107',
    '#FF9800',
    '#FF5722',
    '#F44336',
  ];

  const totalRevenue = useMemo(() => {
    return data.reduce((acc, placement) => {
      return acc + +placement.revenue;
    }, 0);
  }, [data]);

  return (
    <div className="pie-chart">
      <div className="pie-chart__pie">
        <VictoryPie
          colorScale={colors}
          labels={() => null}
          padding={0}
          data={data.map((placement) => {
            return { y: +placement.revenue };
          })}
        />
      </div>
      <div className="pie-chart__legend">
        {data.map((placement, index) => {
          return (
            <div className="pie-chart__legend--item" key={`legend-${index}`}>
              <div
                className="pie-chart__legend--color"
                style={{ backgroundColor: colors[index] }}
              />

              <div className="pie-chart__legend--label">
                <div className="text-truncator">{placement.name}</div>
              </div>

              <div className="w-spacer" />
              <div className="pie-chart__legend--percent">
                {formatAsPercentNoDecimals(+placement.revenue / totalRevenue)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#admin-users {
  display: flex;
  flex-direction: column;
}
#admin-users-nav {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 1rem 0;
}
#admin-users-nav__left {
    display: flex;
    flex-direction: column;
  }
#admin-users-nav__left a {
      height: 100%;
      display: flex;
      align-items: center;
    }
#admin-users-nav__left--header {
      font-size: 1.5rem;
    }
#admin-users-nav__left--subheader {
      font-size: 1rem;
      line-height: 1.5;
      color: gray;
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
    }
#admin-users-nav__right {
    display: flex;
  }
`, "",{"version":3,"sources":["webpack://./src/components/AdminUsersNav.pcss"],"names":[],"mappings":"AAKA;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,aAAa;EACb,iBAAiB;EACjB,8BAA8B;EAC9B,cAAc;AA4BhB;AA1BE;IACE,aAAa;IACb,sBAAsB;EAmBxB;AAjBE;MACE,YAAY;MACZ,aAAa;MACb,mBAAmB;IACrB;AAEA;MACE,iBAAiB;IACnB;AAEA;MACE,eAAe;MACf,gBAAgB;MAChB,WAAW;MACX,mBAAmB;MACnB,sBAAsB;IACxB;AAGF;IACE,aAAa;EACf","sourcesContent":["$verticalGap: 20px;\n$mediascreen: 990px;\n$horizontalGap: 15px;\n$gridGap: 12px;\n\n#admin-users {\n  display: flex;\n  flex-direction: column;\n}\n#admin-users-nav {\n  display: flex;\n  flex-wrap: nowrap;\n  justify-content: space-between;\n  margin: 1rem 0;\n\n  &__left {\n    display: flex;\n    flex-direction: column;\n\n    a {\n      height: 100%;\n      display: flex;\n      align-items: center;\n    }\n\n    &--header {\n      font-size: 1.5rem;\n    }\n\n    &--subheader {\n      font-size: 1rem;\n      line-height: 1.5;\n      color: gray;\n      margin-top: 0.25rem;\n      margin-bottom: 0.25rem;\n    }\n  }\n\n  &__right {\n    display: flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { useDispatch } from 'react-redux';

import { AppDispatch } from '../store';
import { Report } from '../types';
import { deleteScheduledReport } from '../store/scheduleData';
import './DeleteConfirm.pcss';
import { Card } from '../components/Card';

import { Icon } from './icons';
import { Tiles } from './Tiles';
import { deletingSchedule } from '../store/scheduleModals';

export const DeleteConfirm = ({
  currentSchedule,
}: {
  currentSchedule: Report;
}) => {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <Card id="delete-confirm">
      <Card.Header>
        <Card.HeaderLeft>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'yellow',
              height: '50px',
              width: '50px',
              borderRadius: '50%',
            }}
          >
            <Icon
              name="alert-circle"
              style={{ height: '25px', width: '25px' }}
            />
          </div>
        </Card.HeaderLeft>
        <Card.HeaderRight>
          <Icon
            name="x"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              dispatch(deletingSchedule(null));
            }}
          />
        </Card.HeaderRight>
      </Card.Header>
      <Card.Body
        style={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          height: '80px',
          justifyContent: 'space-evenly',
        }}
      >
        <Card.TitleSmall>
          Are you sure you want to delete this scheduled report?
        </Card.TitleSmall>
        <Card.TitleSmall style={{ fontWeight: '100', fontSize: '16px' }}>
          This action cannot be undone.
        </Card.TitleSmall>
      </Card.Body>
      <Card.Header>
        <Tiles
          style={{
            width: '100%',
            height: '45px',
            border: 'none',
            justifyContent: 'space-between',
          }}
        >
          <Tiles.Button
            version="white"
            style={{
              width: '48%',
              fontSize: '16px',
            }}
            onClick={() => {
              dispatch(deletingSchedule(null));
            }}
          >
            Cancel
          </Tiles.Button>
          <Tiles.Button
            version="red"
            style={{
              width: '48%',
              fontSize: '16px',
            }}
            onClick={() => {
              dispatch(deleteScheduledReport(currentSchedule.id));
            }}
          >
            Delete
          </Tiles.Button>
        </Tiles>
      </Card.Header>
    </Card>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pie-chart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
}

.pie-chart__pie {
  max-height: 48%;
  width: 36%;
}

.pie-chart__legend {
  width: 55%;
  display: flex;
  flex-direction: column;
  padding-left: 1vw;
  font-weight: 100;
}

.pie-chart__legend--item {
    display: flex;
    align-items: center;
    width: 100%;
  }

.pie-chart__legend--color {
    width: 1.5vw;
    height: 0.7vw;
    margin-top: 1px;
    margin-right: 1vw;
  }

.pie-chart__legend--label {
    font-size: clamp(0.8rem, 1.1vw, 1.5rem);
    width: 40%;
  }

.pie-chart__legend--percent {
    font-size: clamp(0.8rem, 1vw, 1.5rem);
    color: #067647;
  }

@media (max-width: 1100px) {
  .pie-chart {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .pie-chart__legend {
    width: 100%;
    padding-left: 0;
    margin-top: 1vh;
  }

    .pie-chart__legend--item {
      justify-content: space-between;
      width: 100%;
    }
}

@media screen and (min-width: 1440px) {
  .pie-chart__pie {
    max-height: 33%;
    width: 25%;
    margin-left: 2vw;
  }
}
@media screen and (min-width: 2000px) {
  .pie-chart__pie {
    max-height: 33%;
    width: 15%;
    margin-left: 3vw;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/PieChart.pcss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,gBAAgB;AAoBlB;;AAnBE;IACE,aAAa;IACb,mBAAmB;IACnB,WAAW;EACb;;AACA;IACE,YAAY;IACZ,aAAa;IACb,eAAe;IACf,iBAAiB;EACnB;;AACA;IACE,uCAAuC;IACvC,UAAU;EACZ;;AACA;IACE,qCAAqC;IACrC,cAAc;EAChB;;AAGF;EACE;IACE,sBAAsB;IACtB,mBAAmB;IACnB,8BAA8B;EAChC;;EAEA;IACE,WAAW;IACX,eAAe;IACf,eAAe;EAMjB;;IAJE;MACE,8BAA8B;MAC9B,WAAW;IACb;AAEJ;;AAEA;EACE;IACE,eAAe;IACf,UAAU;IACV,gBAAgB;EAClB;AACF;AACA;EACE;IACE,eAAe;IACf,UAAU;IACV,gBAAgB;EAClB;AACF","sourcesContent":[".pie-chart {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n  padding-bottom: 20px;\n}\n\n.pie-chart__pie {\n  max-height: 48%;\n  width: 36%;\n}\n\n.pie-chart__legend {\n  width: 55%;\n  display: flex;\n  flex-direction: column;\n  padding-left: 1vw;\n  font-weight: 100;\n  &--item {\n    display: flex;\n    align-items: center;\n    width: 100%;\n  }\n  &--color {\n    width: 1.5vw;\n    height: 0.7vw;\n    margin-top: 1px;\n    margin-right: 1vw;\n  }\n  &--label {\n    font-size: clamp(0.8rem, 1.1vw, 1.5rem);\n    width: 40%;\n  }\n  &--percent {\n    font-size: clamp(0.8rem, 1vw, 1.5rem);\n    color: #067647;\n  }\n}\n\n@media (max-width: 1100px) {\n  .pie-chart {\n    flex-direction: column;\n    align-items: center;\n    justify-content: space-between;\n  }\n\n  .pie-chart__legend {\n    width: 100%;\n    padding-left: 0;\n    margin-top: 1vh;\n\n    &--item {\n      justify-content: space-between;\n      width: 100%;\n    }\n  }\n}\n\n@media screen and (min-width: 1440px) {\n  .pie-chart__pie {\n    max-height: 33%;\n    width: 25%;\n    margin-left: 2vw;\n  }\n}\n@media screen and (min-width: 2000px) {\n  .pie-chart__pie {\n    max-height: 33%;\n    width: 15%;\n    margin-left: 3vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { ChildrenProps } from '../types';
import './TextBubble.pcss';

export const TextBubble: React.FC<ChildrenProps & { color: string }> = ({
  children,
  ...props
}) => {
  const { color } = props;
  return (
    <div className={`text-bubble text-bubble--${color}`} {...props}>
      {children}
    </div>
  );
};

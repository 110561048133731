import React from 'react';

import '../components/DataTable.pcss';

export const faqsContent = [
  {
    label: 'How can I set up and manage automated reporting?',
    content: (
      <div>
        <p>
          You can schedule automated reports to be delivered via email or S3
          access by clicking on the ‘Schedule reports’ button. For email, you
          will be able to specify the frequency of delivery, date range, and
          recipients of the report. For S3 buckets, you must provide Chicory
          with an Access Key, Secret Access Key, and the Bucket Path. Please
          reach out to{' '}
          <a href="mailto:support@chicory.co">support@chicory.co</a> for
          assistance setting up access to your platform’s S3 bucket.
        </p>
        <p>
          Once a report is scheduled, you can adjust any report details in the
          Manage Reports tab.
        </p>
      </div>
    ),
  },
  {
    label: 'I have multiple sites, can I see the total data?',
    content: (
      <div>
        <p>
          Yes, users will be able to see total data for all sites. Users can
          select one, multiple, or all sites by using the “domain filter” tool
          at the top-right corner of the page.
        </p>
      </div>
    ),
  },
  {
    label: 'When is the reporting updated?',
    content: (
      <div>
        <p>Yesterday’s data will be updated by 12 PM daily.</p>
      </div>
    ),
  },
  {
    label: 'What are the details of your payment policy for blogging partners?',
    content: (
      <div>
        <p>Our Payment Policy</p>
        <p>
          Blogging partners who are working with Chicory directly will be paid
          in full, every month, on a net-60 schedule. Please note, Chicory does
          not issue 1099s directly.
        </p>
        <p>
          Payments will be made via PayPal to the associated email address
          provided during sign-up. If you did not submit a PayPal address during
          sign-up or are unsure, please contact{' '}
          <a href="mailto:support@chicory.co">support@chicory.co</a>. All
          payments will be sent out through PayPal unless otherwise discussed.
        </p>
        <p>
          If you once worked directly with Chicory, but have switched to using
          Chicory via Mediavine, any existing balance on your Chicory account
          (the money accumulated prior to your switch) will be paid to you by
          our team during the next pay period.
        </p>
        <p>
          If you have any questions please feel free to reach out to{' '}
          <a href="mailto:publishers@chicory.co">publishers@chicory.co</a>. You
          can also find more details in our{' '}
          <a
            href="https://chicory.co/terms-of-services"
            target="_blank"
            rel="noopener noreferrer"
          >
            terms
          </a>
          .
        </p>
      </div>
    ),
  },
  {
    label: 'When will I receive my payment for being a publisher partner?',
    content: (
      <div>
        <p>
          We pay our publisher partners on a net-60 schedule. This means you
          will receive payment 60 days after it is earned. For example, August
          2024 earnings will be delivered by October 31, 2024. If you have any
          questions about our payment schedule, please reach out to{' '}
          <a href="mailto:publishers@chicory.co">publishers@chicory.co</a>
        </p>
      </div>
    ),
  },
  {
    label: 'I have a new PayPal account, how can I update this with Chicory?',
    content: (
      <div>
        <p>
          If you need to update your PayPal account, please reach out to{' '}
          <a href="mailto:support@chicory.co">support@chicory.co</a> for
          assistance. We are actively working on enabling the option to update
          your PayPal email directly within the Partner Portal.
        </p>
      </div>
    ),
  },
  {
    label: 'Am I able to opt-out of Chicory Premium?',
    content: (
      <div>
        <p>
          While Chicory does not recommend opting out of Chicory Premium as it
          will minimize your earnings, you may reach out to{' '}
          <a href="mailto:publishers@chicory.co">publishers@chicory.co</a> for
          support on disabling Premium.
        </p>
      </div>
    ),
  },
  {
    label: 'What opt-out settings are available for Chicory Premium campaigns?',
    content: (
      <div>
        <p>
          While Chicory does not recommend opting out of Chicory Premium
          campaigns as it will affect your earnings, we understand that it is
          necessary under certain circumstances. See below for available opt-out
          solutions:
        </p>
        <ul>
          <li>
            <strong>Campaign-based opt-out:</strong> This option allows you to
            opt out of advertisements based on the promoted brand. To view a
            list of upcoming Chicory Premium campaigns that may appear on your
            site, please visit{' '}
            <a
              href="https://chicory.co/monthly-campaigns"
              target="_blank"
              rel="noopener noreferrer"
            >
              this page
            </a>
            . If you’d like to opt-out of a specific campaign, please reach out
            to <a href="mailto:publishers@chicory.co">publishers@chicory.co</a>.
          </li>
          <li>
            <strong>Specific post opt-outs:</strong> This option allows you to
            opt out specific recipes on your site from featuring Chicory
            advertising. If you’d like to opt-out of a specific recipe, please
            reach out to{' '}
            <a href="mailto:publishers@chicory.co">publishers@chicory.co</a>.
          </li>
        </ul>
      </div>
    ),
  },
  {
    label: 'What is Chicory Premium?',
    content: (
      <div>
        <p>
          Chicory Premium ads are exclusive in-recipe ad placements served
          directly within the recipe card. Ads are contextually targeted to
          complement the recipe a user is viewing. Chicory supports two Premium
          ad formats: in-lines and pairings.
        </p>
        <p>
          Premium In-line ads promote products any time they appear as
          ingredients across our network.
        </p>
        <p>
          Premium Pairings ads align brands with recipes based on lifestyle,
          occasion, seasonality and more.
        </p>
      </div>
    ),
  },
  {
    label: 'What is Recipe Activation?',
    content: (
      <div>
        <p>
          Recipe Activation enables your site visitors to seamlessly cart
          ingredients for full recipes at 70+ integrated retailers. Our
          Shoppable Recipe Button appears below the recipe card and can be
          customized to your branding. Our standard purple button includes the
          CTA “Get Ingredients” and users can click it to select their preferred
          retailer.
        </p>
      </div>
    ),
  },
];

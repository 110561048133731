// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.light-text {
  color: #8b8b8b;
}
`, "",{"version":3,"sources":["webpack://./src/components/DataTablePagination.pcss"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB","sourcesContent":[".light-text {\n  color: #8b8b8b;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { createReducer, createAsyncThunk } from '@reduxjs/toolkit';

import { AdsByPlacement } from '../types';
import { chicoryFetcher, fetchUrlFormatter, getValidPubs } from '../utils';
import { RootState } from '.';

const initialAdsByPlacement: AdsByPlacement = [];

// Thunk
export const fetchAdsByPlacement = createAsyncThunk<
  AdsByPlacement,
  number,
  { state: RootState }
>('FETCH_ADS_BY_PLACEMENT', async (limit, { getState }) => {
  const state = getState();

  const pubs = getValidPubs({
    selectedPubs: state.pubs.selectedPubs,
    availablePubs: state.pubs.availablePubs,
  });
  const path = '/reporting/ads/placement';

  try {
    if (state.dateRange.from === null || state.dateRange.to === null) {
      throw new Error('Date range in store is unexpectedly null.');
    }
    const data = await chicoryFetcher(
      fetchUrlFormatter({
        path: path,
        from: state.dateRange.from,
        to: state.dateRange.to,
        pubs: pubs,
        limit: limit,
      })
    ).then((response) => response.json());
    return data;
  } catch (error) {
    console.error('fetchAdsByPlacement error.', error);
  }
});

export const adsByPlacementReducer = createReducer<AdsByPlacement>(
  initialAdsByPlacement,
  (builder) => {
    builder.addCase(fetchAdsByPlacement.fulfilled, (state, action) => {
      // Product wants only In-lines and Pairings data, additional
      // categories will confuse the user.
      const placementsAndInlines = action.payload.filter((placement) => {
        return placement.name === 'In-lines' || placement.name === 'Pairings';
      });

      return placementsAndInlines;
    });
  }
);

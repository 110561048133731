import { createReducer, createAsyncThunk } from '@reduxjs/toolkit';

import { RaByRecipe } from '../types';
import { RootState } from '.';
import { chicoryFetcher, fetchUrlFormatter, getValidPubs } from '../utils';

const initialRaByRecipe: RaByRecipe = [];

export const fetchRaByRecipe = createAsyncThunk<
  RaByRecipe,
  number,
  { state: RootState }
>('FETCH_RA_BY_RECIPE', async (limit, { getState }) => {
  const state = getState();

  const pubs = getValidPubs({
    selectedPubs: state.pubs.selectedPubs,
    availablePubs: state.pubs.availablePubs,
  });
  const path = '/reporting/ra/recipe';

  try {
    if (state.dateRange.from === null || state.dateRange.to === null) {
      throw new Error('Date range in store is unexpectedly null.');
    }
    const data = await chicoryFetcher(
      fetchUrlFormatter({
        path: path,
        from: state.dateRange.from,
        to: state.dateRange.to,
        pubs: pubs,
        limit: limit,
      })
    ).then((response) => response.json());
    return data;
  } catch (error) {
    console.error('fetchRaByRecipe error.', error);
  }
});

export const raByRecipeReducer = createReducer<RaByRecipe>(
  initialRaByRecipe,
  (builder) => {
    builder.addCase(fetchRaByRecipe.fulfilled, (state, action) => {
      return action.payload;
    });
  }
);

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

import './Toast.pcss';

export const Toast = () => {
  const message = useSelector((state: RootState) => state.toast);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, 3000);

    return () => clearTimeout(timer); // This will clear the timeout if the component unmounts before the timeout finishes
  }, []);

  return visible ? <div id="toast">{message}</div> : null;
};

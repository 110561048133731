// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

#toast {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #590b8a;
  width: 250px;
  z-index: 2;
  top: 50vh; /* halfway down the viewport */
  right: 0px;
  animation: slideIn 1s forwards, slideOut 3s 3s forwards; /* slide in, then slide out after a delay */
  background: #f0e9f5;
}
`, "",{"version":3,"sources":["webpack://./src/components/Toast.pcss"],"names":[],"mappings":"AAAA;EACE;IACE,2BAA2B;EAC7B;EACA;IACE,wBAAwB;EAC1B;AACF;;AAEA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,2BAA2B;EAC7B;AACF;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,mBAAmB;EACnB,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,UAAU;EACV,SAAS,EAAE,8BAA8B;EACzC,UAAU;EACV,uDAAuD,EAAE,2CAA2C;EACpG,mBAAmB;AACrB","sourcesContent":["@keyframes slideIn {\n  0% {\n    transform: translateX(100%);\n  }\n  100% {\n    transform: translateX(0);\n  }\n}\n\n@keyframes slideOut {\n  0% {\n    transform: translateX(0);\n  }\n  100% {\n    transform: translateX(100%);\n  }\n}\n\n#toast {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: fixed;\n  padding-left: 30px;\n  padding-right: 30px;\n  padding-top: 10px;\n  padding-bottom: 10px;\n  border-radius: 5px;\n  border: 1px solid #590b8a;\n  width: 250px;\n  z-index: 2;\n  top: 50vh; /* halfway down the viewport */\n  right: 0px;\n  animation: slideIn 1s forwards, slideOut 3s 3s forwards; /* slide in, then slide out after a delay */\n  background: #f0e9f5;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

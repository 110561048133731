import React, { useState } from 'react';

import './Sidebar.pcss';
import { Icon } from './icons';
import { apiUrl, cdnUrl } from '../config';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import logo from '../assets/images/chicory-logo.png';

// 1.
const Sidebar: React.FC = () => {
  const userObject = useSelector((state: RootState) => state.authenticatedUser);

  return (
    <div id="sidebar">
      <div id="sidebar-chicory-logo__wrapper">
        <img id="sidebar-chicory-logo" src={logo} alt="chicory logo" />
      </div>
      <div id="sidebar-links">
        {/* upper */}
        {/* upper */}
        <div id="sidebar__upper">
          {upperItems.map((item, index) => (
            <SidebarHeader
              key={index}
              text={item.text}
              icon={item.icon}
              link={item.link}
              subItems={item.subItems}
            />
          ))}
        </div>
        {/* lower */}
        {/* lower */}
        <div id="sidebar__lower">
          {lowerItems.map((item, index) => (
            <SidebarHeader
              key={index}
              text={item.text}
              icon={item.icon}
              link={item.link}
              subItems={item.subItems}
            />
          ))}
        </div>
      </div>
      {/* user */}
      {/* user */}
      <div id="sidebar-user">
        <div id="sidebar-user__left-group">
          <div id="sidebar-user__avatar">
            <Icon name="user-01" width="25px" height="25px" />
          </div>
          <div id="sidebar-user__info">
            <div id="sidebar-user__info-name">{userObject?.name}</div>
            <div id="sidebar-user__info-email">{userObject?.email}</div>
          </div>
        </div>
        <div
          id="sidebar-user__logout"
          onClick={() => {
            // dispatch(logoutAction());
            // Don't update redux with logout action.
            // App will re-render and flash.
            // Until next line completes, you're still logged in.
            window.location.href = `${apiUrl}/auth/logout?redirectUrl=${encodeURIComponent(
              cdnUrl
            )}`;
          }}
        >
          <Icon name="log-out" />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

// 2.
interface ISidebarHeader {
  text: string;
  icon?: JSX.Element;
  link?: string;
  subItems?: ISidebarDrawer[];
}

const SidebarHeader: React.FC<ISidebarHeader> = ({
  text,
  icon,
  link = null,
  subItems = [],
}) => {
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div className="sidebar-item">
      <div onClick={handleClick} className="sidebar-item__header">
        {link ? (
          <Link to={{ pathname: link, search: window.location.search }}>
            <div className="sidebar-item__header-left">
              <div>{icon}</div>
              <div className="sidebar-item__header-text">{text}</div>
            </div>
          </Link>
        ) : (
          <div className="sidebar-item__header-left">
            <div>{icon}</div>
            <div className="sidebar-item__header-text">{text}</div>
          </div>
        )}
        {subItems?.length > 0 && (
          <div className="sidebar-item__header-right">
            {open ? <Icon name="chevron-up" /> : <Icon name="chevron-down" />}
          </div>
        )}
      </div>
      {open && subItems?.length > 0 && <SidebarDrawer subItems={subItems} />}
    </div>
  );
};

// 3.
interface ISidebarDrawer {
  text: string;
  link: string;
}

const SidebarDrawer: React.FC<{
  subItems: ISidebarDrawer[];
}> = ({ subItems }) => {
  return (
    <>
      {subItems.map((subItem, index) => (
        <Link
          key={index}
          to={{ pathname: subItem.link, search: window.location.search }}
        >
          <div className="sidebar-item__drawer">{subItem.text}</div>
        </Link>
      ))}
    </>
  );
};

const upperItems: ISidebarHeader[] = [
  {
    text: 'Home',
    icon: <Icon name="home-line" />,
    link: '/',
  },
  {
    text: 'Reports',
    icon: <Icon name="bar-chart-square-02" />,
    subItems: [
      {
        text: 'Revenue',
        link: '/reports/revenue',
      },
      {
        text: 'Media',
        link: '/reports/media',
      },
      {
        text: 'Recipe Activation',
        link: '/reports/ra',
      },
      {
        text: 'Earnings',
        link: '/reports/earnings',
      },
      {
        text: 'Manage Reports',
        link: '/reports/manage',
      },
    ],
  },
];

const lowerItems: ISidebarHeader[] = [
  {
    text: 'Support',
    icon: <Icon name="life-buoy" />,
    subItems: [
      // {
      //   text: 'Documentation',
      //   link: 'xx',
      // },
      {
        text: 'FAQs',
        link: 'support/faq',
      },
      // {
      //   text: 'Contact us',
      //   link: 'xx',
      // },
    ],
  },
];

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip {
  width: 350px;
  left: 0px;
  top: 40px;
  z-index: 2;
  position: absolute;
  font-size: 16px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 15px

  /* Hide default scrollbar */
}

.tooltip::-webkit-scrollbar {
    display: none;
  }

/* Create new scrollbar */
.tooltip::-webkit-scrollbar-track {
  background: transparent;
}

.tooltip::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.tooltip:hover::-webkit-scrollbar-thumb {
  background: #555;
}

.tooltip__description {
  font-weight: 100;
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Tooltip.pcss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,SAAS;EACT,SAAS;EACT,UAAU;EACV,kBAAkB;EAClB,eAAe;EACf,0CAA0C;EAC1C,kBAAmB;;EAEnB,2BAA2B;AAI7B;;AAHE;IACE,aAAa;EACf;;AAGF,yBAAyB;AACzB;EACE,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".tooltip {\n  width: 350px;\n  left: 0px;\n  top: 40px;\n  z-index: 2;\n  position: absolute;\n  font-size: 16px;\n  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);\n  border-radius: 15px;\n\n  /* Hide default scrollbar */\n  &::-webkit-scrollbar {\n    display: none;\n  }\n}\n\n/* Create new scrollbar */\n.tooltip::-webkit-scrollbar-track {\n  background: transparent;\n}\n\n.tooltip::-webkit-scrollbar-thumb {\n  background: #888;\n  border-radius: 10px;\n}\n\n.tooltip:hover::-webkit-scrollbar-thumb {\n  background: #555;\n}\n\n.tooltip__description {\n  font-weight: 100;\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { createReducer, createAction } from '@reduxjs/toolkit';

export const growl = createAction<string | null, 'GROWL'>('GROWL');

export const growlReducer = createReducer<string | null>(
  null, // initial state
  (builder) => {
    builder.addCase(growl, (state, action) => {
      return action.payload;
    });
  }
);

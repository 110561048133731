// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-bubble {
  border-radius: 16px;
  height: 1.5rem;
  max-width: 9rem;
  padding: 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem;
  font-size: 0.875rem;
}

  .text-bubble--blue {
    background-color: #eff8ff;
    color: #175cd3;
    border: 1px solid #b2ddff;
  }

  .text-bubble--green {
    color: #067647;
    border: 1px solid #abefc6;
    background: #ecfdf3;
  }

  .text-bubble--red {
    background-color: rgba(217, 45, 32, 0.02);
    border: 1px solid #d92d20;
    color: #d92d20;
  }
`, "",{"version":3,"sources":["webpack://./src/components/TextBubble.pcss"],"names":[],"mappings":"AAEA;EACE,mBAAmB;EACnB,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;AAmBrB;;EAjBE;IACE,yBAAyB;IACzB,cAAc;IACd,yBAAyB;EAC3B;;EAEA;IACE,cAAc;IACd,yBAAyB;IACzB,mBAAmB;EACrB;;EAEA;IACE,yCAAyC;IACzC,yBAAyB;IACzB,cAAc;EAChB","sourcesContent":["$midDesktop: 990px;\n$smallDesktop: 750px;\n.text-bubble {\n  border-radius: 16px;\n  height: 1.5rem;\n  max-width: 9rem;\n  padding: 0 0.5rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin: 0 0.5rem;\n  font-size: 0.875rem;\n\n  &--blue {\n    background-color: #eff8ff;\n    color: #175cd3;\n    border: 1px solid #b2ddff;\n  }\n\n  &--green {\n    color: #067647;\n    border: 1px solid #abefc6;\n    background: #ecfdf3;\n  }\n\n  &--red {\n    background-color: rgba(217, 45, 32, 0.02);\n    border: 1px solid #d92d20;\n    color: #d92d20;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { analyticsUrl } from '../config';
import { AvailablePubs, EventData } from '../types';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';

// uuid, funnel, session and recipe IDs are added for analytics endpoint compatibility
export async function sendEvent(eventData: EventData) {
    const event = {
        client_timestamp: new Date(new Date().toUTCString()).getTime(),
        funnel_id: uuidv4(),
        duration: 0,
        encoding: "UTF-8",
        language: "en-US",
        name: eventData.name,
        properties: {
            portal: {
                user: eventData.user,
                publishers: eventData.publishers,
                account_id: getAccountID(eventData.publishers),
                start_date: eventData.start_date,
                end_date: eventData.end_date,
                current_page: eventData.current_page,
                date_range_duration: getDateRangeDuration(eventData.start_date, eventData.end_date),
                report_name: eventData.report_name,
            }
        },
        recipe_id: 0,
        referrer: "NA",
        session_id: uuidv4(),
        url: window.location.href,
        uuid: uuidv4(),
        viewport_height: window.innerHeight,
        viewport_width: window.innerWidth,
    };

    if (navigator.sendBeacon) {
        navigator.sendBeacon(`${analyticsUrl}/api/event`, JSON.stringify(event));
    } else {
        await fetch(`${analyticsUrl}/api/event`, {
            method: "POST",
            body: JSON.stringify(event),
        });
    }
}

function getAccountID(publishers: AvailablePubs) {
    if (publishers && publishers.length > 0) {
        return publishers[0]?.accountId;
    }
    return "null";
}

function getDateRangeDuration(start_date: string, end_date: string) {
    if (start_date && end_date) {
        return dayjs(end_date).diff(start_date, 'day') + 1;
    }
}

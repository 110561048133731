import React, { useEffect, useState, useMemo } from 'react';

import './DataTablePagination.pcss';
import { Card } from './Card';
import DataTable from './DataTable';
import { TextBubble } from './TextBubble';
import { Searchbar } from './Searchbar';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import {
  fetchRejectedPartners,
  formatPartnerUserForDataTable,
} from '../store/admin/actions';

import { TablePaginator } from './TablePaginator';
import { partnerSearchFilter } from '../utils';
const ROWS_PER_PAGE = 8;

export function AdminRejectedUsers() {
  const dispatch: AppDispatch = useDispatch();

  const rejectedPartners = useSelector((state: RootState) => {
    return state.admin.rejectedPartners;
  });

  const [currentlyViewingUsers, setCurrentlyViewingUsers] = useState<
    typeof rejectedPartners
  >([]);
  const [page, setPage] = useState(1);

  // Search functionality
  const [searchText, setSearchText] = useState('');

  const [filteredPartners, setFilteredPartners] = useState<
    typeof rejectedPartners | null
  >(null);

  const currentUsersForDataDisplay = useMemo(() => {
    return currentlyViewingUsers.map(formatPartnerUserForDataTable);
  }, [currentlyViewingUsers]);

  useEffect(() => {
    dispatch(fetchRejectedPartners());
  }, [dispatch]);

  // Handle page change or text entered for search
  useEffect(() => {
    const start = (page - 1) * ROWS_PER_PAGE;
    const end = start + ROWS_PER_PAGE;
    if (searchText) {
      const results = rejectedPartners.filter((partner) => {
        return partnerSearchFilter(partner, searchText);
      });
      const view = results.slice(start, end);
      setCurrentlyViewingUsers(view);
      setFilteredPartners(results);
    } else {
      const view = rejectedPartners.slice(start, end);
      setCurrentlyViewingUsers(view);
      setFilteredPartners(null);
    }
  }, [page, rejectedPartners, searchText]);

  // Row selectors
  const [selectedPartners, setSelectedPartners] = useState<number[]>([]);
  const [allSelected, setAllSelected] = useState(false);

  const toggleSelectRow = (id: number) => {
    if (selectedPartners.includes(id)) {
      setSelectedPartners(
        selectedPartners.filter((partnerId) => partnerId !== id)
      );
    } else {
      setSelectedPartners([...selectedPartners, id]);
    }
  };
  const toggleSelectAll = () => {
    if (allSelected) {
      setSelectedPartners([]);
    } else {
      setSelectedPartners(currentlyViewingUsers.map((user) => user.id));
    }
    setAllSelected((prev) => !prev);
  };

  return (
    <>
      <Card>
        <Card.Header>
          <Card.HeaderLeft>
            <Card.TitleSmall>{'Rejected Users'}</Card.TitleSmall>
            <TextBubble color="red">{`${rejectedPartners.length} rejected users`}</TextBubble>
          </Card.HeaderLeft>
          <Card.HeaderRight>
            {/* Search bar */}
            {/* Search bar */}
            <Searchbar setSearchText={setSearchText} searchText={searchText} />
          </Card.HeaderRight>
        </Card.Header>
        <Card.TableBody>
          <DataTable
            view={'rejectedUsers'}
            data={currentUsersForDataDisplay}
            toggleSelectRow={toggleSelectRow}
            toggleSelectAll={toggleSelectAll}
          />
        </Card.TableBody>
        <Card.Header style={{ justifyContent: 'flex-end' }}>
          <Card.HeaderRight>
            <TablePaginator
              amountOfEntries={
                filteredPartners
                  ? filteredPartners.length
                  : rejectedPartners.length
              }
              currentPage={page}
              amountPerPage={ROWS_PER_PAGE}
              setPage={setPage}
            />
          </Card.HeaderRight>
        </Card.Header>
      </Card>
    </>
  );
}

import { createAction, createReducer } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import type { SetURLSearchParams } from 'react-router-dom';

import { DateRange } from '../types';
import { YYYYMMDD } from '../utils';

interface SetDateRangeInstruction {
  newFrom: string;
  newTo: string;
  // setSearchParams hook to update URL without reloading page.
  // In order allow this, we've adjusted Redux middleware to allow function passing, otherwise functions are non serializable and not permitted.
  setSearchParams: SetURLSearchParams;
}

export const dateRangeAction = createAction<
  SetDateRangeInstruction,
  'SET_DATE_RANGE'
>('SET_DATE_RANGE');

const params = new URLSearchParams(window.location.search);
const fromDateString = params.get('from');
const toDateString = params.get('to');

// default to dates in URL or to last 30 days.
const toDateInRedux = toDateString ? dayjs(toDateString) : dayjs().subtract(1, 'day');
const fromDateInRedux = fromDateString
  ? dayjs(fromDateString)
  : toDateInRedux.subtract(30, 'day');
const initialDates: DateRange = {
  from: fromDateInRedux.format('YYYY-MM-DD'),
  to: toDateInRedux.format('YYYY-MM-DD'),
};

export const dateReducer = createReducer<DateRange>(initialDates, (builder) => {
  builder.addCase(dateRangeAction, (state, action) => {
    action.payload.setSearchParams(
      (searchParams) => {
        searchParams.set('from', YYYYMMDD(action.payload.newFrom));
        searchParams.set('to', YYYYMMDD(action.payload.newTo));
        return searchParams;
      },
      { replace: true }
    );
    const newFrom = YYYYMMDD(action.payload.newFrom);
    const newTo = YYYYMMDD(action.payload.newTo);
    return {
      from: newFrom,
      to: newTo,
    };
  });
});

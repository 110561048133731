import React from 'react';

import './Unauthorized.pcss';
import logo from '../assets/images/chicory-logo.png';
import { apiUrl, cdnUrl } from '../config';

export default function Unauthorized() {
  return (
    <>
      <div id="unauthorized-wrapper">
        <div id="unauthorized-header">{`Oops, you don't have any data!`}</div>
        <div id="unauthorized-subheader">
          Please contact Chicory to get set up.
        </div>
        <a
          href={`${apiUrl}/auth/logout?redirectUrl=${encodeURIComponent(
            cdnUrl
          )}`}
        >
          <div id="unauthorized-logout-link">Log out</div>
        </a>
      </div>
      <div id="unauthorized-logo-wrapper">
        <img id="unauthorized-logo" src={logo} alt="chicory logo" />
      </div>
    </>
  );
}

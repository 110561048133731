import { useState, useEffect } from 'react';
import { datadogLogs } from '@datadog/browser-logs';

import type { SignupFormUserInput } from '../types';

export const SIGN_UP_LOCAL_STORAGE_KEY = 'ChicorySignUpFormData';

export default (inputToStore: SignupFormUserInput) => {
  const [firstLoadData, setFirstLoadData] =
    useState<SignupFormUserInput | null>(null);

  let transmittedInputAsString: string | null;
  try {
    transmittedInputAsString = JSON.stringify(inputToStore);
  } catch (e) {
    transmittedInputAsString = null;
  }

  useEffect(() => {
    const delayedEnterIntoLocalStorage = window.setTimeout(() => {
      if (transmittedInputAsString === null) return;
      localStorage.setItem(SIGN_UP_LOCAL_STORAGE_KEY, transmittedInputAsString);
    }, 200);
    return () => {
      window.clearTimeout(delayedEnterIntoLocalStorage);
    };
  }, [transmittedInputAsString]);

  useEffect(() => {
    const storedDataString = localStorage.getItem(SIGN_UP_LOCAL_STORAGE_KEY);
    if (storedDataString !== null) {
      try {
        const storedData = JSON.parse(storedDataString);
        setFirstLoadData(storedData);
      } catch (e) {
        datadogLogs.logger.error(
          'Could not used saved sign-up input',
          { storedDataString },
          e instanceof Error ? e : undefined
        );
      }
    }
  }, []);

  return firstLoadData;
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#unauthorized-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 100vh;
  width: 100vw;
}

#unauthorized-header {
  text-align: center;
  font-size: 55px;
  margin-bottom: 40px;
}
#unauthorized-subheader {
  color: #475467;
  font-size: 21px;
  font-weight: 100;
  margin-bottom: 32px;
}

#unauthorized-logout-link {
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  font-size: 16px;
  transition: background-color 0.3s ease;
  border-radius: 8px;
  border: 1px solid #590b8a;
  background: #590b8a;

  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

#unauthorized-logout-link:hover {
  background-color: #8e24aa;
}

#unauthorized-logo-wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 30px;
}

#unauthorized-logo {
  max-width: 150px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Unauthorized.pcss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,aAAa;EACb,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,sCAAsC;EACtC,kBAAkB;EAClB,yBAAyB;EACzB,mBAAmB;;EAEnB,kDAAkD;AACpD;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,SAAS;EACT,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["#unauthorized-wrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 20px;\n  height: 100vh;\n  width: 100vw;\n}\n\n#unauthorized-header {\n  text-align: center;\n  font-size: 55px;\n  margin-bottom: 40px;\n}\n#unauthorized-subheader {\n  color: #475467;\n  font-size: 21px;\n  font-weight: 100;\n  margin-bottom: 32px;\n}\n\n#unauthorized-logout-link {\n  display: inline-block;\n  padding: 10px 20px;\n  color: #fff;\n  font-size: 16px;\n  transition: background-color 0.3s ease;\n  border-radius: 8px;\n  border: 1px solid #590b8a;\n  background: #590b8a;\n\n  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);\n}\n\n#unauthorized-logout-link:hover {\n  background-color: #8e24aa;\n}\n\n#unauthorized-logo-wrapper {\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  padding: 30px;\n}\n\n#unauthorized-logo {\n  max-width: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

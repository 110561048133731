import React from 'react';
import './Card.pcss';
import { CardType, ChildrenProps } from '../types';

// prettier-ignore
export const Card: CardType<ChildrenProps> = ({ children, ...props }: ChildrenProps) => {
  return <div className="card" {...props}>{children}</div>;
};
// prettier-ignore
Card.Header = function Header({ children, ...props }: ChildrenProps) {
  return <div className="card__header" {...props}>{children}</div>;
};
// prettier-ignore
Card.HeaderLeft = function HeaderLeft({ children, ...props }: ChildrenProps) {
  return <div className="card__header__left" {...props}>{children}</div>;
};
// prettier-ignore
Card.HeaderRight = function HeaderRight({ children, ...props }: ChildrenProps) {
  return <div className="card__header__right" {...props}>{children}</div>;
};
// prettier-ignore
Card.TitleLarge = function TitleLarge({ children, ...props }: ChildrenProps) {
  return <div className="card__title card__title--large" {...props}>{children}</div>;
}
// prettier-ignore
Card.TitleSmall = function TitleSmall({ children, ...props }: ChildrenProps) {
  return <div className="card__title card__title--small" {...props}>{children}</div>;
}
// prettier-ignore
Card.Body = function Body({ children, ...props }: ChildrenProps) {
  return <div className="card__body" {...props}>{children}</div>;
}
// prettier-ignore
Card.TableBody = function TableBody({ children, ...props }: ChildrenProps) {
  return <div className="card__table-body" {...props}>{children}</div>;
}

Card.Divider = function Divider() {
  return <div className="card__divider"></div>;
};

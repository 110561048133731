import { createReducer, createAction } from '@reduxjs/toolkit';
import { FeatureFlags } from '../types';

export const setFeatureFlags = createAction<FeatureFlags, 'SET_FEATURE_FLAGS'>(
  'SET_FEATURE_FLAGS'
);

const initialState = {
  showImpressions: false,
};

export const featureFlagsReducer = createReducer<FeatureFlags>(
  initialState, // initial state
  (builder) => {
    builder.addCase(setFeatureFlags, (state, action) => {
      return action.payload;
    });
  }
);

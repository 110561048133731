import { createReducer, createAction } from '@reduxjs/toolkit';

export const toast = createAction<string | null, 'TOAST'>('TOAST');

export const toastReducer = createReducer<string | null>(
  null, // initial state
  (builder) => {
    builder.addCase(toast, (state, action) => {
      return action.payload;
    });
  }
);

import React, { useMemo } from 'react';
import * as Switch from '@radix-ui/react-switch';
import isUrl from 'validator/lib/isURL';

import store, { RootState } from '../store';
import { Icon } from './icons';
import './DataTable.pcss';
import {
  DataTableData,
  DataTableHeaders,
  ChicoryGeneralProps,
  Report,
} from '../types';
import { useDispatch, useSelector } from 'react-redux';
import { updateScheduledReport } from '../store/scheduleData';
import {
  capitalize,
  formatAsUsd,
  formatAsPercent,
  formatWithCommas,
  formatAsHumanDate,
} from '../utils';
import { deletingSchedule, editingSchedule } from '../store/scheduleModals';
import { TextBubble } from './TextBubble';
import { CheckboxSquare } from './CheckboxSquare';

const DataTable: React.FC<
  {
    view: DataTableHeaders;
    data: DataTableData;
    toggleSelectRow?: (id: number) => void;
    toggleSelectAll?: () => void;
    selectedRowIds?: number[];
    allRowsSelected?: boolean;
  } & Partial<ChicoryGeneralProps>
> = ({
  view,
  data,
  toggleSelectRow,
  toggleSelectAll,
  selectedRowIds,
  allRowsSelected,
  ...props
}) => {
  // Feature flags
  const { showImpressions } = useSelector(
    (state: RootState) => state.featureFlags
  );

  // Pull out className, we're using these to denote a variant table css style
  const { className, ...otherProps } = props;

  const headers = {
    // Dashboard
    recipes: showImpressions
      ? ['Recipe', 'URL', 'Revenue', 'Ad Impressions']
      : ['Recipe', 'URL', 'Revenue'],
    // Media page
    mediaRecipes: [
      'Recipe',
      'Domain',
      'URL',
      'Ad Impressions',
      'Creative Clicks',
      'Click-through rate',
    ],
    mediaIngredients: [
      'Ingredient',
      'Ad Impressions',
      'Creative Clicks',
      'Click-through rate',
    ],
    mediaPublishers: [
      'Domain',
      'Ad Impressions',
      'Creative Clicks',
      'Click-through rate',
    ],
    // Revenue page
    revenuePlacements: ['Placement', 'Revenue'],
    revenueRecipes: showImpressions
      ? ['Recipe', 'Domain', 'URL', 'Revenue', 'Ad Impressions']
      : ['Recipe', 'Domain', 'URL', 'Revenue'],
    revenueIngredients: ['Ingredient', 'Revenue', 'Ad Impressions'],
    revenuePublishers: showImpressions
      ? ['Domain', 'Revenue', 'Ad Impressions']
      : ['Domain', 'Revenue'],
    // RA page
    raRecipes: [
      'Recipe',
      'URL',
      'Views',
      'Clicks',
      'Button CTR',
      'Choose Retailer Actions',
      'Add-to-Carts',
      'Add-to-Cart Rate',
    ],
    raIngredients: [
      'Ingredient',
      'Choose Retailer Actions',
      'Add-to-Carts',
      'Add-to-Cart Rate',
    ],
    raRetailers: [
      'Retailer',
      'Choose Retailer Actions',
      'Add-to-Carts',
      'Add-to-Cart Rate',
    ],
    raPublishers: [
      'Domain',
      'Views',
      'Clicks',
      'Button CTR',
      'Choose Retailer Actions',
      'Add-to-Carts',
      'Add-to-Cart Rate',
    ],
    // Earnings Page
    earningsByDay: ['Date', 'Earnings'],
    earningsByPlacement: ['Category', 'Subcategory', 'Earnings'],
    // Schedule page
    manageReports: [
      'Report Name',
      'Active',
      'Category',
      'Method',
      'Recipients',
      'Frequency',
      'Actions',
    ],
    // Admin User Management
    currentUsers: ['User', 'Email', 'Account ID', 'Domain(s)', 'Partner tier'],
    rejectedUsers: [
      'User',
      'PayPal Email',
      'Website URL',
      'Date',
      'Source',
      'Impressions',
    ],
    pendingUsers: [
      'Select',
      'User',
      'PayPal Email',
      'Website URL',
      'Date',
      'Source',
      'Impressions',
    ],
  }[view];

  // Return table headers here, rows are rendered in component below
  return (
    <table className={`table ${className ? className : ''}`} {...otherProps}>
      <thead>
        <tr className="table-head">
          {headers.map((header: string, i: number) => {
            // 'Select All' checkbox in first column header
            if (header === 'Select' && toggleSelectAll && selectedRowIds) {
              return (
                <th key={`${header}${i}`} className="table-head-cell">
                  <CheckboxSquare
                    toggleSelect={toggleSelectAll}
                    selected={allRowsSelected || false}
                  />
                </th>
              );
            } else if (
              // Distinguish "Category" used by Reports vs Earnings Page
              view === 'earningsByPlacement' &&
              (header === 'Category' || header === 'Subcategory')
            ) {
              // On Earnings Page, Category spans two cols
              return header === 'Category' ? (
                <th
                  key={`${header}${i}`}
                  className="table-head-cell"
                  colSpan={2}
                >
                  <div className="text-truncator">{header}</div>
                </th>
              ) : null; // null for Subcategory header
            } else {
              return (
                <th key={`${header}${i}`} className="table-head-cell">
                  <div className="text-truncator">{header}</div>
                </th>
              );
            }
          })}
        </tr>
      </thead>
      <tbody>
        {data && (
          <DataRows
            data={data}
            headers={headers}
            toggleSelectRow={toggleSelectRow}
            selectedRowIds={selectedRowIds}
          />
        )}
      </tbody>
    </table>
  );
};

export default DataTable;

const DataRows: React.FC<{
  data: DataTableData;
  headers: string[];
  toggleSelectRow?: (id: number) => void;
  selectedRowIds?: number[];
}> = ({ data, headers, toggleSelectRow, selectedRowIds }) => {
  const dispatch = useDispatch<typeof store.dispatch>();

  // Pre-render loop to identify merged cells on Earnings Page
  // This returns an array eg. [2,0,2,0] where first row spans 2 rows,
  // second row is hidden (ie merged),
  // third row spans 2 rows, fourth row is hidden
  const rowSpans = useMemo(() => {
    // Currently only applied to first col on Earnings Page
    if (headers[0] !== 'Category') return [];

    const rowSpans: number[] = [];
    let previousCategory: string | undefined = undefined;
    let currentSpan = 1;

    data.forEach((row, index) => {
      if (data[0]?.category !== undefined) {
        if (row.category === previousCategory) {
          currentSpan++;
          rowSpans[index] = 0; // Hide row
        } else {
          if (previousCategory !== undefined) {
            rowSpans[index - currentSpan] = currentSpan;
          }
          previousCategory = row.category;
          currentSpan = 1;
          rowSpans[index] = 1;
        }
      }
    });

    // After the loop, set the last category span
    if (previousCategory !== undefined) {
      rowSpans[rowSpans.length - currentSpan] = currentSpan;
    }
    return rowSpans;
  }, [data, headers]);

  const generateCell = (colName: string, row: any) => {
    // Handle first column, eg. checkboxes, capitalization...
    if (colName === headers[0]) {
      if (colName === 'Select' && row.id && toggleSelectRow && selectedRowIds) {
        // Checkbox first column
        return (
          <CheckboxSquare
            toggleSelect={() => toggleSelectRow(row.id)}
            selected={selectedRowIds.includes(row.id)}
          />
        );
      } else if (row.name && isUrl(row.name)) {
        // URL first column
        return <div className="text-truncator">{row.name}</div>;
      } else if (colName === 'Date' && row.date) {
        // Date first column
        return (
          <div className="text-truncator">{formatAsHumanDate(row.date)}</div>
        );
      } else if (colName === 'Category') {
        // If Category is first col then we're on Earnings Page
        // Merging cells is handled in return statement
        return <div className="text-truncator">{capitalize(row.category)}</div>;
      } else {
        return <div className="text-truncator">{capitalize(row.name)}</div>;
      }
    } // End first column handling.
    else {
      switch (colName) {
        case 'Domain':
          return row.url ? (
            <div className="text-truncator">{row.url.split('/')[0]}</div>
          ) : null;
        case 'URL':
          return row.url ? (
            <a
              className="table-data-cell__anchor"
              href={'https://' + row.url}
              target="_blank"
              rel="noreferrer"
            >
              <span>{row.url}</span>
              <Icon name="link-external" width="15px" height="15px" />
            </a>
          ) : null;

        case 'Revenue':
          return row.revenue !== undefined ? (
            <div className="text-truncator">{formatAsUsd(+row.revenue)}</div>
          ) : null;
        case 'Impressions':
        case 'Ad Impressions':
          return row.impressions !== undefined ? (
            <div className="text-truncator">
              {formatWithCommas(row.impressions)}
            </div>
          ) : row.monthlyImpressions !== undefined ? (
            <div className="text-truncator">
              {formatWithCommas(row.monthlyImpressions)}
            </div>
          ) : null;

        case 'Views':
          return row.views !== undefined ? (
            <div className="text-truncator">{formatWithCommas(row.views)}</div>
          ) : null;

        case 'Clicks':
        case 'Creative Clicks':
          return row.clicks !== undefined ? (
            <div className="text-truncator">{formatWithCommas(row.clicks)}</div>
          ) : null;
        case 'Click-through rate':
          return row.impressions !== undefined && row.clicks !== undefined ? (
            <div className="text-truncator">
              {row.impressions !== 0
                ? formatAsPercent(row.clicks / row.impressions)
                : formatAsPercent(0)}
            </div>
          ) : null;

        case 'Button CTR':
          return row.views !== undefined && row.clicks !== undefined ? (
            <div className="text-truncator">
              {row.views !== 0
                ? formatAsPercent(row.clicks / row.views)
                : formatAsPercent(0)}
            </div>
          ) : null;

        case 'Choose Retailer Actions':
          return row.retailerChoices !== undefined ? (
            <div className="text-truncator">
              {formatWithCommas(row.retailerChoices)}
            </div>
          ) : null;

        case 'Add-to-Carts':
          return row.addsToCart !== undefined ? (
            <div className="text-truncator">
              {formatWithCommas(row.addsToCart)}
            </div>
          ) : null;

        case 'Add-to-Cart Rate':
          return row.addsToCart !== undefined &&
            row.retailerChoices !== undefined ? (
            <div className="text-truncator">
              {row.retailerChoices !== 0
                ? formatAsPercent(row.addsToCart / row.retailerChoices)
                : formatAsPercent(0)}
            </div>
          ) : null;

        // Scheduled Reports columns
        case 'Active':
          return row.active !== undefined ? (
            <Switch.Root
              className="SwitchRoot"
              id={`scheduled-report-${row.id}`}
              onCheckedChange={(nextActiveState) => {
                dispatch(
                  updateScheduledReport({
                    ...row,
                    active: nextActiveState,
                  } as Report)
                );
              }}
              checked={row.active}
            >
              <Switch.Thumb className="SwitchThumb" />
            </Switch.Root>
          ) : null;
        case 'Category':
          return row.category ? (
            <div className="text-truncator">{capitalize(row.category)}</div>
          ) : null;
        case 'Method':
          return row.method ? (
            <div className="text-truncator">{capitalize(row.method?.type)}</div>
          ) : null;
        case 'Recipients':
          return row.method && row.method.type === 's3' ? (
            <div className="text-truncator">{row.method.bucket}</div>
          ) : row.method && row.method.type === 'email' ? (
            <div className="text-truncator">
              {row.method.addresses.join(', ')}
            </div>
          ) : null;
        case 'Frequency':
          return row.frequency ? (
            <div className="text-truncator">{capitalize(row.frequency)}</div>
          ) : null;
        case 'Actions':
          return (
            <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
              <Icon
                name="trash"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  dispatch(deletingSchedule(row as Report));
                }}
              />
              <div style={{ width: '15px' }} />
              <Icon
                name="edit"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  dispatch(editingSchedule(row as Report));
                }}
              />
            </div>
          );

        // Admin User Management columns
        case 'User':
          return row.name ? (
            <div className="text-truncator">{capitalize(row.name)}</div>
          ) : null;
        case 'PayPal Email':
          return row.paypalEmail ? (
            <div className="text-truncator">{row.paypalEmail}</div>
          ) : null;
        case 'Email':
          return row.email ? (
            <div className="text-truncator">{row.email}</div>
          ) : null;
        case 'Account ID':
          return row.accountName ? (
            <div className="text-truncator">{capitalize(row.accountName)}</div>
          ) : null;
        case 'Website URL':
          return row.domains && row.domains.length > 0
            ? row.domains.map((domain: string) => (
                <a
                  className="table-cell-domain-link"
                  key={`${domain}`}
                  href={'https://' + domain}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="text-truncator">
                    <span>{domain}</span>
                  </div>
                </a>
              ))
            : null;
        case 'Domain(s)':
          return row.domains && row.domains.length > 0
            ? row.domains.map((domain: string) => (
                <a
                  className="table-cell-domain-link"
                  key={`${domain}`}
                  href={'https://' + domain}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>{domain}</span>
                </a>
              ))
            : null;
        case 'Partner tier':
          if (row.partnerTier) {
            let color: 'blue' | 'green' = 'blue';
            if (row.partnerTier === 'Individual Creator') {
              color = 'green';
            }
            return (
              <TextBubble color={color}>
                <div className="text-truncator">
                  {capitalize(row.partnerTier)}
                </div>
              </TextBubble>
            );
          } else {
            return null;
          }
        case 'Date':
          return row.date ? (
            <div className="text-truncator">{formatAsHumanDate(row.date)}</div>
          ) : row.createdDate ? (
            <div className="text-truncator">
              {formatAsHumanDate(row.createdDate)}
            </div>
          ) : null;
        case 'Source':
          return row.source ? (
            <div className="text-truncator">{capitalize(row.source)}</div>
          ) : null;

        // Earnings Page
        case 'Earnings':
          return row.earnings !== undefined || row.amount !== undefined ? (
            <div className="text-truncator">
              {formatAsUsd(row.earnings ?? row.amount)}
            </div>
          ) : null;
        case 'Subcategory':
          let overrideHeaderText = '';
          if (row.subcategory === 'Inlines') {
            overrideHeaderText = 'In-lines';
          }
          return row.subcategory ? (
            <div className="text-truncator">
              {capitalize(overrideHeaderText || row.subcategory)}
            </div>
          ) : null;

        default:
          return null;
      }
    }
  };

  return (
    <>
      {data.map((row, rowIndex) => {
        return (
          <tr
            key={row.id ?? row.name ?? row.date ?? row.subcategory}
            className="table-data-row"
          >
            {headers.map((colName, colIndex) =>
              headers[0] === 'Category' && colIndex === 0 ? (
                // Merged Category cells return a tall <td> or null
                rowSpans[rowIndex] === 0 ? null : (
                  <td
                    className="table-data-cell"
                    key={`${colName}`}
                    rowSpan={rowSpans[rowIndex]}
                  >
                    {generateCell(colName, row)}
                  </td>
                )
              ) : (
                <td className="table-data-cell" key={`${colName}`}>
                  {generateCell(colName, row)}
                </td>
              )
            )}
          </tr>
        );
      })}
    </>
  );
};

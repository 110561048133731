import React from 'react';
import { UserState } from '../../../types/';
import { Navigate } from 'react-router-dom';

// Short circuit if user completed onboarding betore
export const AlreadyCompletedOnboarding: React.FC<{
  user: UserState;
  children: React.ReactNode;
}> = ({ user, children }) => {
  if (
    user?.installationDetails?.lastPageReached === true &&
    user?.installationDetails?.onboardingStatus !== 'scraper-validation-failure'
  ) {
    return <Navigate to="/onboarding/thanks" />;
  } else {
    return <>{children}</>;
  }
};

import React, { useState } from 'react';
import { Tiles } from './Tiles';
import { Icon } from './icons';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import './PubPicker.pcss';
import { selectedPubsAction } from '../store/publisherSlice';
import { useSearchParams } from 'react-router-dom';

export const PubPicker = () => {
  // Reusable button with dropdown to select domains
  // Saves checked pubs to selectedPubsLocalState, dispatches to Store when user hits apply.
  // If we dispatch to Store on every click, will cause unnecessary fetches to api.

  const dispatch = useDispatch();
  const { availablePubs, selectedPubs } = useSelector(
    (state: RootState) => state.pubs
  );
  const [, setSearchParams] = useSearchParams();

  const [selectedPubsLocalState, setSelectedPubsLocalState] =
    useState<number[]>(selectedPubs);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleSelectAll = () => {
    setSelectedPubsLocalState((prev) => {
      if (prev?.length === availablePubs?.length || !availablePubs) {
        return []; // If all were selected, unselect all
      } else {
        return availablePubs.map((pub) => pub.id);
      }
    });
  };

  return (
    <div className="pubpicker">
      <Tiles style={{ background: 'white' }}>
        <Tiles.Button
          version={'white'}
          onClick={() => setShowDropdown((prev) => !prev)}
        >
          <Icon name="globe" />
          <div className="w-spacer" />
          <div>{'Domains'}</div>
          <div className="w-spacer" />
          <Icon name="chevron-down" style={{ marginLeft: '5px' }} />
        </Tiles.Button>
      </Tiles>

      {showDropdown && (
        <div className="pubpicker-dropdown">
          <Checkbox
            name={`pub-all`}
            checked={selectedPubsLocalState?.length === availablePubs?.length}
            onClick={handleSelectAll}
            label={'Select all domains'}
          />
          {availablePubs?.map((availablePub) => (
            <Checkbox
              key={availablePub.id}
              name={`pub-${availablePub.id}`}
              checked={selectedPubsLocalState.includes(availablePub.id)}
              onClick={(checked) => {
                let values = [...selectedPubsLocalState];
                if (checked) {
                  values.push(availablePub.id);
                } else {
                  values = values.filter((id) => id !== availablePub.id);
                }
                setSelectedPubsLocalState(values);
              }}
              label={availablePub.name}
            />
          ))}
          <hr />
          <button
            onClick={() => {
              let selectedPubs: number[] = [];
              if (selectedPubsLocalState?.length !== availablePubs?.length) {
                selectedPubs = selectedPubsLocalState;
              }
              setSearchParams(
                (searchParams) => {
                  searchParams.set('pubs', selectedPubs.join(','));
                  if (selectedPubs.length === 0) {
                    searchParams.delete('pubs');
                  }
                  return searchParams;
                },
                { replace: true }
              );
              dispatch(selectedPubsAction({ selectedPubs }));
              setShowDropdown(false);
            }}
          >
            Apply
          </button>
        </div>
      )}
    </div>
  );
};

export const Checkbox = ({
  name,
  checked,
  onClick,
  label,
  showCheckbox = true,
}: {
  name: string;
  checked: boolean;
  onClick: (checked: boolean) => void;
  label: string;
  showCheckbox?: boolean;
}) => {
  return (
    <div
      onMouseDown={() => {
        onClick(!checked);
      }}
      className={`pubpicker-dropdown__radio-div`}
    >
      <div className="text-truncator">
        <label htmlFor={name}>{label}</label>
      </div>
      {showCheckbox && (
        <input type="checkbox" name={name} checked={checked} readOnly={true} />
      )}
    </div>
  );
};

import React, { useEffect, useState, MouseEventHandler } from 'react';

import './DataTablePagination.pcss';
import { Card } from './Card';
import DataTable from './DataTable';
import { Tiles } from './Tiles';
import { Icon } from './icons';
import { DataTableData, DataTableHeaders } from '../types';
import { TablePaginator } from './TablePaginator';

const rowsPerPage = 10;

export function DataTablePagination({
  data,
  title,
  view,
  isDownloading,
  onDownload,
  onSchedule,
}: {
  data: DataTableData;
  title: string;
  view: DataTableHeaders;
  isDownloading?: boolean;
  onDownload?: MouseEventHandler<Element>;
  onSchedule?: () => void;
}) {

  const [dataSlice, setDataSlice] = useState<DataTableData>([]);
  const [page, setPage] = useState(1);

  // Initial slice
  useEffect(() => {
    setDataSlice(data.slice(0, rowsPerPage));
  }, [data]);

  // Handle page change
  useEffect(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    setDataSlice(data.slice(start, end));
  }, [page, data]);

  return (
    <>
      <Card>
        <Card.Header>
          <Card.HeaderLeft>
            <Card.TitleSmall>{title}</Card.TitleSmall>
          </Card.HeaderLeft>
        </Card.Header>
        <Card.TableBody>
          <DataTable view={view} data={dataSlice} />
        </Card.TableBody>
        <Card.Header>
          <Card.HeaderLeft>
            <TablePaginator
              setPage={setPage}
              amountPerPage={rowsPerPage}
              amountOfEntries={data.length}
              currentPage={page}
            />
          </Card.HeaderLeft>
          <Card.HeaderRight>
            <Tiles style={{ background: 'white', border: 'none' }}>
              <Tiles.Button version={'white'} onClick={onSchedule}>
                <Icon name="clockRefresh" />
                <div className="w-spacer" />
                <div>{'Schedule Reports'}</div>
                <div className="w-spacer" />
              </Tiles.Button>
              <div className="w-spacer" />
              <Tiles.Button
                version={'white'}
                onClick={!isDownloading ? onDownload : undefined}
              >
                <Icon name="downloadCloud" />
                <div className="w-spacer" />
                <div>
                  {isDownloading ? 'Downloading...' : 'Download full data'}
                </div>
                <div className="w-spacer" />
              </Tiles.Button>
            </Tiles>
          </Card.HeaderRight>
        </Card.Header>
      </Card>
    </>
  );
}

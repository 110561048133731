import { createReducer, createAsyncThunk } from '@reduxjs/toolkit';
import {
  EarningsApiResponse,
  EarningsAggregatedByDate,
  EarningsAmounts,
  EarningsAggregatedByProduct,
  EarningsInStore,
  EarningsProduct,
} from '../types';
import { RootState } from '.';

import { chicoryFetcher, fetchUrlFormatter, getValidPubs } from '../utils';

// Categories defined by Product team
const CATEGORY_MAP = {
  pairings: { subcategory: 'Pairings', category: 'Chicory Premium' },
  inlines: { subcategory: 'Inlines', category: 'Chicory Premium' },
  featuredRetailer: { subcategory: 'Recipe Activation', category: 'Other' },
  aisle: { subcategory: 'Innovation Products', category: 'Other' },
  portfolio: {
    subcategory: 'Innovation Products',
    category: 'Other',
  },
  reach: { subcategory: 'Innovation Products', category: 'Other' },
} as const;

type Subcategory =
  (typeof CATEGORY_MAP)[keyof typeof CATEGORY_MAP]['subcategory'];
type Category = (typeof CATEGORY_MAP)[keyof typeof CATEGORY_MAP]['category'];

const initialState = {
  aggregatedByDate: [],
  aggregatedByProduct: {},
  aggregatedBySubcategory: [],
};

// Thunk.
export const fetchEarnings = createAsyncThunk<
  EarningsInStore, // Return type
  { limit: number }, // Input type
  { state: RootState }
>('FETCH_EARNINGS', async ({ limit }, { getState }) => {
  const state = getState();

  const pubs = getValidPubs({
    selectedPubs: state.pubs.selectedPubs,
    availablePubs: state.pubs.availablePubs,
  });

  if (state.dateRange.from === null || state.dateRange.to === null) {
    throw new Error('Date range in store is unexpectedly null.');
  }

  const path = '/reporting/earnings';
  const data: EarningsApiResponse[] = await chicoryFetcher(
    fetchUrlFormatter({
      path,
      from: state.dateRange.from,
      to: state.dateRange.to,
      pubs: pubs,
      limit: limit,
    })
  ).then((response) => response.json());

  if (localStorage.getItem('mockEarningsData') === '1') {
    data.forEach((entry) => {
      Object.values(entry).forEach((subEntry) => {
        if (typeof subEntry === 'string') return;
        subEntry.amount = (Math.random() * 100 + Math.random() * 500).toFixed(
          2
        );
      });
    });
  }

  // Reduce the 'amount' keys into a single sum for each date
  const aggregatedByDate: EarningsAggregatedByDate = [];
  const aggregatedByProduct: EarningsAggregatedByProduct = {};

  for (const day of data) {
    let earningsOnThisDay = 0;

    for (const key in day) {
      if (key === 'date') {
        continue;
      } else {
        // TS won't define keys when iterating through an object, product will always be type string. Below, we need to cast the key to the type we expect.
        const product = key as EarningsProduct;
        const thisProductEarnings = day[product] as EarningsAmounts;
        const amount = +thisProductEarnings.amount;

        // For each product, sum into product object
        if (
          product in aggregatedByProduct &&
          aggregatedByProduct[product] !== undefined
        ) {
          aggregatedByProduct[product] =
            (aggregatedByProduct[product] as number) + amount;
        } else {
          aggregatedByProduct[product] = amount;
        }
        // For each product, sum into day array
        earningsOnThisDay += amount;
      }
    } // End product loop, continue to next day

    aggregatedByDate.push({
      date: day.date,
      earnings: earningsOnThisDay,
    });
  } // End data loop

  // Categorize earnings, eg Innovation Products, Chicory Premium
  const aggregatedBySubcategory: {
    [K in Subcategory]?: {
      category: Category;
      subcategory: Subcategory;
      amount: number;
    };
  } = {};

  for (const key in aggregatedByProduct) {
    const product = key as EarningsProduct;
    if (key in CATEGORY_MAP) {
      const { category, subcategory } = CATEGORY_MAP[product];

      const previousRecord = aggregatedBySubcategory[subcategory];

      if (previousRecord && previousRecord.amount > 0) {
        previousRecord.amount += aggregatedByProduct[product] ?? 0;
      } else {
        aggregatedBySubcategory[subcategory] = {
          category,
          subcategory,
          amount: aggregatedByProduct[product] ?? 0,
        };
      }
    } else {
      throw new Error(`Product ${key} not classified in earnings CATEGORY_MAP`);
    }
  }
  // Sort to display Chicory Premium at top
  const aggregatedBySubcategoryArray = Object.values(
    aggregatedBySubcategory
  ).sort((i, j) =>
    i.category === 'Chicory Premium'
      ? -1
      : j.category === 'Chicory Premium'
        ? 1
        : 0
  );

  return {
    aggregatedByDate,
    aggregatedByProduct,
    aggregatedBySubcategory: aggregatedBySubcategoryArray,
  };
});

export const earningsReducer = createReducer<EarningsInStore>(
  initialState,
  (builder) => {
    builder.addCase(fetchEarnings.fulfilled, (state, action) => {
      return action.payload;
    });
  }
);

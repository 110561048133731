// External imports
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { datadogLogs } from '@datadog/browser-logs';

// Local imports
import store from './store';
import { router } from './routes';
import './styles.pcss';
import { datadogClientToken, environment, version } from './config';

if (datadogClientToken) {
  datadogLogs.init({
    clientToken: datadogClientToken,
    service: 'chicory-partner-portal-ui',
    version,
    env: environment,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ['log', 'info', 'warn', 'error'],
    forwardReports: 'all',
    sessionSampleRate: 100,
  });
}

const root = document.querySelector('#root');

if (root) {
  const reactRoot = createRoot(root);
  reactRoot.render(
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
} else {
  throw new Error('Missing root element');
}

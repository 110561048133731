import { Icon } from './icons';
import './Searchbar.pcss';

import React from 'react';
export const Searchbar: React.FC<{
  setSearchText: (text: string) => void;
  searchText: string;
}> = ({ setSearchText, searchText }) => {
  return (
    <>
      <div className="searchbar">
        <Icon name="search" style={{ marginTop: '2px' }} />
        <input
          type="text"
          className="search-input"
          placeholder="Search"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>
    </>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chicory-checkbox {
  border-radius: 5px;
  border: 1px solid #d0d5dd;
  width: 20px;
  height: 20px
}
.chicory-checkbox:hover {
    cursor: pointer;
  }
.checkbox--checked {
  background-color: #590b8a;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox--checked::after {
  content: '';
  width: 50%;
  height: 2px;
  background-color: white;
  transform: translateY(-50%);
}
`, "",{"version":3,"sources":["webpack://./src/components/CheckboxSquare.pcss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,WAAW;EACX;AAKF;AAHE;IACE,eAAe;EACjB;AAEF;EACE,yBAAyB;EACzB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,UAAU;EACV,WAAW;EACX,uBAAuB;EACvB,2BAA2B;AAC7B","sourcesContent":[".chicory-checkbox {\n  border-radius: 5px;\n  border: 1px solid #d0d5dd;\n  width: 20px;\n  height: 20px;\n\n  &:hover {\n    cursor: pointer;\n  }\n}\n.checkbox--checked {\n  background-color: #590b8a;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.checkbox--checked::after {\n  content: '';\n  width: 50%;\n  height: 2px;\n  background-color: white;\n  transform: translateY(-50%);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import './AdminLayout.pcss';
import { Navigate, Outlet } from 'react-router-dom';
import logo from '../assets/images/chicory-logo.png';
import { Icon } from '../components/icons';
import { apiUrl, cdnUrl } from '../config';
import { RootState } from '../store';
import { useSelector } from 'react-redux';

export const AdminLayout = () => {
  const user = useSelector((state: RootState) => state.authenticatedUser);

  if (!user) return <progress></progress>;

  if (user.role !== 'admin') {
    return <Navigate replace={true} to="/unauthorized" />;
  }

  return (
    <div id="admin-layout">
      <div id="admin-nav">
        <div id="admin-nav__left">
          <img
            id="admin-chicory-logo"
            width="150px"
            src={logo}
            alt="chicory logo"
          />
        </div>
        <div id="admin-nav__right">
          <Icon
            name="log-out"
            className="stroke-red"
            onClick={() => {
              // dispatch(logoutAction());
              // Don't update redux with logout action.
              // App will re-render and flash.
              // Until next line completes, you're still logged in.
              window.location.href = `${apiUrl}/auth/logout?redirectUrl=${encodeURIComponent(
                cdnUrl
              )}`;
            }}
            style={{ cursor: 'pointer' }}
          />
        </div>
      </div>
      <div id="admin-layout-spacer">
        <Outlet />
      </div>
    </div>
  );
};

import React from 'react';

import './Tooltip.pcss';
import { Card } from './Card';
import { Icon } from './icons';

type Locations = 'raMetrics' | 'reportMedia' | 'reportRevenue';

export const Tooltip = ({
  location,
  onClick,
}: {
  location: Locations;
  onClick?: () => void;
}) => {
  const tips = {
    raMetrics: [
      'Views',
      'Clicks',
      'Choose Retailer Actions',
      'Add-to-Carts',
      'CTR',
      'Choose Retailer Rate',
      'ATC Rate',
    ] as const,
    reportMedia: ['Ad Impressions', 'Creative Clicks'] as const,
    reportRevenue: ['Ad Revenue'] as const,
  }[location];
  return (
    <div className="tooltip">
      <Card>
        <Card.Header
          style={{
            justifyContent: 'flex-end',
            paddingTop: '10px',
            paddingBottom: '10px',
          }}
        >
          <Card.HeaderRight>
            <Icon name="x" style={{ cursor: 'pointer' }} onClick={onClick} />
          </Card.HeaderRight>
        </Card.Header>
        <Card.Body
          style={{
            flexDirection: 'column',
            maxHeight: '300px',
            overflowY: 'auto',
          }}
        >
          {tips.map((tip, i) => {
            return (
              <div key={i} className="tooltip-tip">
                <div className="tooltip__title">{tip}</div>
                <div className="tooltip__description">{definitions[tip]}</div>
              </div>
            );
          })}
        </Card.Body>
        <Card.Header
          style={{
            paddingTop: '10px',
            paddingBottom: '10px',
          }}
        ></Card.Header>
      </Card>
    </div>
  );
};

const definitions = {
  Views: (
    <div>
      The number of times the Shoppable Recipe Button came into view on the
      recipe page. <br /> <br />
      Note: this is not the same as a page view.
    </div>
  ),
  Clicks: `The number of times users engaged with the Shoppable Recipe Button`,
  'Choose Retailer Actions': `The number of times users choose a specific retailer from the retailer selection modal`,
  'Add-to-Carts': `The number of times products land in the selected retailer's cart`,
  CTR: `The ratio of 'Clicks' to 'Views,' expressed as a percentage`,
  'Choose Retailer Rate': `The ratio of 'Choose Retailer Actions' to 'Views,' expressed as a percentage.`,
  'ATC Rate': `The ratio of 'Add-to-Carts' to 'Choose Retailer Actions,' expressed as a percentage.`,
  'Ad Impressions': `The number of times an ad is fetched and displayed on a user's screen, even if it is not clicked.`,
  'Creative Clicks': `The number of clicks on different elements or creative components with an ad, including the image and CTA button.`,
  'Ad Revenue': `The total revenue generated from advertising calculated by ('Total Ad Impressions' * 'Dynamic CPM') / 1,000`,
};

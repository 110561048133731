import React, { useMemo } from 'react';
import { Tiles } from './Tiles';

const makePagesArray = (startingNumber: number, length: number) => {
  const arr = new Array(length).fill(0);
  return arr.map((_, index) => {
    return startingNumber + index;
  });
};

export const TablePaginator = ({
  amountOfEntries,
  currentPage,
  amountPerPage,
  setPage,
}: {
  amountOfEntries: number;
  currentPage: number;
  amountPerPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const numPages = useMemo(() => {
    return Math.ceil(amountOfEntries / amountPerPage);
  }, [amountOfEntries, amountPerPage]);

  const pageRanges: Array<Array<number>> = [];
  if (numPages > 10) {
    const lastPages = makePagesArray(numPages - 4, 5);
    const firstPages = (() => {
      if (lastPages.includes(currentPage)) {
        return makePagesArray(1, 5);
      } else {
        const maximumFirstPageNumber = numPages - 9;
        return makePagesArray(Math.min(currentPage, maximumFirstPageNumber), 5);
      }
    })();
    pageRanges.push(firstPages, lastPages);
  } else {
    pageRanges.push(makePagesArray(1, numPages));
  }

  const makePageElements = (pageNumberArray: number[]) => {
    return pageNumberArray.map((pageNumber) => (
      <Tiles.Tile
        key={pageNumber}
        selected={currentPage === pageNumber}
        selectedClass="highlight-darkgray"
        onClick={() => setPage(pageNumber)}
        style={{
          minWidth: '40px',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        {pageNumber}
      </Tiles.Tile>
    ));
  };

  const showEllipsis =
    currentPage <= numPages - 2 * 5 || currentPage > numPages - 5;

  return (
    <>
      {numPages > 1 ? (
        <Tiles>
          <Tiles.Tile
            onClick={() => {
              setPage((prev) => {
                if (prev === 1) {
                  return prev;
                } else {
                  return prev - 1;
                }
              });
            }}
          >
            Previous
          </Tiles.Tile>
          {pageRanges[0] && makePageElements(pageRanges[0])}
          {showEllipsis && pageRanges[1] && (
            <Tiles.Tile>
              <span className="pagination-ellipses">...</span>
            </Tiles.Tile>
          )}
          {pageRanges[1] && makePageElements(pageRanges[1])}
          <Tiles.Tile
            onClick={() => {
              setPage((prev) => {
                if (prev === numPages) {
                  return prev;
                } else {
                  return prev + 1;
                }
              });
            }}
          >
            Next
          </Tiles.Tile>
        </Tiles>
      ) : (
        <div className="light-text">Page 1 of 1</div>
      )}
    </>
  );
};

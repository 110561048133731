import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  Outlet,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import './MainLayout.pcss';
import { sendEvent } from '../utils/analytics';
import { RootState, AppDispatch } from '../store';
import { growl } from '../store/growl';
import { Growl } from '../components/Growl';
import Sidebar from '../components/Sidebar';
import Modal from '../components/Modal';
import { Toast } from '../components/Toast';
import { establishUserIsAdmin } from '../utils';

export const MainLayout = () => {
  const dispatch = useDispatch<AppDispatch>();

  const user = useSelector((state: RootState) => state.authenticatedUser);
  const storeDates = useSelector((state: RootState) => state.dateRange);
  const growlMessage = useSelector((state: RootState) => state.growl);
  const toastMessage = useSelector((state: RootState) => state.toast);
  const [searchParams, setSearchParams] = useSearchParams();
  const fromDateInParams = searchParams.get('from');
  const toDateInParams = searchParams.get('to');

  // Update url dates with Store defaults when no date provided
  useEffect(() => {
    if (fromDateInParams === null && storeDates.from !== null) {
      const from = storeDates.from;
      setSearchParams(
        (params) => {
          params.set('from', from);
          return params;
        },
        { replace: true } // Without this breaks back functionality
      );
    }
    if (toDateInParams === null && storeDates.to !== null) {
      const to = storeDates.to;
      setSearchParams(
        (params) => {
          params.set('to', to);
          return params;
        },
        {
          replace: true,
        }
      );
    }
  }, [
    setSearchParams,
    fromDateInParams,
    toDateInParams,
    storeDates.from,
    storeDates.to,
  ]);

  // View page events
  const location = useLocation();
  const { availablePubs } = useSelector((state: RootState) => state.pubs);
  const prevPathnameRef = useRef<string | undefined>(undefined);

  useEffect(() => {
    if (user === null) return;
    // Replace slashes in pathname with underscores, default to 'dashboard' if pathname is '/'
    let current_page =
      location.pathname.slice(1).split('/').join('_') || 'dashboard';
    // Special case handling for 'manage_reports' page
    if (current_page === 'reports_manage') {
      current_page = 'manage_reports';
    }

    const hasPathnameChanged = location.pathname !== prevPathnameRef.current;
    const hasPubs = availablePubs && availablePubs.length > 0;
    const hasStoreDates = storeDates && storeDates.from && storeDates.to;

    if (
      user &&
      storeDates.from &&
      storeDates.to &&
      hasPathnameChanged &&
      hasPubs &&
      hasStoreDates
    ) {
      sendEvent({
        user: user,
        publishers: availablePubs,
        name: `portal_view_${current_page}`,
        start_date: storeDates.from,
        end_date: storeDates.to,
        current_page: current_page,
      });
      prevPathnameRef.current = location.pathname;
    }
  }, [location.pathname, user, availablePubs, storeDates]);

  if (establishUserIsAdmin(user)) {
    return <Navigate replace={true} to="/admin/users/approved" />;
  }

  return (
    <div
      className="main-layout"
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        backgroundColor: '#F9FAFB',
      }}
    >
      {growlMessage && (
        <Modal onClose={() => dispatch(growl(null))}>
          <Growl />
        </Modal>
      )}
      {toastMessage && <Toast />}
      <Sidebar />
      <div className="main-layout__sidebar-spacer">
        <Outlet />
      </div>
    </div>
  );
};

import { createReducer, createAsyncThunk } from '@reduxjs/toolkit';

import { AdsByPublisher } from '../types';
import { RootState } from '.';
import { chicoryFetcher, fetchUrlFormatter, getValidPubs } from '../utils';

const initialAdsByPublisher: AdsByPublisher = [];

export const fetchAdsByPublisher = createAsyncThunk<
  AdsByPublisher,
  number,
  { state: RootState }
>('FETCH_ADS_BY_PUBLISHER', async (limit, { getState }) => {
  const state = getState();

  const pubs = getValidPubs({
    selectedPubs: state.pubs.selectedPubs,
    availablePubs: state.pubs.availablePubs,
  });
  const path = '/reporting/ads/publisher';

  if (state.dateRange.from === null || state.dateRange.to === null) {
    throw new Error('Date range in store is unexpectedly null.');
  }
  const data: AdsByPublisher = await chicoryFetcher(
    fetchUrlFormatter({
      path: path,
      from: state.dateRange.from,
      to: state.dateRange.to,
      pubs: pubs,
      limit: limit,
    })
  ).then((response) => response.json());
  return data;
});

export const adsByPublisherReducer = createReducer<AdsByPublisher>(
  initialAdsByPublisher,
  (builder) => {
    builder.addCase(fetchAdsByPublisher.fulfilled, (state, action) => {
      return action.payload;
    });
  }
);

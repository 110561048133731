import React from "react";
import creator1 from "../../assets/images/creators/creator_1.jpg";
import creator2 from "../../assets/images/creators/creator_2.jpg";
import creator3 from "../../assets/images/creators/creator_3.jpg";
import creator4 from "../../assets/images/creators/creator_4.jpg";
import creator5 from "../../assets/images/creators/creator_5.png";

const Creators: React.FC = () => {
    return (
        <div id="creators">
            <div id="creators-icons">
                <img src={creator1} />
                <img src={creator2} />
                <img src={creator3} />
                <img src={creator4} />
                <img src={creator5} />
            </div>
            <span>
                Join <strong>5,200+</strong> content creators
            </span>
        </div>
    );
};

export default Creators;
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { InstallationPlatform } from '../../types';

import './Step1.pcss';
import { useIsMobile } from '../../hooks/useIsMobile';
import { Icon } from '../../components/icons';
import { Tiles } from '../../components/Tiles';
import { Navigate } from 'react-router-dom';
import { updateInstallationPlatform } from '../../store/userSlice';
import { AlreadyCompletedOnboarding } from './onboardingUtils';

type PlatformOptions = '' | InstallationPlatform;
const Step1Install: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.authenticatedUser);
  const pubUrl = user?.publishers[0]?.name;
  const isMobile = useIsMobile();
  const [nextRedirect, setNextRedirect] = useState(false);

  // Dropdown
  const [isOpen, setIsOpen] = useState(false);
  const [option, setOption] = useState<PlatformOptions>(
    user?.installationDetails?.platform || ''
  );
  const dropdownRef = useRef<HTMLDivElement | null>(null); // Close dropdown on click
  const toggleDropdown = () => setIsOpen((prev) => !prev);
  const handleSelect = (option: PlatformOptions) => {
    setOption(option);
    setIsOpen(false);
  };
  const options: {
    value: '' | 'wordpress' | 'mediavine' | 'other';
    label: string;
  }[] = [
    { value: '', label: 'Select your platform' },
    { value: 'wordpress', label: 'WordPress' },
    { value: 'mediavine', label: 'Mediavine' },
    { value: 'other', label: 'Other / JavaScript' },
  ];

  const handleSubmit = async () => {
    try {
      if (option !== '') {
        await dispatch(updateInstallationPlatform(option));
      }
    } catch (e) {}
    setNextRedirect(true);
  };

  // Close dropdown clicking anywhere
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      event.target instanceof Node &&
      !dropdownRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };
  // Close dropdown on escape key
  const handleEscapeKey = (event: KeyboardEvent) => {
    if (event.key === 'Escape') setIsOpen(false);
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  // Copy choices
  const getInstructions = () => {
    switch (option) {
      case 'wordpress':
        return (
          <>
            <div>
              If you use WordPress to power your site, you can install the free
              Chicory WordPress plugin.
            </div>
            <p>
              <a
                href="https://wordpress.org/plugins/chicory-recipe-ingredients/"
                target="_blank"
                rel="noopener noreferrer"
                className="blue-link"
              >
                Chicory Shoppable Recipes
              </a>
            </p>
          </>
        );
      case 'mediavine':
        return (
          <>
            <div>
              If you are a Mediavine blogger, you can enable Chicory directly
              through your Mediavine dashboard. Simply toggle on the Chicory
              widget to enable ads.
            </div>
            <p>
              For Mediavine support contact:
              <br />
              <a href="mailto:publishers@mediavine.com" className="blue-link">
                publishers@mediavine.com
              </a>
            </p>
          </>
        );
      case 'other':
        return (
          <>
            <div>
              {`If you don't use WordPress for your site, and you aren't a Mediavine blogger, then you can install our technology directly on your recipe pages.`}
            </div>
            <p>
              You should download the Chicory{' '}
              <a
                href="https://static1.squarespace.com/static/5f1759f10de2dd310eb34d73/t/657c62148bb0e91c9977621a/1702650389425/Chicory_SDK_InstallationGuide_Creators.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="blue-link"
              >
                SDK Installation Guide
              </a>{' '}
              and follow the steps on page 3.
            </p>
          </>
        );
      default:
        return <></>;
    }
  };

  // Next button link
  if (nextRedirect) {
    return <Navigate to="/onboarding/step2" />;
  }

  return (
    <AlreadyCompletedOnboarding user={user}>
      <div id="onboarding-step1-container">
        {/* Upper text "getting-started" */}
        <h2 id="upper__header">Getting started with Chicory</h2>
        <p>
          Thank you for your interest in joining {`Chicory's`} network of
          creators and recipe publishers with
          <span className="text--bold"> {pubUrl}</span>. Start earning revenue
          on your recipe pages by installing {`Chicory's`} technology.
        </p>
        <p>
          {`To ensure Chicory's proprietary technology works on your site, choose the right method 
           for the platform you are using, and we will validate that your recipe content is structured 
           correctly. There are three easy steps to complete installation 
           and we'll help guide you along the way.`}
        </p>
        {/* Lower "select-platform" */}
        {isMobile ? (
          <section id="select-platform">
            <h1> Step 1 of 3: Installing</h1>
            <div id="select-platform__mobile-side-by-side">
              <Icon name="install" height="70px" width="70px" />
              <div id="select-platform__text">
                Select which platform you use for the corresponding method
                before proceeding.
              </div>
            </div>
          </section>
        ) : (
          <section id="select-platform">
            <Icon
              name="install"
              height="150px"
              width="150px"
              style={{
                marginRight: '30px',
                minWidth: '150px',
              }}
            />
            <div id="select-platform__text-container">
              <h1 id="header">Step 1 of 3: Installing</h1>
              <div id="select-platform__text">
                Select which platform you use for the corresponding method
                before proceeding.
              </div>
            </div>
          </section>
        )}
        <div id="interactive-section">
          <div id="select-platform__narrow-container">
            <div id="dropdown" ref={dropdownRef}>
              <div id="dropdown__select-box" onClick={toggleDropdown}>
                {options.find((opt) => opt.value === option)?.label}
                <Icon name="chevron-down" height="20px" width="20px" />
              </div>
              {isOpen && (
                <div id="dropdown__options">
                  {options.map((option) => (
                    <div
                      className="dropdown__option"
                      key={option.value}
                      onClick={() => handleSelect(option.value)}
                    >
                      {option.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div id="instructions-text">{getInstructions()}</div>
          </div>
          <div id="button">
            <Tiles
              style={{
                width: '100%',
                height: '45px',
                border: 'none',
                justifyContent: 'flex-end',
                marginBottom: '50px',
              }}
            >
              <Tiles.Button
                version="darkPurple"
                style={{
                  width: isMobile ? '100%' : '180px',
                  fontSize: '16px',
                  fontWeight: 700,
                  letterSpacing: '1px',
                }}
                onClick={handleSubmit}
              >
                Next
              </Tiles.Button>
            </Tiles>
          </div>
        </div>
      </div>
    </AlreadyCompletedOnboarding>
  );
};

export default Step1Install;

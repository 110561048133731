import React, { useEffect, useMemo, useState } from 'react';
import { Card } from './Card';
import { Icon } from './icons';
import { Form } from './Form';
import { Tiles } from './Tiles';
import { chicoryFetcher, debounce } from '../utils';
import { Account, Publisher } from '../types';

import './AdminAddNewUserForm.pcss';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store';
import { fetchCurrentPartners } from '../store/admin/actions';

const PUB_NO_ACCOUNT = `
  This publisher currently does not belong to any Account in Partner Portal.
  Please reach out to the Product team to add users for this publisher.
`;

const PUB_DOES_NOT_EXIST = `
  No publisher with the supplied ID exists.
`;

const FOUND_ACCOUNT = `
  We've found an existing Account with this publisher.
  If you submit new partner information below, they will gain access to the data for this Account.
`;

const NewUserForm: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const receivePubIdInput = useMemo(() => {
    return debounce((userInput: string) => {
      setPubId(userInput || null);
    }, 500);
  }, []);

  const dispatch = useDispatch<AppDispatch>();

  // Loading states.
  const [submittingNewPartner, setSubmittingNewPartner] =
    useState<boolean>(false);

  // Error and success messaging states.
  const [thisPublisherMissingAccount, setPublisherMissingAccount] =
    useState<Publisher | null>(null);
  const [publisherNotFound, setPublisherNotFound] = useState<boolean>(false);
  const [submissionError, setSubmissionError] = useState<string | null>(null);
  const [submissionSuccess, setSubmissionSuccess] = useState<boolean>(false);

  // Value states.
  const [typedPubIdForAccountLookup, setPubId] = useState<string | null>(null);
  const [foundAccount, setFoundAccount] = useState<Account | null>(null);
  const [partnerName, setPartnerName] = useState<string>('');
  const [partnerEmail, setPartnerEmail] = useState<string>('');

  // Debounce fetch a publisher if an ID is entered into the first input.
  useEffect(() => {
    setPublisherMissingAccount(null);
    setPublisherNotFound(false);
    setFoundAccount(null);
    if (typedPubIdForAccountLookup !== null) {
      chicoryFetcher(`/publishers/${typedPubIdForAccountLookup}`)
        .then((res) => res.json())
        .then((publisher: Publisher) => {
          if (publisher.accountId) {
            return chicoryFetcher(`/accounts/${publisher.accountId}`).then(
              (res) => res.json()
            );
          } else {
            setPublisherMissingAccount(publisher);
            return null;
          }
        })
        .then((account: Account) => {
          setFoundAccount(account);
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (e.message.includes('404')) {
              setPublisherNotFound(true);
            }
          }
        });
    }
  }, [typedPubIdForAccountLookup]);

  const onSubmission = async () => {
    if (foundAccount === null) return;
    setSubmissionError(null);
    if (partnerName && partnerEmail) {
      const [firstName, lastName] = partnerName.split(' ');
      if (!firstName || !lastName) {
        setSubmissionError(
          `Please input partner's first and last name separated by a space.`
        );
        return;
      }
      const email = partnerEmail;
      const status = 'approved';
      const accountId = foundAccount.id;
      try {
        setSubmittingNewPartner(true);
        const response = await chicoryFetcher(`/partners`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            firstName,
            lastName,
            email,
            status,
            accountId,
          }),
        });
        if (response.status === 201) {
          setPartnerName('');
          setPartnerEmail('');
          setSubmissionSuccess(true);
          dispatch(fetchCurrentPartners());
          setTimeout(() => setSubmissionSuccess(false), 3000);
        }
      } catch (e) {
        if (e instanceof Error) {
          if (e.message.includes('409')) {
            setSubmissionError(`Provided email already belongs to a partner.`);
          } else if (e.message.includes('400')) {
            setSubmissionError(
              `Provided information is invalid. Please check your inputs and try again.`
            );
          }
        }
      }
      setSubmittingNewPartner(false);
    } else {
      setSubmissionError('Please provide all required partner information.');
    }
  };

  return (
    <Card id="admin-new-user-form">
      <Card.Header>
        <Card.HeaderLeft>
          <Icon className="add-user-icon" name="user-01" />
          <Card.TitleSmall>Add a new partner</Card.TitleSmall>
        </Card.HeaderLeft>
        <Card.HeaderRight>
          <Icon name="x" className="close-icon" onClick={onClose} />
        </Card.HeaderRight>
      </Card.Header>
      <Card.Divider />
      <Card.Body>
        <Form.TitleSmall style={{ marginBottom: '0.5rem' }}>
          Provide the ID of a publisher this user will be associated with:
        </Form.TitleSmall>
        <Form.InputContainer>
          <input
            type="text"
            onChange={(e) => {
              receivePubIdInput(e.target.value);
            }}
          />
        </Form.InputContainer>
        <div>
          {thisPublisherMissingAccount && (
            <Form.TitleSmall>
              {thisPublisherMissingAccount.hostname}: {PUB_NO_ACCOUNT}
            </Form.TitleSmall>
          )}
          {publisherNotFound && (
            <Form.TitleSmall>{PUB_DOES_NOT_EXIST}</Form.TitleSmall>
          )}
          {foundAccount && (
            <div>
              <Form.TitleSmall>{FOUND_ACCOUNT}</Form.TitleSmall>
              <div className="account-information">
                <h4>{foundAccount.name}:</h4>
                <span>
                  {foundAccount.publishers
                    .map((pub) => {
                      return pub.hostname;
                    })
                    .join(', ')}
                </span>
              </div>
            </div>
          )}
          {foundAccount && (
            <form id="admin-new-user-form-partner-fields">
              {(submissionSuccess || submissionError) && (
                <div className="submission-message-container">
                  {submissionSuccess && (
                    <span className="green">User has been created!</span>
                  )}
                  {submissionError && (
                    <span className="red">{submissionError}</span>
                  )}
                </div>
              )}
              <div className="field-section">
                <Form.Label>
                  <span>Partner name</span>
                  <span>First and last name only</span>
                </Form.Label>
                <Form.InputContainer>
                  <input
                    type="text"
                    onChange={(e) => {
                      setPartnerName(e.target.value);
                    }}
                    value={partnerName}
                  />
                </Form.InputContainer>
              </div>

              <div className="field-section">
                <Form.Label>
                  <span>Partner email</span>
                  <span>Used for login</span>
                </Form.Label>
                <Form.InputContainer>
                  <input
                    type="text"
                    onChange={(e) => {
                      setPartnerEmail(e.target.value);
                    }}
                    value={partnerEmail}
                  />
                </Form.InputContainer>
              </div>
              <div className="buttons-container">
                <Tiles.Button
                  version="white"
                  style={{
                    fontSize: '16px',
                    width: '50%',
                    padding: '1rem',
                  }}
                  onClick={onClose}
                >
                  Cancel
                </Tiles.Button>
                <Tiles.Button
                  version="darkPurple"
                  style={{
                    fontSize: '16px',
                    width: '50%',
                    padding: '1rem',
                  }}
                  onClick={!submittingNewPartner ? onSubmission : () => {}}
                >
                  {!submittingNewPartner ? 'Add Partner' : '...'}
                </Tiles.Button>
              </div>
            </form>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default NewUserForm;

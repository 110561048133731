import React from 'react';
import { useSelector } from 'react-redux';

import './RaMetrics.pcss';
import { Icon } from './icons';
import { RootState } from '../store';
import {
  formatAsPercent,
  formatAsPercentNoDecimals,
  formatWithCommas,
} from '../utils';

export const RaMetrics: React.FC = () => {
  const raStats = useSelector((state: RootState) => state.raOverview);
  const user = useSelector((state: RootState) => state.authenticatedUser);
  const textCenter = user && user.hasPremium ? '' : 'text-center';

  // Pub metrics
  // Pub metrics
  // Pub metrics
  const ctr =
    raStats?.clicks && raStats?.views ? raStats?.clicks / raStats?.views : 0;

  const chooseRetailerRate =
    raStats?.clicks && raStats?.retailerChoices
      ? raStats?.retailerChoices / raStats?.clicks
      : 0;

  const atcRate =
    raStats?.addsToCart && raStats?.retailerChoices
      ? raStats?.addsToCart / raStats?.retailerChoices
      : 0;

  // Market metrics
  // Market metrics
  // Market metrics

  function getMarketMessage(
    pubRate: number,
    marketRate: number,
    colorize?: boolean
  ): JSX.Element | null {
    if (!user || !user.hasPremium) return null;

    let marketMessage = '';

    const diffToMarket = marketRate
      ? Number(((pubRate - marketRate) / marketRate).toFixed(2))
      : null;
    if (marketRate !== 0) {
      const formattedMarketRate = formatAsPercentNoDecimals(
        Math.abs(diffToMarket || 0)
      );

      if (diffToMarket !== null) {
        if (diffToMarket > 0) {
          marketMessage = `${formattedMarketRate} Above Market`;
        } else if (diffToMarket < 0) {
          marketMessage = `${formattedMarketRate} Below Market`;
        } else {
          marketMessage = `At Market Rate`;
        }
      } else {
        marketMessage = 'No Market Data';
      }
    }
    let color = '';
    if (colorize) {
      color = ctr < raStats?.clickThroughMarketRate ? 'red' : 'green';
    }

    // return marketMessage;
    return (
      <div
        className={`ra-metric__upper-right--stats-market text-truncator ${color}`}
      >
        {marketMessage}
      </div>
    );
  }

  // CTR Market Rate
  const ctrMarketMessage = getMarketMessage(
    ctr,
    raStats?.clickThroughMarketRate,
    true
  );
  // Choose Retailer Market Rate
  const chooseRetailerMarketMessage = getMarketMessage(
    chooseRetailerRate,
    raStats?.chooseRetailerMarketRate
  );
  // ATC Market Rate
  const atcMarketMessage = getMarketMessage(
    atcRate,
    raStats?.addToCartMarketRate
  );

  return (
    <div id="ra-metrics">
      {/* VIEWS */}
      {/* VIEWS */}
      <div className="ra-metric">
        <div className="ra-metric__upper">
          {/* Upper Left */}
          {/* Upper Left */}
          <div className="ra-metric__upper-left">
            <div className="ra-metric__upper-left--icon">
              <Icon name="eye" height="25px" width="25px" />
            </div>
          </div>
          {/* Upper Right */}
          {/* Upper Right */}
          <div className="ra-metric__upper-right">
            {/* stats */}
            <div className="ra-metric__upper-right--stats">
              <div
                className={`ra-metric__upper-right--stats-self text-truncator ${textCenter}`}
              >
                {`${formatAsPercent(ctr)} CTR`}
              </div>
              {ctrMarketMessage}
            </div>
            {/* arrow */}
            {/* arrow */}
            <div className="ra-metric__upper-right--arrow-wrapper">
              <div className="line"></div>
              <div className="arrow"></div>
            </div>
          </div>
        </div>
        {/* Lower */}
        {/* Lower */}
        <div className={`ra-metric__lower`}>
          <div className="ra-metric__lower--metric-name text-truncator ">
            Views
          </div>
          <div className="ra-metric__lower--metric-value">
            <div className="text-truncator">
              {raStats?.views ? formatWithCommas(raStats?.views) : ''}
            </div>
          </div>
        </div>
      </div>
      {/* CLICKS */}
      {/* CLICKS */}
      <div className="ra-metric">
        <div className="ra-metric__upper">
          {/* Upper Left */}
          {/* Upper Left */}
          <div className="ra-metric__upper-left">
            <div className="ra-metric__upper-left--icon">
              <Icon name="cursor-click-01" height="25px" width="25px" />
            </div>
          </div>
          {/* Upper Right */}
          {/* Upper Right */}
          <div className="ra-metric__upper-right">
            {/* stats */}
            <div className="ra-metric__upper-right--stats">
              <div
                className={`ra-metric__upper-right--stats-self text-truncator ${textCenter}`}
              >
                {`${formatAsPercent(chooseRetailerRate)} Choose Retailer Rate`}
              </div>
              {chooseRetailerMarketMessage}
            </div>
            {/* arrow */}
            {/* arrow */}
            <div className="ra-metric__upper-right--arrow-wrapper">
              <div className="line"></div>
              <div className="arrow"></div>
            </div>
          </div>
        </div>
        {/* Lower */}
        {/* Lower */}
        <div className={`ra-metric__lower`}>
          <div className="ra-metric__lower--metric-name text-truncator ">
            Clicks
          </div>
          <div className="ra-metric__lower--metric-value">
            <div className="text-truncator">
              {raStats?.clicks ? formatWithCommas(raStats?.clicks) : ''}
            </div>
          </div>
        </div>
      </div>
      {/* CHOOSE RETAILER */}
      {/* CHOOSE RETAILER */}
      <div className="ra-metric">
        <div className="ra-metric__upper">
          {/* Upper Left */}
          {/* Upper Left */}
          <div className="ra-metric__upper-left">
            <div className="ra-metric__upper-left--icon">
              <Icon name="building-02" height="25px" width="25px" />
            </div>
          </div>
          {/* Upper Right */}
          {/* Upper Right */}
          <div className="ra-metric__upper-right">
            {/* stats */}
            <div className="ra-metric__upper-right--stats">
              <div
                className={`ra-metric__upper-right--stats-self text-truncator ${textCenter}`}
              >
                {`${formatAsPercent(atcRate)} ATC Rate`}
              </div>
              {atcMarketMessage}
            </div>
            {/* arrow */}
            {/* arrow */}
            <div className="ra-metric__upper-right--arrow-wrapper">
              <div className="line"></div>
              <div className="arrow"></div>
            </div>
          </div>
        </div>
        {/* Lower */}
        {/* Lower */}
        <div className="ra-metric__lower">
          <div className="ra-metric__lower--metric-name text-truncator ">
            Choose Retailer
          </div>
          <div className="ra-metric__lower--metric-value">
            <div className="text-truncator">
              {raStats?.retailerChoices
                ? formatWithCommas(raStats?.retailerChoices)
                : ''}
            </div>
          </div>
        </div>
      </div>

      {/* ADD TO CART */}
      {/* ADD TO CART */}
      <div className="ra-metric">
        <div className="ra-metric__upper">
          {/* Upper Left */}
          {/* Upper Left */}
          <div className="ra-metric__upper-left">
            <div className="ra-metric__upper-left--icon">
              <Icon name="shopping-cart" height="25px" width="25px" />
            </div>
          </div>
          {/* Upper Right */}
          {/* Upper Right */}
          <div className="ra-metric__upper-right" />
        </div>
        {/* Lower */}
        {/* Lower */}
        <div className={`ra-metric__lower`}>
          <div className="ra-metric__lower--metric-name text-truncator ">
            ATCs
          </div>
          <div className="ra-metric__lower--metric-value">
            <div className="text-truncator">
              {raStats?.addsToCart ? formatWithCommas(raStats?.addsToCart) : ''}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { createReducer, createAsyncThunk } from '@reduxjs/toolkit';

import { AdsByRecipe } from '../types';
import { AppDispatch, RootState } from '.';
import { chicoryFetcher, fetchUrlFormatter, getValidPubs } from '../utils';

const initialAdsByRecipe: AdsByRecipe = [];

export const fetchAdsByRecipe = createAsyncThunk<
  AdsByRecipe,
  number,
  { state: RootState; dispatch: AppDispatch }
>('FETCH_ADS_BY_RECIPE', async (limit, { getState }) => {
  const state = getState();

  const pubs = getValidPubs({
    selectedPubs: state.pubs.selectedPubs,
    availablePubs: state.pubs.availablePubs,
  });
  const path = '/reporting/ads/recipe';

  try {
    if (state.dateRange.from === null || state.dateRange.to === null) {
      throw new Error('Date range in store is unexpectedly null.');
    }
    const data = await chicoryFetcher(
      fetchUrlFormatter({
        path: path,
        from: state.dateRange.from,
        to: state.dateRange.to,
        pubs: pubs,
        limit: limit,
      })
    ).then((response) => response.json());

    return data;
  } catch (error) {
    console.error('fetchAdsByRecipe error.', error);
  }
});

export const adsByRecipeReducer = createReducer<AdsByRecipe>(
  initialAdsByRecipe,
  (builder) => {
    builder.addCase(fetchAdsByRecipe.fulfilled, (state, action) => {
      return action.payload;
    });
  }
);

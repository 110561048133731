import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import store, { RootState } from '../store';
import { getAllScheduledReports } from '../store/scheduleData';
import './ManageReports.pcss';
import { Card } from '../components/Card';
import DataTable from '../components/DataTable';
import Modal from '../components/Modal';
import { EditReportForm } from '../components/EditReportForm';
import {
  deletingSchedule,
  confirmingSchedule,
  editingSchedule,
} from '../store/scheduleModals';
import { DeleteConfirm } from '../components/DeleteConfirm';
import { ConfirmSave } from '../components/ConfirmSave';

export const ManageReports: () => React.JSX.Element = () => {
  const dispatch = useDispatch<typeof store.dispatch>();

  const allReports = useSelector((state: RootState) => state.scheduledReports);
  const currentlyEditingSchedule = useSelector(
    (state: RootState) => state.editingSchedule
  );
  const currentlyDeletingSchedule = useSelector(
    (state: RootState) => state.deletingSchedule
  );
  const currentlyConfirmingSchedule = useSelector(
    (state: RootState) => state.confirmingSchedule
  );

  useEffect(() => {
    document.title = 'Chicory Manage Reports';

    (async () => {
      try {
        dispatch(getAllScheduledReports());
      } catch (error) {
        console.error('Manage reports page error.', error);
      }
    })();
  }, [dispatch]);

  return (
    <>
      <div id="manage-reports">
        {/* header */}
        {/* header */}
        <div id="manage-reports-header">
          <div id="manage-reports-header__left">
            <div id="manage-reports-header__welcome">
              {'Manage Scheduled Reports'}
            </div>
            <div id="manage-reports-header__greeting-msg">
              View, edit, or remove your scheduled reports.
            </div>
          </div>
          <div id="manage-reports-header__right"></div>
        </div>
        {/* section1 */}
        {/* section1 */}
        <div id="manage-reports-section1">
          <Card>
            <DataTable
              view="manageReports"
              data={allReports}
              style={{ borderRadius: '15px' }}
            />
          </Card>
        </div>
      </div>
      {/* Edit Modal */}
      {/* Edit Modal */}
      {currentlyEditingSchedule && (
        <Modal
          onClose={() => {
            dispatch(editingSchedule(null));
          }}
        >
          <EditReportForm
            currentReport={currentlyEditingSchedule}
            createOrUpdateMode={'update'}
          />
        </Modal>
      )}
      {/* Delete confirm */}
      {/* Delete confirm */}
      {currentlyDeletingSchedule && (
        <Modal
          onClose={() => {
            dispatch(deletingSchedule(null));
          }}
        >
          <DeleteConfirm currentSchedule={currentlyDeletingSchedule} />
        </Modal>
      )}
      {/* Confirm saved */}
      {/* Confirm saved */}
      {currentlyConfirmingSchedule && (
        <Modal
          onClose={() => {
            dispatch(confirmingSchedule(null));
          }}
        >
          <ConfirmSave currentSchedule={currentlyConfirmingSchedule} />
        </Modal>
      )}
    </>
  );
};

import { createReducer } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { Report, Reports, ReportsApi } from '../types';
import { AppDispatch, RootState } from '.';
import { chicoryFetcher, fetchUrlFormatter } from '../utils';
import {
  editingSchedule,
  confirmingSchedule,
  deletingSchedule,
  creatingSchedule,
} from './scheduleModals';

const initialReports: Reports = [];

// Fetch all thunk.
export const getAllScheduledReports = createAsyncThunk<
  Reports, // Return type
  void // No input
>('FETCH_SCHEDULED_REPORTS', async () => {
  const data: ReportsApi = await chicoryFetcher(
    fetchUrlFormatter({
      path: '/scheduled-reports',
      limit: 100,
    })
  ).then((response) => response.json());
  return data.results;
});

// 2) Create report.
export const createScheduledReport = createAsyncThunk<
  Report, // Return type
  Omit<Report, 'id'> & { showSuccessModal?: boolean }, // Input type
  { dispatch: AppDispatch; state: RootState }
>('CREATE_REPORT', async (report, { dispatch, getState }) => {
  const state = getState();
  const path = `/scheduled-reports`;
  // Don't catch errors, allow it to bubble up to component
  const response = await chicoryFetcher(path, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(report),
  });
  const data = await response.json();
  if (state.creatingSchedule === true) {
    dispatch(creatingSchedule(false));
    dispatch(confirmingSchedule({ ...data, updatingSchedule: false }));
  }
  return data;
});

// 3) Update report
export const updateScheduledReport = createAsyncThunk<
  Report, // Return type
  Report & { showSuccessModal?: boolean }, // Input type
  { dispatch: AppDispatch; state: RootState }
>('UPDATE_SCHEDULED_REPORT', async (newReportData, { dispatch, getState }) => {
  const state = getState();
  const path = `/scheduled-reports/${newReportData.id}`;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id: _, ...reportDataWithoutId } = newReportData;
  // Don't catch errors, allow it to bubble up to component
  const data = await chicoryFetcher(path, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(reportDataWithoutId),
  }).then((response) => {
    return response.json();
  });
  if (state.editingSchedule !== null) {
    dispatch(editingSchedule(null));
    dispatch(
      confirmingSchedule({
        ...data,
        updatingSchedule: true,
      })
    );
  }
  return data;
});

// 4) Delete report.
export const deleteScheduledReport = createAsyncThunk<
  { id: number }, // Return type
  number, // Input type
  { dispatch: AppDispatch }
>('DELETE_REPORT', async (id, { dispatch }) => {
  // Error catch in reducer promise rejected state
  const path = `/scheduled-reports/${id}`;
  const res = await chicoryFetcher(path, {
    method: 'DELETE',
  });
  if (res.status === 204) {
    dispatch(deletingSchedule(null));
    return { id };
  } else {
    throw new Error(`Deletion failed, non-204 status code: ${res.status}`);
  }
});

export const scheduleDataReducer = createReducer<Reports>(
  initialReports,
  (builder) => {
    // 1) Fetch all reports
    builder.addCase(getAllScheduledReports.fulfilled, (state, action) => {
      return action.payload;
    });
    // 2) Create report.
    builder.addCase(createScheduledReport.fulfilled, (state, action) => {
      return [...state, action.payload];
    });
    builder.addCase(createScheduledReport.rejected, (state, action) => {
      if (action.error.message && action.error.message.slice(0, 3) === '422') {
        // Chicoryfetcher error message structure:  "422 Network response was not ok."
        // Re-throw error to bubble back to dispatch in component
        // We do this to display the error message to the user
        throw new Error('422'); // AWS bucket is unaccessible
      } else {
        console.error('createScheduledReport error.', action.error);
      }
    });
    // 3) Update report
    builder.addCase(updateScheduledReport.fulfilled, (state, action) => {
      const index = state.findIndex(
        (report) => report.id === action.payload.id
      );
      state[index] = action.payload;
    });
    builder.addCase(updateScheduledReport.rejected, (state, action) => {
      if (action.error.message === '422') {
        // Re-throw to trigger error where dispatched, in form modal
        // We do this to display the error message to the user
        throw new Error('422'); // AWS bucket is unaccessible
      } else {
        // Will log to DataDog, not handling this in component
        console.error('updateScheduledReport error.', action.error);
      }
    });
    // 4) Delete report.
    builder.addCase(
      deleteScheduledReport.fulfilled,
      (state, action: { payload: { id: number } }) => {
        return state.filter((report) => report.id !== action.payload.id);
      }
    );
    builder.addCase(deleteScheduledReport.rejected, (state, action) => {
      console.error('deleteScheduledReport error', action.error);
    });
  }
);

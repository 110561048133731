import React, { useMemo } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { formatAsUsd } from '../utils';
import { PlacementSubcategory } from '../types';

const colorMap = {
  ['Pairings']: '#560d84',
  ['Inlines']: '#ba82de',
  ['Recipe Activation']: '#6ccafc',
  ['Innovation Products']: '#0e71bb',
};

type BreakdownDataEntry = {
  amount: number;
  subcategory: PlacementSubcategory;
};

type ChartProps = { data: BreakdownDataEntry[] };
type SubcategoryToUSDAmount = Record<PlacementSubcategory, string>;

const EarningsLegend = ({ data }: ChartProps) => {
  const productMap = useMemo(() => {
    return data.reduce((map, entry) => {
      map[entry.subcategory] = formatAsUsd(entry.amount);
      return map;
    }, {} as SubcategoryToUSDAmount);
  }, [data]);

  return (
    <div id="earnings-breakdown-legend">
      <div className="ebl-section">
        <h2>Premium</h2>
        <ul>
          <li>
            <div>
              <div
                className="legend-bullet"
                style={{ background: colorMap['Pairings'] }}
              />
              <h3>Pairings</h3>
            </div>
            <h4>{productMap['Pairings']}</h4>
          </li>
          <li>
            <div>
              <div
                className="legend-bullet"
                style={{ background: colorMap['Inlines'] }}
              />
              <h3>In-lines</h3>
            </div>
            <h4>{productMap['Inlines']}</h4>
          </li>
        </ul>
      </div>
      <div className="ebl-section">
        <h2>Others</h2>
        <ul>
          <li>
            <div>
              <div
                className="legend-bullet"
                style={{ background: colorMap['Recipe Activation'] }}
              />
              <h3>Recipe Activation</h3>
            </div>
            <h4>{productMap['Recipe Activation']}</h4>
          </li>
          <li>
            <div>
              <div
                className="legend-bullet"
                style={{ background: colorMap['Innovation Products'] }}
              />
              <h3>Innovation Products</h3>
            </div>
            <h4>{productMap['Innovation Products']}</h4>
          </li>
        </ul>
      </div>
    </div>
  );
};

export const EarningsBreakdownChart = ({ data }: ChartProps) => {
  const earningsTotal = useMemo(() => {
    return data.reduce((sum, entry) => {
      return sum + entry.amount;
    }, 0);
  }, [data]);

  const hasEarnings = earningsTotal !== 0;
  const containerStyle = hasEarnings
    ? {}
    : { background: 'rgba(0, 0, 0, 0.1)', marginBottom: '1rem' };
  return (
    <div id="earnings-charts-top-level-container">
      <div id="earnings-donut-chart-container" style={containerStyle}>
        {hasEarnings && (
          <ResponsiveContainer>
            <PieChart>
              <Pie data={data} dataKey="amount" innerRadius={'40%'}>
                {data.map((entry) => {
                  return (
                    <Cell
                      key={entry.subcategory}
                      fill={colorMap[entry.subcategory]}
                    />
                  );
                })}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          </ResponsiveContainer>
        )}
      </div>
      <EarningsLegend data={data} />
    </div>
  );
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: { payload: BreakdownDataEntry }[];
}
const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
  if (active && payload && payload[0]) {
    const entry = payload[0].payload;
    return (
      <div
        style={{
          padding: '0.5rem',
          backgroundColor: 'white',
          border: '1px solid #ccc',
        }}
      >
        <span>{entry.subcategory}</span>
        <br />
        <span>{formatAsUsd(entry.amount)}</span>
      </div>
    );
  }
  return null;
};

export default EarningsBreakdownChart;

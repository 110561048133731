// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#main-faq {
}

#faq-header {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  min-height: 70px;
}

#faq-header__welcome {
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 5px;
  }

#faq-header__greeting-msg {
    font-size: 18px;
    color: #475467;
    font-weight: 100;
    margin-bottom: 5px;
  }

#main-faq a {
  color: blue;
}

#faq-layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  column-gap: 40px;
  font-size: 18px;
  font-weight: 100;
  margin-top: 40px;
}

.collapsible-wrapper {
  min-height: 100px;
}

.trigger {
  cursor: pointer;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.trigger-icon {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transition: transform 0.3s;
  margin-right: 10px;
}

.Collapsible__contentInner {
  background-color: #fefefe;
  padding: 0.5em 1.5em;
  border-radius: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Faq.pcss"],"names":[],"mappings":"AAKA;AACA;;AAEA;EACE,mBAA2B;EAC3B,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;EACf,WAAW;EACX,gBAAgB;AAYlB;;AAXE;IACE,eAAe;IACf,gBAAgB;IAChB,kBAAkB;EACpB;;AACA;IACE,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,kBAAkB;EACpB;;AAGF;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,qBAAgB;EAAhB,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,qBAAqB;EACrB,YAAY;EACZ,0BAA0B;EAC1B,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,oBAAoB;EACpB,kBAAkB;AACpB","sourcesContent":["$verticalGap: 20px;\n$mediascreen: 990px;\n$horizontalGap: 15px;\n$gridGap: 12px;\n\n#main-faq {\n}\n\n#faq-header {\n  margin-bottom: $verticalGap;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  flex-wrap: wrap;\n  width: 100%;\n  min-height: 70px;\n  &__welcome {\n    font-size: 30px;\n    font-weight: 400;\n    margin-bottom: 5px;\n  }\n  &__greeting-msg {\n    font-size: 18px;\n    color: #475467;\n    font-weight: 100;\n    margin-bottom: 5px;\n  }\n}\n\n#main-faq a {\n  color: blue;\n}\n\n#faq-layout {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  column-gap: 40px;\n  font-size: 18px;\n  font-weight: 100;\n  margin-top: 40px;\n}\n\n.collapsible-wrapper {\n  min-height: 100px;\n}\n\n.trigger {\n  cursor: pointer;\n  font-weight: 400;\n  display: flex;\n  align-items: center;\n  padding: 10px 0;\n}\n\n.trigger-icon {\n  border: solid black;\n  border-width: 0 2px 2px 0;\n  display: inline-block;\n  padding: 3px;\n  transition: transform 0.3s;\n  margin-right: 10px;\n}\n\n.Collapsible__contentInner {\n  background-color: #fefefe;\n  padding: 0.5em 1.5em;\n  border-radius: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from 'react';
import { Tiles } from './Tiles';
import { Icon } from './icons';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import dayjs from 'dayjs';
import { DateRangePicker } from 'react-date-range';
import { useDispatch, useSelector } from 'react-redux';
import { dateRangeAction } from '../store/dateSlice';
import { RootState } from '../store';
import './DatePicker.pcss';
import { YYYYMMDD } from '../utils';
import { useLocation, useSearchParams } from 'react-router-dom';
import { sendEvent } from '../utils/analytics';
import {
  defineds,
  newStaticRanges,
  newInputRanges,
} from './DatePickerDateRanges';

export const DatePicker = () => {
  const dispatch = useDispatch();
  const dates = useSelector((state: RootState) => state.dateRange);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectionRange, setSelectionRange] = useState({
    startDate: dayjs(dates.from).toDate(),
    endDate: dayjs(dates.to).toDate(),
    key: 'chicoryDates',
  });

  const [, setSearchParams] = useSearchParams();
  const { availablePubs } = useSelector((state: RootState) => state.pubs);
  const user = useSelector((state: RootState) => state.authenticatedUser);
  const location = useLocation();
  const handleSelect = (ranges: any) => {
    setSelectionRange(ranges.chicoryDates);
  };

  const handleApply = () => {
    dispatch(
      dateRangeAction({
        newFrom: YYYYMMDD(selectionRange.startDate),
        newTo: YYYYMMDD(selectionRange.endDate),
        setSearchParams: setSearchParams,
      })
    );
    setSelectionRange({
      startDate: dayjs(selectionRange.startDate).toDate(),
      endDate: dayjs(selectionRange.endDate).toDate(),
      key: 'chicoryDates',
    });
    setShowCalendar(false);
    sendEvent({
      user: user,
      publishers: availablePubs,
      name: 'portal_filter_update_date',
      start_date: dayjs(selectionRange.startDate).format('YYYY-MM-DD'),
      end_date: dayjs(selectionRange.endDate).format('YYYY-MM-DD'),
      // Replace slashes in pathname with underscores, default to 'dashboard' if pathname is '/'
      current_page:
        location.pathname.slice(1).split('/').join('_') || 'dashboard',
    });
  };

  // For display in button
  let datesInButton;
  switch (true) {
    case dayjs(dates.from).format('YYYY') !== dayjs(dates.to).format('YYYY'):
      // Different years
      datesInButton =
        dayjs(dates.from).format('MMM D, YYYY') +
        ' - ' +
        dayjs(dates.to).format('MMM D, YYYY');
      break;
    case dayjs(dates.from).format('MMM') !== dayjs(dates.to).format('MMM'):
      // We know years are the same, test different months
      datesInButton =
        dayjs(dates.from).format('MMM D') +
        '-' +
        dayjs(dates.to).format('MMM D, YYYY');
      break;
    case dayjs(dates.from).format('D') !== dayjs(dates.to).format('D'):
      // We know years and months are the same, test different days
      datesInButton =
        dayjs(dates.from).format('MMM D') +
        '-' +
        dayjs(dates.to).format('D, YYYY');
      break;
    default:
      // We know years, months, and days are the same
      datesInButton = dayjs(dates.from).format('MMM D, YYYY');
  }

  return (
    <div className="datepicker">
      <Tiles style={{ background: 'white' }}>
        <Tiles.Tile onClick={() => setShowCalendar((prev) => !prev)}>
          <Icon name="calendar" />
          <div className="w-spacer" />
          <div>{datesInButton}</div>
          <div className="w-spacer" />
          <Icon name="chevron-down" style={{ marginLeft: '5px' }} />
        </Tiles.Tile>
      </Tiles>

      {showCalendar && (
        <div className="datepicker-popout">
          <DateRangePicker
            ranges={[selectionRange]}
            minDate={defineds().minDate}
            maxDate={defineds().yesterday}
            staticRanges={newStaticRanges()}
            inputRanges={newInputRanges()}
            rangeColors={['#590b8a']}
            months={2}
            direction="horizontal"
            onChange={handleSelect}
          />
          <hr />
          <button
            className="datepicker-cancel-button"
            onClick={() => setShowCalendar(false)}
          >
            Cancel
          </button>
          <button className="datepicker-apply-button" onClick={handleApply}>
            Apply
          </button>
        </div>
      )}
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import store, { RootState } from '../store';
import { useSearchParams } from 'react-router-dom';

import './RecipeActivation.pcss';
import Modal from '../components/Modal';
import { EditReportForm } from '../components/EditReportForm';
import { ConfirmSave } from '../components/ConfirmSave';
import { PubPicker } from '../components/PubPicker';
import { DatePicker } from '../components/DatePicker';
import { DataTablePagination } from '../components/DataTablePagination';
import { fetchRaByRetailer } from '../store/raByRetailer';
import { fetchRaByRecipe } from '../store/raByRecipe';
import { fetchRaByIngredient } from '../store/raByIngredient';
import { fetchRaByPublisher } from '../store/raByPublisher';
import { confirmingSchedule, creatingSchedule } from '../store/scheduleModals';
import { downloadCSV } from '../utils';
import { sendEvent } from '../utils/analytics';
import { ReportCategory, ReportGrouping } from '../types';
import { growl } from '../store/growl';
import { toast } from '../store/toast';

export const RecipeActivation: () => React.JSX.Element = () => {
  const dispatch = useDispatch<typeof store.dispatch>();
  // Subscribe to store
  const user = useSelector((state: RootState) => state.authenticatedUser);
  const allPublisherData = useSelector(
    (state: RootState) => state.raByPublisher
  );
  const allIngredientData = useSelector(
    (state: RootState) => state.raByIngredient
  );
  const allRecipeData = useSelector((state: RootState) => state.raByRecipe);
  const allRetailerData = useSelector((state: RootState) => state.raByRetailer);
  const { selectedPubs, availablePubs } = useSelector(
    (state: RootState) => state.pubs
  );
  const storeDates = useSelector((state: RootState) => state.dateRange);
  const currentlyCreatingSchedule = useSelector(
    (state: RootState) => state.creatingSchedule
  );
  const currentlyConfirmingSchedule = useSelector(
    (state: RootState) => state.confirmingSchedule
  );

  // Schedule reports
  const [grouping, setGrouping] = useState<ReportGrouping>('by_recipe');
  const [category, setCategory] = useState<ReportCategory>('recipe_activation');

  // Subscribe to url params
  const [searchParams] = useSearchParams();
  const fromDateInParams = searchParams.get('from');
  const toDateInParams = searchParams.get('to');

  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    if (
      fromDateInParams === storeDates.from &&
      toDateInParams === storeDates.to &&
      availablePubs !== null
    ) {
      (async () => {
        try {
          dispatch(fetchRaByRetailer(50));
          dispatch(fetchRaByRecipe(50));
          dispatch(fetchRaByIngredient(50));
          dispatch(fetchRaByPublisher(50));
        } catch (error) {
          console.error('RA report page error.', error);
        }
      })();
    }
  }, [
    dispatch,
    storeDates.from,
    storeDates.to,
    fromDateInParams,
    toDateInParams,
    availablePubs,
    selectedPubs,
  ]);

  const download = (path: string, fileNamePrefix: string) => {
    return async () => {
      setIsDownloading(true);
      dispatch(toast('Downloading your report, this may take some time.'));
      try {
        if (storeDates.from === null || storeDates.to === null) {
          throw new Error('Date range in store is unexpectedly null.');
        }
        await downloadCSV({
          path,
          fileNamePrefix,
          from: storeDates.from,
          to: storeDates.to,
          pubs: selectedPubs,
        });
        sendEvent({
          user: user,
          publishers: availablePubs,
          name: 'portal_report_download',
          start_date: storeDates.from,
          end_date: storeDates.to,
          current_page: 'reports_recipe_activation',
          report_name: fileNamePrefix,
        });
      } catch (error) {
        dispatch(
          growl(
            'The data was too large to be downloaded directly. Please use the “Schedule Reports” button instead.'
          )
        );
        console.error('RA report download error.', error);
      }
      setIsDownloading(false);
      dispatch(toast(null));
    };
  };

  return (
    <>
      <div id="recipe-activation">
        {/* header */}
        {/* header */}
        <div id="recipe-activation-header">
          <div id="recipe-activation-header__left">
            <div id="recipe-activation-header__welcome">Recipe Activation</div>
            <div id="recipe-activation-header__greeting-msg">
              Displaying the top 50 results. Download for full data.
            </div>
          </div>
          <div id="recipe-activation-header__right">
            <PubPicker />
            <div className="w-spacer" />
            <DatePicker />
          </div>
        </div>
        {/* 1. By Recipe */}
        {/* 1. By Recipe */}
        <div id="recipe-activation-section1">
          <DataTablePagination
            data={allRecipeData}
            title="RA Performance by Recipe"
            view="raRecipes"
            isDownloading={isDownloading}
            onDownload={download(
              '/reporting/ra/recipe',
              'Chicory_recipe_activation_by_recipe'
            )}
            onSchedule={() => {
              setCategory('recipe_activation');
              setGrouping('by_recipe');
              dispatch(creatingSchedule(true));
            }}
          />
        </div>
        {/* 2. By Ingredient */}
        {/* 2. By Ingredient */}
        <div id="recipe-activation-section2">
          <DataTablePagination
            data={allIngredientData}
            title="RA Performance by Ingredient"
            view="raIngredients"
            isDownloading={isDownloading}
            onDownload={download(
              '/reporting/ra/ingredient',
              'Chicory_recipe_activation_by_ingredient'
            )}
            onSchedule={() => {
              setCategory('recipe_activation');
              setGrouping('by_ingredient');
              dispatch(creatingSchedule(true));
            }}
          />
        </div>
        {/* 3. By Retailer */}
        {/* 3. By Retailer */}
        <div id="recipe-activation-section3">
          <DataTablePagination
            data={allRetailerData}
            title="RA Performance by Retailer"
            view="raRetailers"
            isDownloading={isDownloading}
            onDownload={download(
              '/reporting/ra/retailer',
              'Chicory_recipe_activation_by_retailer'
            )}
            onSchedule={() => {
              setCategory('recipe_activation');
              setGrouping('by_retailer');
              dispatch(creatingSchedule(true));
            }}
          />
        </div>
        {/* 4. By Domain */}
        {/* 4. By Domain */}
        <div id="recipe-activation-section4">
          <DataTablePagination
            data={allPublisherData}
            title="RA Performance by Domain"
            view="raPublishers"
            isDownloading={isDownloading}
            onDownload={download(
              '/reporting/ra/publisher',
              'Chicory_recipe_activation_by_publisher'
            )}
            onSchedule={() => {
              setCategory('recipe_activation');
              setGrouping('by_domain');
              dispatch(creatingSchedule(true));
            }}
          />
        </div>
      </div>
      {/* Create schedule */}
      {/* Create schedule */}
      {currentlyCreatingSchedule && (
        <Modal
          onClose={() => {
            dispatch(creatingSchedule(false));
          }}
        >
          <EditReportForm
            createOrUpdateMode={'create'}
            category={category}
            grouping={grouping}
          />
        </Modal>
      )}
      {/* Confirm saved */}
      {/* Confirm saved */}
      {currentlyConfirmingSchedule && (
        <Modal
          onClose={() => {
            dispatch(confirmingSchedule(null));
          }}
        >
          <ConfirmSave currentSchedule={currentlyConfirmingSchedule} />
        </Modal>
      )}
    </>
  );
};

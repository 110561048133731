// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#admin-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #f9fafb;
}

#admin-nav {
  width: 100%;
  height: 4rem;
  padding: 0 3rem;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
}

#admin-nav__left {
    display: flex;
    align-items: center;
  }

#admin-nav__right {
    display: flex;
    align-items: center;
    width: 2rem;
    display: flex;
    justify-content: space-between;
  }

#admin-layout-spacer {
  padding: 0 4rem;
  width: 100%;
}

.table__pending-users th:first-child,
  .table__pending-users td:first-child {
    width: 3rem;
    border-right: none;
  }

.table__pending-users th:first-child + th,
  .table__pending-users td:first-child + td {
    border-left: none;
  }

#admin-current-users-table-display .table-head {
    height: 40px;
  }

#admin-current-users-table-display .table-data-row {
    height: 40px;
    font-size: 0.9rem;
  }

#admin-current-users-table-display .table-cell-domain-link {
    color: purple;
    font-size: 0.8rem
  }

#admin-current-users-table-display .table-cell-domain-link::after {
      content: ', ';
    }

#admin-current-users-table-display .table-cell-domain-link:last-child::after {
        content: '';
      }

#admin-current-users-table-display .table-cell-domain-link:hover {
      text-decoration: underline;
    }

#admin-current-users-table-display .table-data-cell[\\:has\\(.table-cell-domain-link\\)] {
    overflow: overlay;
  }

#admin-current-users-table-display .table-data-cell:has(.table-cell-domain-link) {
    overflow: overlay;
  }
`, "",{"version":3,"sources":["webpack://./src/pages/AdminLayout.pcss"],"names":[],"mappings":"AAGA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,gCAAgC;EAChC,aAAa;EACb,8BAA8B;AAahC;;AAXE;IACE,aAAa;IACb,mBAAmB;EACrB;;AACA;IACE,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb,8BAA8B;EAChC;;AAGF;EACE,eAAe;EACf,WAAW;AACb;;AAGE;;IAEE,WAAW;IACX,kBAAkB;EACpB;;AACA;;IAEE,iBAAiB;EACnB;;AAIA;IACE,YAAY;EACd;;AACA;IACE,YAAY;IACZ,iBAAiB;EACnB;;AACA;IACE,aAAa;IACb;EAYF;;AAXE;MACE,aAAa;IACf;;AAEE;QACE,WAAW;MACb;;AAEF;MACE,0BAA0B;IAC5B;;AAEF;IACE,iBAAiB;EACnB;;AAFA;IACE,iBAAiB;EACnB","sourcesContent":["$midDesktop: 990px;\n$sidebarWidth: 280px;\n\n#admin-layout {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 100vh;\n  background-color: #f9fafb;\n}\n\n#admin-nav {\n  width: 100%;\n  height: 4rem;\n  padding: 0 3rem;\n  border-bottom: 1px solid #e0e0e0;\n  display: flex;\n  justify-content: space-between;\n\n  &__left {\n    display: flex;\n    align-items: center;\n  }\n  &__right {\n    display: flex;\n    align-items: center;\n    width: 2rem;\n    display: flex;\n    justify-content: space-between;\n  }\n}\n\n#admin-layout-spacer {\n  padding: 0 4rem;\n  width: 100%;\n}\n\n.table__pending-users {\n  & th:first-child,\n  & td:first-child {\n    width: 3rem;\n    border-right: none;\n  }\n  & th:first-child + th,\n  & td:first-child + td {\n    border-left: none;\n  }\n}\n\n#admin-current-users-table-display {\n  .table-head {\n    height: 40px;\n  }\n  .table-data-row {\n    height: 40px;\n    font-size: 0.9rem;\n  }\n  .table-cell-domain-link {\n    color: purple;\n    font-size: 0.8rem;\n    &::after {\n      content: ', ';\n    }\n    &:last-child {\n      &::after {\n        content: '';\n      }\n    }\n    &:hover {\n      text-decoration: underline;\n    }\n  }\n  .table-data-cell:has(.table-cell-domain-link) {\n    overflow: overlay;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import dayjs from 'dayjs';

import { apiUrl, cdnUrl } from '../config';
import { CSVDownloadParams, PubsState, UserState } from '../types';
import { PartnerRawFromAPI } from '../store/admin/actions';

export const chicoryFetcher = async (url: string, options?: RequestInit) => {
  // String parse url.
  let relativePath = url.replace(apiUrl, '');
  if (relativePath[0] === '/') {
    relativePath = relativePath.slice(1);
  }

  const incomingHeaders = options?.headers;
  options = {
    ...options,
    headers: { 'X-CHICORY-CSRF-PROTECTION': '1' },
    credentials: 'include',
  };
  if (incomingHeaders) {
    options.headers = { ...options.headers, ...incomingHeaders };
  }

  const response = await fetch(`${apiUrl}/${relativePath}`, options);
  if (response.status === 401) {
    // auth0 login screen takes a redirect param
    window.location.href = `${apiUrl}/auth/login?redirectUrl=${encodeURIComponent(
      window.location.href
    )}`;
    throw new Error('User not authenticated.');
  } else if (response.status === 403) {
    // Admin users would fall here.
    window.location.href = `${cdnUrl}/unauthorized`;
    throw new Error('User is not authorized.');
  } else if (response.ok) {
    // Either you're authenticated or the route doesn't require authentication
    return response;
  } else {
    throw new Error(`${response.status} Network response was not ok.`);
  }
};

export const establishUserIsAdmin = (user: UserState) => {
  if (!user) throw new Error('User value is unexpectedly empty.');
  if (!user.role) throw new Error('User missing role entirely.');
  return user.role === 'admin';
};

export const delay = (mil: number) => {
  return new Promise((res) => setTimeout(res, mil));
};

export const YYYYMMDD = (date: Date | string) =>
  dayjs(date).format('YYYY-MM-DD');

export const fetchUrlFormatter = ({
  path,
  pubs,
  from,
  to,
  limit,
  reportStyle,
  status,
  fileNamePrefix,
  creationMethod,
}: {
  path: string;
  pubs?: number[] | undefined;
  from?: string;
  to?: string;
  limit?: number | null;
  reportStyle?: string;
  status?: string;
  fileNamePrefix?: string;
  creationMethod?: 'signup';
}) => {
  // Protocol and domain are required for URL constructor but we won't use them
  const finalUrl = new URL(path, 'http://www.example.com');
  if (from && to) {
    finalUrl.searchParams.append('from', from);
    finalUrl.searchParams.append('to', to);
  }

  if (limit) {
    finalUrl.searchParams.append('limit', limit.toString());
  }

  if (pubs && pubs.length) {
    finalUrl.searchParams.append('pubId', pubs.toString());
  }

  if (reportStyle) {
    finalUrl.searchParams.append('reportStyle', reportStyle);
  }

  if (status) {
    finalUrl.searchParams.append('status', status);
  }

  if (fileNamePrefix) {
    finalUrl.searchParams.append('fileNamePrefix', fileNamePrefix);
  }

  if (creationMethod) {
    finalUrl.searchParams.append('creationMethod', creationMethod);
  }

  return finalUrl.pathname + finalUrl.search;
};

export const getValidPubs = ({ selectedPubs, availablePubs }: PubsState) => {
  let pubs: number[] | undefined;
  if (selectedPubs?.length === 0) {
    pubs = undefined;
  } else if (availablePubs !== null) {
    const availablePubIds = availablePubs.map((pub) => pub.id);
    const validPubs = selectedPubs.filter((pub) => {
      return availablePubIds.includes(pub);
    });
    if (validPubs.length === 0) {
      pubs = undefined;
    } else {
      pubs = validPubs;
    }
  }
  return pubs;
};

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const downloadCSV = async (params: CSVDownloadParams) => {
  return await chicoryFetcher(
    fetchUrlFormatter({
      path: params.path,
      from: params.from,
      to: params.to,
      pubs: params.pubs,
      reportStyle: params.reportStyle,
      limit: params.limit,
      status: params.status,
      fileNamePrefix: params.fileNamePrefix,
      creationMethod: params.creationMethod,
    }),
    { headers: { Accept: 'text/csv' } }
  )
    .then((response) => response.blob())
    .then((blob) => {
      const file = new File(
        [blob],
        `${params.fileNamePrefix}_${Date.now()}.csv`
      );
      const url = window.URL.createObjectURL(file);
      const fileLink = document.createElement('a');
      fileLink.href = url;
      fileLink.download = file.name;
      fileLink.click();
      fileLink.remove();
    });
};

export const partnerSearchFilter = (
  partner: PartnerRawFromAPI,
  searchText: string
) => {
  return (
    partner.firstName?.toLowerCase().includes(searchText.toLowerCase()) ||
    partner.lastName?.toLowerCase().includes(searchText.toLowerCase()) ||
    partner.email?.toLowerCase().includes(searchText.toLowerCase()) ||
    partner.paypalEmail?.toLowerCase().includes(searchText.toLowerCase()) ||
    partner.publishers
      ?.map((pub) => pub.hostname)
      .join(' ')
      .includes(searchText.toLowerCase()) ||
    partner.source?.toLowerCase().includes(searchText.toLowerCase())
  );
};

// Format numbers in 1 of 4 ways:
export const formatWithMorK = (num: number) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K';
  } else {
    return num.toString();
  }
};

export const formatAsPercent = (() => {
  const numAsPercent = new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  return (num: number) => {
    return numAsPercent.format(num);
  };
})();

export const formatAsPercentNoDecimals = (() => {
  const numAsPercent = new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: 0,
  });
  return (num: number) => {
    return numAsPercent.format(num);
  };
})();

export const formatWithCommas = (() => {
  const formattedNum = new Intl.NumberFormat('en-US');
  return (num: number) => {
    return formattedNum.format(num);
  };
})();

export const formatAsUsd = (() => {
  const formattedNum = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return (num: number) => {
    return formattedNum.format(num);
  };
})();

export const formatAsHumanDate = (() => {
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
  return (date: string) => {
    // Manually parse string otherwise timezone issues
    const [year, month, day] = date.split('-').map(Number);
    if (!year || !month || !day)
      throw new Error(
        `Provided date ${date} not properly formatted with hyphens (ISO8601).`
      );
    return formattedDate.format(new Date(year, month - 1, day));
  };
})();

export const debounce = (func: (...myArgs: any[]) => any, delayMs: number) => {
  let currentDelay: null | ReturnType<typeof setTimeout> = null;
  return (...args: any[]) => {
    if (currentDelay) {
      clearTimeout(currentDelay);
    }
    currentDelay = setTimeout(() => {
      func(...args);
      currentDelay = null;
    }, delayMs);
  };
};

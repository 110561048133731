import React, { useCallback, useEffect } from 'react';
import './Modal.pcss';

type ModalProps = { children: React.ReactNode; onClose: () => void };

const Modal = ({ children, onClose }: ModalProps) => {
  // For scroll lock
  const scrollPosition = window.scrollY || document.documentElement.scrollTop;

  const releaseScrollLock = useCallback(() => {
    document.body.classList.remove('modal-open');
    // Remove any margin from the body element
    document.body.style.marginTop = '';
    // Restore its scroll position
    window.scrollTo(0, scrollPosition);
  }, [scrollPosition]);

  const scrollLock = useCallback(() => {
    document.body.classList.add('modal-open');
    document.body.style.marginTop = -scrollPosition + 'px';
  }, [scrollPosition]);

  // Escape key closes modal
  const listener = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        releaseScrollLock();
        onClose();
      }
    },
    [releaseScrollLock, onClose]
  );

  useEffect(() => {
    scrollLock();
    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
      releaseScrollLock();
    };
  }, [listener, releaseScrollLock, scrollLock]);

  return (
    <div id="modal-container" onMouseDown={onClose}>
      <div
        id="modal-content"
        onClick={(e) => {
          e.stopPropagation();
        }}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </div>
  );
};
export default Modal;

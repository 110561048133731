import React from 'react';
import { Link } from 'react-router-dom';

import './PageNotFound.pcss';
import logo from '../assets/images/chicory-logo.png';

export default function PageNotFound() {
  return (
    <>
      <div id="page-not-found-wrapper">
        <div id="page-not-found-message">Oops, this page does not exist!</div>
        <Link to={{ pathname: '/', search: window.location.search }}>
          <div id="page-not-found-home-link">Go to home</div>
        </Link>
      </div>
      <div id="page-not-found-logo-wrapper">
        <img id="page-not-found-logo" src={logo} alt="chicory logo" />
      </div>
    </>
  );
}

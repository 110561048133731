import React, { useMemo } from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
  AreaChart,
} from 'recharts';
import { AdsByHistorical, DateRange } from '../types';
import dayjs from 'dayjs';
import DateAndCurrencyValueTooltip from './DateAndCurrencyValueTooltip';

const transformData = (data: AdsByHistorical, storeDates: DateRange) => {
  const chartData = [];
  const startDate = dayjs(storeDates.from);
  const endDate = dayjs(storeDates.to);

  for (let day = startDate; day <= endDate; day = day.add(1, 'day')) {
    const dataForDay = data.find((e) => dayjs(e.date).isSame(day, 'day'));
    chartData.push({
      date: day.toDate(),
      revenue: dataForDay ? parseFloat(dataForDay.revenue) : 0,
    });
  }

  return chartData;
};

const RevenueLineChart: React.FC<{
  data: AdsByHistorical;
  storeDates: DateRange;
}> = ({ data, storeDates }) => {
  if (!data || data.length === 0) return <div>No data available</div>;
  const chartData = useMemo(
    () => transformData(data, storeDates),
    [data, storeDates]
  );

  const tickFormatter = (tick: number) => dayjs(tick).format('MM/DD/YY');

  return (
    <ResponsiveContainer>
      <AreaChart margin={{ left: -15, bottom: 10 }} data={chartData}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="20%" stopColor="#6c239a" stopOpacity={0.1} />
            <stop offset="95%" stopColor="#6c239a" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="date"
          tickFormatter={tickFormatter}
          style={{ fontWeight: 400, fontSize: '0.75rem' }}
        />
        <YAxis
          axisLine={false}
          dataKey="revenue"
          domain={[0, 'auto']}
          style={{ fontWeight: 200, fontSize: '0.8rem' }}
          padding={{ bottom: 2 }}
          tickMargin={0}
          tickLine={false}
          tickFormatter={(amount) => {
            if (amount === 0) return '';
            if (amount < 1000) return `$${amount}`;
            return `$${amount / 1000}k`;
          }}
        />
        <CartesianGrid
          strokeDasharray="15 0"
          strokeWidth={0.2}
          stroke={'#000'}
          strokeOpacity={'100%'}
          vertical={false}
        />
        <Area
          type="monotone"
          dataKey="revenue"
          stroke="#6c239a"
          strokeWidth={2}
          fillOpacity={1}
          fill="url(#colorUv)"
        />
        <Tooltip content={<DateAndCurrencyValueTooltip />} />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default RevenueLineChart;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#media {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#media-header {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  min-height: 70px;
}

#media-header__welcome {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 5px;
  }

#media-header__greeting-msg {
    font-size: 18px;
    color: #475467;
    font-weight: 100;
    margin-bottom: 5px;
  }

#media-header__right {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
#media-section1,
#media-section2 {
  width: 100%;
  margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Media.pcss"],"names":[],"mappings":"AAKA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,mBAA2B;EAC3B,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;EACf,WAAW;EACX,gBAAgB;AAqBlB;;AApBE;IACE,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;EACpB;;AACA;IACE,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,kBAAkB;EACpB;;AACA;IACE,aAAa;IACb,mBAAmB;IACnB,eAAe;EACjB;AAEF;;EAEE,WAAW;EACX,mBAA2B;AAC7B","sourcesContent":["$verticalGap: 20px;\n$mediascreen: 990px;\n$horizontalGap: 15px;\n$gridGap: 12px;\n\n#media {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}\n\n#media-header {\n  margin-bottom: $verticalGap;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  flex-wrap: wrap;\n  width: 100%;\n  min-height: 70px;\n  &__welcome {\n    display: flex;\n    flex-wrap: nowrap;\n    align-items: center;\n    position: relative;\n    font-size: 30px;\n    font-weight: 400;\n    margin-bottom: 5px;\n  }\n  &__greeting-msg {\n    font-size: 18px;\n    color: #475467;\n    font-weight: 100;\n    margin-bottom: 5px;\n  }\n  &__right {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n  }\n}\n#media-section1,\n#media-section2 {\n  width: 100%;\n  margin-bottom: $verticalGap;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import store, { RootState } from '../store';
import { useSearchParams } from 'react-router-dom';

import './Media.pcss';
import Modal from '../components/Modal';
import { PubPicker } from '../components/PubPicker';
import { DatePicker } from '../components/DatePicker';
import { DataTablePagination } from '../components/DataTablePagination';
import { fetchAdsByRecipe } from '../store/adsByRecipe';
import { EditReportForm } from '../components/EditReportForm';
import { ConfirmSave } from '../components/ConfirmSave';
import { fetchAdsByIngredient } from '../store/adsByIngredient';
import { fetchAdsByPublisher } from '../store/adsByPub';
import { confirmingSchedule, creatingSchedule } from '../store/scheduleModals';
import { downloadCSV } from '../utils';
import { sendEvent } from '../utils/analytics';
import { ReportCategory, ReportGrouping } from '../types';
import { growl } from '../store/growl';
import { toast } from '../store/toast';
import { Icon } from '../components/icons';
import { Tooltip } from '../components/Tooltip';

export const Media: () => React.JSX.Element = () => {
  const dispatch = useDispatch<typeof store.dispatch>();
  // Subscribe to store
  const user = useSelector((state: RootState) => state.authenticatedUser);
  const allPublisherData = useSelector(
    (state: RootState) => state.adsByPublisher
  );
  const allIngredientData = useSelector(
    (state: RootState) => state.adsByIngredient
  );
  const allRecipeData = useSelector((state: RootState) => state.adsByRecipe);

  const { selectedPubs, availablePubs } = useSelector(
    (state: RootState) => state.pubs
  );
  const storeDates = useSelector((state: RootState) => state.dateRange);
  const currentlyCreatingSchedule = useSelector(
    (state: RootState) => state.creatingSchedule
  );
  const currentlyConfirmingSchedule = useSelector(
    (state: RootState) => state.confirmingSchedule
  );

  // Schedule Reports
  const [grouping, setGrouping] = useState<ReportGrouping>('by_recipe');
  const [category, setCategory] = useState<ReportCategory>('media');

  // Subscribe to url params
  const [searchParams] = useSearchParams();
  const fromDateInParams = searchParams.get('from');
  const toDateInParams = searchParams.get('to');

  const [isDownloading, setIsDownloading] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  // Feature flags
  const { showImpressions } = useSelector(
    (state: RootState) => state.featureFlags
  );

  useEffect(() => {
    if (
      fromDateInParams === storeDates.from &&
      toDateInParams === storeDates.to &&
      availablePubs !== null
    ) {
      (async () => {
        try {
          dispatch(fetchAdsByRecipe(50));
          dispatch(fetchAdsByIngredient(50));
          dispatch(fetchAdsByPublisher(50));
        } catch (error) {
          console.error('Media report page error.', error);
        }
      })();
    }
  }, [
    dispatch,
    storeDates.from,
    storeDates.to,
    fromDateInParams,
    toDateInParams,
    availablePubs,
    selectedPubs,
  ]);

  const download = (path: string, fileNamePrefix: string) => {
    return async () => {
      if (storeDates.from === null || storeDates.to === null) {
        return;
      }
      setIsDownloading(true);
      dispatch(toast('Downloading your report, this may take some time.'));
      try {
        await downloadCSV({
          path,
          fileNamePrefix,
          from: storeDates.from,
          to: storeDates.to,
          pubs: selectedPubs,
          reportStyle: 'ad-performance',
        });
        sendEvent({
          user: user,
          publishers: availablePubs,
          name: 'portal_report_download',
          start_date: storeDates.from,
          end_date: storeDates.to,
          current_page: 'reports_media',
          report_name: fileNamePrefix,
        });
      } catch (error) {
        dispatch(
          growl(
            'The data was too large to be downloaded directly. Please use the “Schedule Reports” button instead.'
          )
        );
        console.error('Media report download error.', error);
      }
      setIsDownloading(false);
      dispatch(toast(null));
    };
  };

  return (
    <>
      <div id="media">
        {/* header */}
        {/* header */}
        <div id="media-header">
          <div id="media-header__left">
            <div id="media-header__welcome">
              Ad Performance <div className="w-spacer" />
              <Icon
                name="help-circle"
                style={{ marginBottom: '-4px', cursor: 'pointer' }}
                onClick={() => setShowTooltip((prev) => !prev)}
              />
              {showTooltip && (
                <Tooltip
                  location="reportMedia"
                  onClick={() => {
                    setShowTooltip((prev) => !prev);
                  }}
                />
              )}
            </div>

            <div id="media-header__greeting-msg">
              Displaying the top 50 results. Download for full data.
            </div>
          </div>
          <div id="media-header__right">
            <PubPicker />
            <div className="w-spacer" />
            <DatePicker />
          </div>
        </div>
        {/* 1. By Recipe */}
        {/* 1. By Recipe */}
        {showImpressions && (
          <div id="media-section1">
            <DataTablePagination
              data={allRecipeData}
              title="Ad performance by Recipe"
              view="mediaRecipes"
              isDownloading={isDownloading}
              onDownload={download(
                '/reporting/ads/recipe',
                'Chicory_media_by_recipe'
              )}
              onSchedule={() => {
                setCategory('media');
                setGrouping('by_recipe');
                dispatch(creatingSchedule(true));
              }}
            />
          </div>
        )}
        {/* 2. By Ingredient */}
        {/* 2. By Ingredient */}
        <div id="media-section2">
          <DataTablePagination
            data={allIngredientData}
            title="Ad performance by Ingredient"
            view="mediaIngredients"
            isDownloading={isDownloading}
            onDownload={download(
              '/reporting/ads/ingredient',
              'Chicory_media_by_ingredient'
            )}
            onSchedule={() => {
              setCategory('media');
              setGrouping('by_ingredient');
              dispatch(creatingSchedule(true));
            }}
          />
        </div>
        {/* 3. By Domain */}
        {/* 3. By Domain */}
        {showImpressions && (
          <div id="media-section3">
            <DataTablePagination
              data={allPublisherData}
              title="Ad performance by Domain"
              view="mediaPublishers"
              isDownloading={isDownloading}
              onDownload={download(
                '/reporting/ads/publisher',
                'Chicory_media_by_publisher'
              )}
              onSchedule={() => {
                setCategory('media');
                setGrouping('by_domain');
                dispatch(creatingSchedule(true));
              }}
            />
          </div>
        )}
      </div>
      {/* Create schedule */}
      {/* Create schedule */}
      {currentlyCreatingSchedule && (
        <Modal
          onClose={() => {
            dispatch(creatingSchedule(false));
          }}
        >
          <EditReportForm
            createOrUpdateMode={'create'}
            category={category}
            grouping={grouping}
          />
        </Modal>
      )}
      {/* Confirm saved */}
      {/* Confirm saved */}
      {currentlyConfirmingSchedule && (
        <Modal
          onClose={() => {
            dispatch(confirmingSchedule(null));
          }}
        >
          <ConfirmSave currentSchedule={currentlyConfirmingSchedule} />
        </Modal>
      )}
    </>
  );
};

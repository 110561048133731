import { createAction, createReducer } from '@reduxjs/toolkit';

import { Publisher, PubsState } from '../types';

interface SetAvailablePubsInstruction {
  availablePubs: Publisher[];
}

interface SetSelectedPubsInstruction {
  selectedPubs: number[];
}

export const availablePubsAction = createAction<
  SetAvailablePubsInstruction,
  'SET_AVAILABLE_PUBS'
>('SET_AVAILABLE_PUBS');

export const selectedPubsAction = createAction<
  SetSelectedPubsInstruction,
  'SET_SELECTED_PUBS'
>('SET_SELECTED_PUBS');

const params = new URLSearchParams(window.location.search);
const pubIds =
  params
    .get('pubs')
    ?.split(',')
    .map((pub) => +pub) || [];

const initialPubs: PubsState = {
  availablePubs: null,
  selectedPubs: pubIds,
};

export const pubsReducer = createReducer<PubsState>(initialPubs, (builder) => {
  builder.addCase(selectedPubsAction, (state, action) => {
    const newlySelectedPubs = action.payload.selectedPubs;
    state.selectedPubs = newlySelectedPubs;
  });
  builder.addCase(availablePubsAction, (state, action) => {
    state.availablePubs = action.payload.availablePubs;
  });
});

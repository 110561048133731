import React from 'react';
import { Link, useRouteError } from 'react-router-dom';

import './ErrorPage.pcss';
import logo from '../assets/images/chicory-logo.png';

export default function ErrorBoundary() {
  const error = useRouteError() as Error;
  console.error(`Error boundary reached ${error}`);
  return (
    <>
      <div id="error-boundary">
        <div id="error-message">Oops, something went wrong!</div>
        <div id="error-fix-message">{`We're working to fix this`}</div>
        <Link to={{ pathname: '/', search: window.location.search }}>
          <div id="home-link">Go to home</div>
        </Link>
      </div>
      <div id="error-chicory-logo__wrapper">
        <img id="error-chicory-logo" src={logo} alt="chicory logo" />
      </div>
    </>
  );
}

import React, { useEffect, useState, useMemo } from 'react';

import { Card } from './Card';
import DataTable from './DataTable';
import { Tiles } from './Tiles';
import { TextBubble } from './TextBubble';
import { Searchbar } from './Searchbar';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import {
  approvePartners,
  fetchPendingPartners,
  formatPendingPartnerForRequestsDataTable,
  rejectPartners,
} from '../store/admin/actions';
import { TablePaginator } from './TablePaginator';
import { partnerSearchFilter } from '../utils';

const ROWS_PER_PAGE = 10;
const LIMIT_OF_RECORDS_CAN_SHOW = 250;

export function AdminPendingUsers() {
  const dispatch = useDispatch<AppDispatch>();

  const pendingPartnersFullSet = useSelector(
    (state: RootState) => state.admin.pendingPartnerRequests
  );

  const pendingPartners = useMemo(
    () => pendingPartnersFullSet.slice(0, LIMIT_OF_RECORDS_CAN_SHOW),
    [pendingPartnersFullSet]
  );

  const [filteredBySearchPartners, setFilteredBySearchPartners] = useState<
    typeof pendingPartnersFullSet | null
  >([]);
  const [currentlyViewingPartners, setCurrentlyViewingPartners] = useState<
    typeof pendingPartnersFullSet
  >([]);
  const [page, setPage] = useState(1);

  // Search functionality
  const [searchText, setSearchText] = useState('');

  const currentlyViewingPartnersForDataDisplay = useMemo(() => {
    return currentlyViewingPartners.map(
      formatPendingPartnerForRequestsDataTable
    );
  }, [currentlyViewingPartners]);

  useEffect(() => {
    dispatch(fetchPendingPartners());
  }, [dispatch]);

  // Handle page change or text entered for search
  useEffect(() => {
    const start = (page - 1) * ROWS_PER_PAGE;
    const end = start + ROWS_PER_PAGE;
    setSelectedPartners([]);
    if (searchText) {
      const results = pendingPartnersFullSet.filter((partner) => {
        return partnerSearchFilter(partner, searchText);
      });
      const view = results.slice(start, end);
      setFilteredBySearchPartners(results);
      setCurrentlyViewingPartners(view);
    } else {
      const view = pendingPartners.slice(start, end);
      setFilteredBySearchPartners(null);
      setCurrentlyViewingPartners(view);
    }
  }, [page, pendingPartners, pendingPartnersFullSet, searchText]);

  useEffect(() => {
    setPage(1);
  }, [searchText]);

  // Row selection functionality
  const [selectedPartners, setSelectedPartners] = useState<number[]>([]);
  const allRowsSelected = useMemo(() => {
    const result =
      // Don't want the 'all' checkbox filled when no rows to display
      currentlyViewingPartners.length > 0 &&
      // Perform an inexpensive length check first to hopefully short-circuit the full dual-array comparison
      currentlyViewingPartners.length === selectedPartners.length &&
      // Expensive n^2 array comparison
      currentlyViewingPartners.every((partner) =>
        selectedPartners.includes(partner.id)
      );
    return result;
  }, [currentlyViewingPartners, selectedPartners]);
  const toggleSelectRow = (id: number) => {
    if (selectedPartners.includes(id)) {
      // Deselect
      setSelectedPartners(
        selectedPartners.filter((partnerId) => partnerId !== id)
      );
    } else {
      // Select
      setSelectedPartners([...selectedPartners, id]);
    }
  };
  const toggleSelectAll = () => {
    if (allRowsSelected) {
      setSelectedPartners([]);
    } else {
      setSelectedPartners(currentlyViewingPartners.map((user) => user.id));
    }
  };

  return (
    <>
      <Card>
        <Card.Header>
          <Card.HeaderLeft>
            <Card.TitleSmall>{'New requests'}</Card.TitleSmall>
            <TextBubble color="blue">{`${pendingPartnersFullSet.length} sign-ups`}</TextBubble>
          </Card.HeaderLeft>
          <Card.HeaderRight>
            {/* Search bar */}
            {/* Search bar */}
            <Searchbar setSearchText={setSearchText} searchText={searchText} />
          </Card.HeaderRight>
        </Card.Header>
        <Card.TableBody>
          <DataTable
            view={'pendingUsers'}
            data={currentlyViewingPartnersForDataDisplay}
            toggleSelectRow={toggleSelectRow}
            toggleSelectAll={toggleSelectAll}
            selectedRowIds={selectedPartners}
            allRowsSelected={allRowsSelected}
            className="table__pending-users"
          />
        </Card.TableBody>
        {/* Bottom Action Bar */}
        {/* Bottom Action Bar */}
        <Card.Header>
          <Card.HeaderLeft>
            <Tiles style={{ border: 'none' }}>
              {/* Approve Button */}
              {/* Approve Button */}
              <Tiles.Button
                version={'green'}
                style={{ width: '120px' }}
                onClick={() => {
                  dispatch(approvePartners(selectedPartners));
                }}
              >
                <div>{`Approve ${selectedPartners.length}`}</div>
              </Tiles.Button>
              <div className="w-spacer" />
              <div className="w-spacer" />
              {/* Reject Button */}
              {/* Reject Button */}
              <Tiles.Button
                version={'red'}
                style={{ width: '120px' }}
                onClick={() => {
                  dispatch(rejectPartners(selectedPartners));
                }}
              >
                <div>{`Reject ${selectedPartners.length}`}</div>
              </Tiles.Button>
            </Tiles>
          </Card.HeaderLeft>
          <Card.HeaderRight>
            <TablePaginator
              amountOfEntries={
                filteredBySearchPartners
                  ? filteredBySearchPartners.length
                  : pendingPartners.length
              }
              currentPage={page}
              amountPerPage={ROWS_PER_PAGE}
              setPage={setPage}
            />
          </Card.HeaderRight>
        </Card.Header>
      </Card>
    </>
  );
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-layout__sidebar-spacer {
  margin-left: 280px;
  padding: 25px;
  flex-grow: 1;
  background-color: #f5f7fa;
  height: 100vh;
  overflow-y: auto;
}

@media (max-width: 990px) {
  .main-layout__sidebar-spacer {
    margin-left: 200px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/MainLayout.pcss"],"names":[],"mappings":"AAGA;EACE,kBAA0B;EAC1B,aAAa;EACb,YAAY;EACZ,yBAAyB;EACzB,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":["$midDesktop: 990px;\n$sidebarWidth: 280px;\n\n.main-layout__sidebar-spacer {\n  margin-left: $sidebarWidth;\n  padding: 25px;\n  flex-grow: 1;\n  background-color: #f5f7fa;\n  height: 100vh;\n  overflow-y: auto;\n}\n\n@media (max-width: $midDesktop) {\n  .main-layout__sidebar-spacer {\n    margin-left: 200px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

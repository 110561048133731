export const REFERENCE_OPTIONS: Array<{ choiceValue: string; extraPrompt?: string }> =
  [
    { choiceValue: 'Facebook' },
    { choiceValue: 'Twitter' },
    { choiceValue: 'Instagram' },
    { choiceValue: 'Pinterest' },
    { choiceValue: 'Email from Chicory' },
    { choiceValue: 'Word of Mouth' },
    { choiceValue: 'Online Advertisement' },
    { choiceValue: 'WordPress Plugin Search' },
    { choiceValue: 'WP Ultimate Recipe' },
    { choiceValue: 'Eat.Blog.Talk' },
    {
      choiceValue: 'Referred by another blogger',
      extraPrompt: `Blogger's name`,
    },
    { choiceValue: 'Other', extraPrompt: `Tell us more` },
    { choiceValue: 'Blogger conference', extraPrompt: 'Which conference?' },
    { choiceValue: 'Email newsletter', extraPrompt: 'Which newsletter?' },
    { choiceValue: 'Plugin', extraPrompt: 'Plugin name' },
  ];

export const sourcesToPrompt = REFERENCE_OPTIONS.reduce<{ [k: string]: string }>(
  (map, option) => {
    if (option.extraPrompt) {
      map[option.choiceValue] = option.extraPrompt;
    }
    return map;
  },
  {}
);
import { createReducer, createAsyncThunk } from '@reduxjs/toolkit';

import { RaOverview } from '../types';
import { RootState } from '.';
import { chicoryFetcher, fetchUrlFormatter, getValidPubs } from '../utils';

const initialRaOverview: RaOverview = {
  addToCartMarketRate: 0,
  addsToCart: 0,
  chooseRetailerMarketRate: 0,
  clickThroughMarketRate: 0,
  clicks: 0,
  retailerChoices: 0,
  views: 0,
};

export const fetchRaOverview = createAsyncThunk<
  RaOverview,
  void,
  { state: RootState }
>('FETCH_RA_OVERVIEW', async (_, { getState }) => {
  const state = getState();

  const pubs = getValidPubs({
    selectedPubs: state.pubs.selectedPubs,
    availablePubs: state.pubs.availablePubs,
  });
  const path = '/reporting/ra/overview';

  try {
    if (state.dateRange.from === null || state.dateRange.to === null) {
      throw new Error('Date range in store is unexpectedly null.');
    }
    const data = await chicoryFetcher(
      fetchUrlFormatter({
        path: path,
        from: state.dateRange.from,
        to: state.dateRange.to,
        pubs: pubs,
      })
    ).then((response) => response.json());
    return data;
  } catch (error) {
    console.error('fetchRAOverview error.', error);
  }
});

export const RAOverviewReducer = createReducer<RaOverview>(
  initialRaOverview,
  (builder) => {
    builder.addCase(fetchRaOverview.fulfilled, (state, action) => {
      return action.payload;
    });
  }
);

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import './AdminUsersNav.pcss';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Tiles } from './Tiles';
import { Icon } from './icons';
import Modal from './Modal';
import AdminNewUserForm from './AdminAddNewUserForm';
import store from '../store';
import { toast } from '../store/toast';
import { growl } from '../store/growl';
import { downloadCSV } from '../utils';

export const AdminUsersNav = () => {
  const dispatch = useDispatch<typeof store.dispatch>();
  // Does not accomodate query params in url
  const location = useLocation().pathname.split('/').pop() || '';

  const [displayNewUserForm, setDisplayNewUserForm] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const download = (path: string, status: string) => {
    return async () => {
      setIsDownloading(true);
      dispatch(toast('Downloading users, this may take some time.'));
      try {
        await downloadCSV({
          path: path,
          status: status,
          limit: 100000,
          creationMethod: 'signup',
          fileNamePrefix: `${location}Users`,
        });
      } catch (error) {
        dispatch(
          growl(
            'There was an error, please try again. If the problem persists, please let engineering know.'
          )
        );
        console.error(`Location: ${location} export error.`, error);
      }
      setIsDownloading(false);
      dispatch(toast(null));
    };
  };

  return (
    <div id="admin-users">
      <div id="admin-users-nav">
        <div id="admin-users-nav__left">
          <Tiles>
            <Tiles.Tile selected={location === 'approved'}>
              <Link to={{ pathname: '/admin/users/approved' }}>
                Current Users
              </Link>
            </Tiles.Tile>
            <Tiles.Tile selected={location === 'rejected'}>
              <Link to={{ pathname: '/admin/users/rejected' }}>
                Rejected Users
              </Link>
            </Tiles.Tile>
            <Tiles.Tile selected={location === 'pending'}>
              <Link to={{ pathname: '/admin/users/pending' }}>
                User requests
              </Link>
            </Tiles.Tile>
          </Tiles>
        </div>
        <div id="admin-users-nav__right">
          <Tiles style={{ border: 'none' }}>
            {location !== 'users' && (
              <Tiles.Button
                version={'white'}
                onClick={
                  isDownloading
                    ? undefined
                    : download('/partners', `${location}`)
                }
              >
                <Icon name="downloadCloud" />
                <div className="w-spacer" />
                <div>{'Export Data'}</div>
                <div className="w-spacer" />
              </Tiles.Button>
            )}
            <div className="w-spacer" />
            <div className="w-spacer" />
            <Tiles.Button
              version={'darkPurple'}
              onClick={() => setDisplayNewUserForm(true)}
            >
              <Icon name="plus" />
              <div className="w-spacer" />
              <div>{'Add a user'}</div>
              <div className="w-spacer" />
            </Tiles.Button>
          </Tiles>
        </div>
      </div>
      <Outlet />
      {displayNewUserForm && (
        <Modal onClose={() => setDisplayNewUserForm(false)}>
          <AdminNewUserForm onClose={() => setDisplayNewUserForm(false)} />
        </Modal>
      )}
    </div>
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { chicoryFetcher } from '../../utils';
import { DataTableData } from '../../types';
import { growl } from '../growl';

export type PartnerRawFromAPI = {
  id: number;
  accountId: number;
  createdDate: string;
  email: string;
  paypalEmail?: string;
  excludeFromMarketingEmails: boolean;
  firstName: string;
  lastName: string;
  isPremium: boolean;
  monthlyImpressions: number;
  publishers: { hostname: string; id: number; name: string }[];
  source: string;
  sourceComments: string;
  status: 'pending' | 'approved' | 'rejected';
};

export type RejectedPartnerRawFromAPI = PartnerRawFromAPI & {
  status: 'rejected';
};
export type PendingPartnerRawFromAPI = PartnerRawFromAPI & {
  status: 'pending';
};

type Unpacked<T> = T extends (infer U)[] ? U : T;
type ApiDataEntry = Unpacked<DataTableData>;

export const formatPartnerUserForDataTable: (
  p: PartnerRawFromAPI
) => ApiDataEntry = (partner) => {
  return {
    id: partner.id,
    name: `${partner.firstName} ${partner.lastName}`,
    email: partner.email,
    paypalEmail: partner.paypalEmail ? partner.paypalEmail : '',
    accountName: partner.accountId ? partner.accountId?.toString() : '',
    domains: partner.publishers?.map((pub) => pub.hostname),
    partnerTier: partner.isPremium ? 'Premium Publisher' : 'Individual Creator',
    monthlyImpressions: partner.monthlyImpressions,
    createdDate: partner.createdDate,
    source: partner.source,
  };
};

export const formatPendingPartnerForRequestsDataTable: (
  p: PendingPartnerRawFromAPI
) => ApiDataEntry = (partner) => {
  return {
    id: partner.id,
    name: `${partner.firstName} ${partner.lastName}`,
    email: partner.email,
    paypalEmail: partner.paypalEmail ? partner.paypalEmail : '',
    accountId: partner.accountId ? partner.accountId.toString() : '',
    accountName: 'NOT FOUND ON API',
    domains: partner.publishers?.map((pub) => pub.hostname),
    date: partner.createdDate,
    source: partner.source,
    impressions: partner.monthlyImpressions,
  };
};

export const fetchCurrentPartners = createAsyncThunk<PartnerRawFromAPI[]>(
  'FETCH_CURRENT_PARTNERS',
  async () => {
    const partnerResponse = await chicoryFetcher(
      '/partners?status=approved&creationMethod=signup&limit=10000'
    );
    const partners = await partnerResponse.json();
    return partners.results;
  }
);

export const fetchPendingPartners = createAsyncThunk<
  PendingPartnerRawFromAPI[]
>('FETCH_PENDING_PARTNERS', async () => {
  const partnerResponse = await chicoryFetcher(
    '/partners?status=pending&creationMethod=signup&limit=100000'
  );
  const partners = await partnerResponse.json();
  return partners.results;
});

export const fetchRejectedPartners = createAsyncThunk<
  RejectedPartnerRawFromAPI[]
>('FETCH_REJECTED_PARTNERS', async () => {
  const partnerResponse = await chicoryFetcher(
    '/partners?status=rejected&creationMethod=signup&limit=10000'
  );
  const partners = await partnerResponse.json();
  return partners.results;
});

export const approvePartners = createAsyncThunk<
  undefined, // Return type
  number[] // Input type
>(
  'APPROVE_PARTNERS',
  async (
    partnerIds, // Input
    { dispatch }
  ) => {
    // Patch request to approve partners
    const body = partnerIds.map((id) => ({
      identifier: { id: id },
      fields: { status: 'approved' },
    }));

    const res = await chicoryFetcher('/partners', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    if (!res.ok) {
      dispatch(growl('Server error, please refresh and try again.'));
    } else {
      dispatch(fetchPendingPartners());
    }

    return undefined;
  }
);

export const rejectPartners = createAsyncThunk<
  undefined, // Return type
  number[] // Input type
>('REJECT_PARTNERS', async (partnerIds, { dispatch }) => {
  const body = partnerIds.map((id) => ({
    identifier: { id: id },
    fields: { status: 'rejected' },
  }));
  const res = await chicoryFetcher('/partners', {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  if (!res.ok) {
    dispatch(growl('Server error, please refresh and try again.'));
  } else {
    dispatch(fetchPendingPartners());
  }

  return undefined;
});

import { Action, createReducer } from '@reduxjs/toolkit';

import {
  type PartnerRawFromAPI,
  type RejectedPartnerRawFromAPI,
  type PendingPartnerRawFromAPI,
  fetchCurrentPartners,
  fetchPendingPartners,
  fetchRejectedPartners,
} from './actions';

type AdminState = {
  partners: PartnerRawFromAPI[];
  currentPartnersCursorNext: string | null;
  rejectedPartners: RejectedPartnerRawFromAPI[];
  pendingPartnerRequests: PendingPartnerRawFromAPI[];
};

const adminPartnersDataReducer = createReducer<PartnerRawFromAPI[]>(
  [],
  (builder) => {
    builder.addCase(fetchCurrentPartners.fulfilled, (_, action) => {
      return action.payload;
    });
  }
);

const rejectedPartnersDataReducer = createReducer<RejectedPartnerRawFromAPI[]>(
  [],
  (builder) => {
    builder.addCase(fetchRejectedPartners.fulfilled, (_, action) => {
      return action.payload;
    });
  }
);

const pendingRequestsReducer = createReducer<PendingPartnerRawFromAPI[]>(
  [],
  (builder) => {
    builder.addCase(fetchPendingPartners.fulfilled, (_, action) => {
      return action.payload;
    });
  }
);

const adminReducer: (
  state: AdminState | undefined,
  action: Action
) => AdminState = (
  state = {
    currentPartnersCursorNext: null,
    partners: [],
    rejectedPartners: [],
    pendingPartnerRequests: [],
  },
  action
) => {
  return {
    currentPartnersCursorNext: null,
    partners: adminPartnersDataReducer(state.partners, action),
    rejectedPartners: rejectedPartnersDataReducer(
      state.rejectedPartners,
      action
    ),
    pendingPartnerRequests: pendingRequestsReducer(
      state.pendingPartnerRequests,
      action
    ),
  };
};

export default adminReducer;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../store';
import dayjs from 'dayjs';

import './EditReportForm.pcss';
import {
  createScheduledReport,
  updateScheduledReport,
} from '../store/scheduleData';
import { creatingSchedule, editingSchedule } from '../store/scheduleModals';
import { Icon } from './icons';
import { Tiles } from './Tiles';
import { Form } from './Form';
import { Checkbox } from './PubPicker';
import { EmailMethod, Report, S3Method } from '../types';

interface IProps {
  createOrUpdateMode: 'create' | 'update';
  currentReport?: Report;
  category?: string;
  grouping?: string;
}

export const EditReportForm = ({
  currentReport,
  createOrUpdateMode,
  category = '',
  grouping = '',
}: IProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { availablePubs, selectedPubs } = useSelector(
    (state: RootState) => state.pubs
  );

  const isEmailSchedule = currentReport?.method?.type === 'email';

  // Default to showing email fields if creating a new report
  const [emailOrS3, setEmailOrS3] = useState<'email' | 's3'>(
    createOrUpdateMode === 'create' ? 'email' : isEmailSchedule ? 'email' : 's3'
  );

  // Discriminate the report delivery method for typescript
  let s3Method: S3Method | null = null;
  let emailMethod: EmailMethod | null = null;
  if (createOrUpdateMode === 'update') {
    if (currentReport?.method.type === 's3') {
      s3Method = currentReport?.method;
    } else if (currentReport?.method.type === 'email') {
      emailMethod = currentReport?.method;
    }
  }

  // email fields
  const [addresses, setAddresses] = useState(
    createOrUpdateMode === 'create'
      ? ''
      : emailMethod?.addresses.join(', ') || ''
  );

  // s3 fields
  const [accessKey, setAccessKey] = useState<string>(
    createOrUpdateMode === 'create' ? '' : s3Method?.accessKeyId || ''
  );
  const [secretAccessKey, setSecretAccessKey] = useState<string | undefined>(
    undefined
  );
  const [bucket, setBucket] = useState(
    createOrUpdateMode === 'create' ? '' : s3Method?.bucket || ''
  );
  const [prefix, setPrefix] = useState(
    createOrUpdateMode === 'create' ? '' : s3Method?.prefix || ''
  );

  // shared fields
  const [reportName, setReportName] = useState(
    createOrUpdateMode === 'create' ? '' : currentReport?.name || ''
  );
  const [frequency, setFrequency] = useState(
    createOrUpdateMode === 'create' ? 'daily' : currentReport?.frequency || ''
  );
  const [dayOfWeek, setDayOfWeek] = useState(1);
  const [selectedCategory] = useState<string>(
    createOrUpdateMode === 'update' && currentReport
      ? currentReport.category
      : category
  );
  const [selectedGrouping] = useState<string>(
    createOrUpdateMode === 'update' && currentReport
      ? currentReport.grouping
      : grouping
  );
  // Component level state to manage which pubs to schedule reports for.
  // Distinct from the selectedPubs or availablePubs used elsewhere in app.
  const [publishers, setPublishers] = useState(() => {
    if (createOrUpdateMode === 'create') {
      if (availablePubs === null) {
        return [];
      }
      if (selectedPubs.length === 0) {
        return availablePubs;
      }
      const matchedPubs: { id: number; name: string }[] = [];
      selectedPubs.forEach((pubId) => {
        const pub = availablePubs.find(({ id }) => id === pubId);
        if (pub) matchedPubs.push({ id: pub.id, name: pub.name });
      });
      return matchedPubs;
    } else {
      return currentReport?.publishers || [];
    }
  });
  const [breakOutByDates, setBreakOutByDates] = useState(
    createOrUpdateMode === 'create'
      ? false
      : currentReport?.breakOutByDates || false
  );
  const [active] = useState(
    createOrUpdateMode === 'create' ? true : currentReport?.active || true
  );

  // Misc modals and errors
  const [noBucketAccess, setNoBucketAccess] = useState(false);
  const [showPubDropdown, setShowPubDropdown] = useState(false);
  const [showDayDropdown, setShowDayDropdown] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const clickHandler = async () => {
    const fieldsValidated = () => {
      const errorsArray: string[] = [];

      if (publishers?.length === 0) {
        errorsArray.push('No domains selected');
      }
      if (reportName === '') {
        errorsArray.push('Report name is missing');
      }
      if (emailOrS3 === 's3') {
        if (
          accessKey === '' ||
          accessKey.length > 512 ||
          accessKey.length < 16
        ) {
          errorsArray.push('Access Key is not an appropriate length');
        }
        if (
          secretAccessKey === undefined ||
          secretAccessKey === '' ||
          secretAccessKey.length > 512
        ) {
          errorsArray.push('Secret Access Key is missing or too long');
        }
      }

      if (emailOrS3 === 'email' && addresses.length === 0) {
        errorsArray.push('Email addresses are missing');
      }

      return errorsArray;
    };

    setErrors([]); // Clear previous errors
    const localErrors = fieldsValidated(); // Function level errors
    setErrors(localErrors); // Component level errors

    if (localErrors.length > 0) {
      return;
    }

    const method =
      emailOrS3 === 'email'
        ? {
            type: 'email' as const,
            addresses: addresses
              .split(',')
              .map((email) => email.trim())
              .filter((email) => email !== ''),
          }
        : {
            type: 's3' as const,
            accessKeyId: accessKey,
            secretAccessKey,
            bucket,
            prefix,
          };

    const report: Omit<Report, 'id'> = {
      name: reportName,
      frequency,
      dayOfWeek,
      category: selectedCategory,
      grouping: selectedGrouping,
      publishers,
      breakOutByDates,
      active,
      method,
    };

    if (createOrUpdateMode === 'create') {
      dispatch(
        createScheduledReport({ ...report, showSuccessModal: true })
      ).then(null, (rejected) => {
        // 2nd func parameter in a .then() is the catch method
        if (rejected.message === '422') {
          setNoBucketAccess(true);
        }
      });
    } else if (createOrUpdateMode === 'update' && currentReport) {
      dispatch(
        updateScheduledReport({
          ...report,
          id: currentReport.id,
          showSuccessModal: true,
        })
      ).then(null, (rejected) => {
        if (rejected.message === '422') {
          setNoBucketAccess(true);
        }
      });
    }
  };

  // Reset to Monday when user chooses daily or monthly
  useEffect(() => {
    if (frequency !== 'weekly') {
      setDayOfWeek(1);
    }
  }, [frequency]);

  return (
    <div
      id="edit-report-form"
      onClick={() => {
        setShowPubDropdown(false);
        setShowDayDropdown(false);
      }}
    >
      <Form>
        {/* Header */}
        {/* Header */}
        {/* Header */}
        <Form.Header style={{ paddingBottom: '0px' }}>
          <Form.HeaderLeft>
            <Tiles>
              <Tiles.Button version="white" className="nohover">
                <Icon name="upload" />
              </Tiles.Button>
            </Tiles>
          </Form.HeaderLeft>
          <Form.HeaderRight>
            <Icon
              name="x"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                createOrUpdateMode === 'create'
                  ? dispatch(creatingSchedule(false))
                  : dispatch(editingSchedule(null));
              }}
            />
          </Form.HeaderRight>
        </Form.Header>
        <Form.Header
          style={{
            paddingTop: '15px',
            paddingBottom: '12px',
          }}
        >
          <Form.HeaderLeft
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Form.TitleLarge>
              {createOrUpdateMode === 'create'
                ? 'Schedule Reports'
                : 'Update Reports'}
            </Form.TitleLarge>
            <Form.TitleSmall>
              {`All reports will be delivered at 10AM EST. ${
                frequency === 'monthly'
                  ? 'Delivered on the first of every month.'
                  : ''
              }`}
            </Form.TitleSmall>
          </Form.HeaderLeft>
        </Form.Header>
        {/* Tabs */}
        {/* Tabs */}
        <Form.Header
          style={{
            paddingBottom: '0',
          }}
        >
          {createOrUpdateMode === 'create' && (
            <div className="schedule__tabs-container">
              <div
                className={`schedule__tab ${
                  emailOrS3 === 'email' ? 'schedule__tab--active' : ''
                }`}
                onClick={() => {
                  setEmailOrS3('email');
                  setErrors([]);
                }}
              >
                Email
              </div>
              <div
                className={`schedule__tab ${
                  emailOrS3 === 's3' ? 'schedule__tab--active' : ''
                }`}
                onClick={() => {
                  setEmailOrS3('s3');
                  setErrors([]);
                }}
              >
                S3
              </div>
            </div>
          )}
        </Form.Header>
        {/* Body */}
        {/* Body */}
        {/* Body */}
        <Form.Body
          style={{
            borderTop: '1px solid #D0D5DD',
            borderBottom: '1px solid #D0D5DD',
            maxHeight: '481px',
            overflowY: 'auto',
          }}
        >
          <Form.Label>Report Name</Form.Label>
          <Form.InputContainer>
            <input
              className="form__input"
              value={reportName}
              onChange={(e) => {
                setReportName(e.target.value);
              }}
            />
          </Form.InputContainer>
          {/* S3 Fields */}
          {/* S3 Fields */}
          {/* S3 Fields */}
          {emailOrS3 === 's3' && (
            <>
              <Form.Label>Access Key</Form.Label>
              <Form.InputContainer>
                <input
                  value={accessKey}
                  onChange={(e) => {
                    setAccessKey(e.target.value);
                  }}
                />
              </Form.InputContainer>

              <Form.Label>Secret Access Key</Form.Label>
              <Form.InputContainer>
                <input
                  type={'password'}
                  value={
                    secretAccessKey === undefined
                      ? '1234567778'
                      : secretAccessKey
                  }
                  onChange={(e) => {
                    setSecretAccessKey(e.target.value);
                  }}
                  onBlur={() => {
                    if (secretAccessKey === '') {
                      setSecretAccessKey(undefined);
                    }
                  }}
                  onFocus={() => {
                    setSecretAccessKey('');
                  }}
                />
              </Form.InputContainer>

              <Form.Label>{`Bucket Name`}</Form.Label>
              <Form.InputContainer>
                <input
                  value={bucket}
                  onChange={(e) => {
                    setBucket(e.target.value);
                  }}
                />
              </Form.InputContainer>
              <Form.Label
                style={{
                  fontSize: '14px',
                  paddingBottom: '15px',
                }}
              >
                <span>{'Example: chicory-portal'}</span>
              </Form.Label>
              <Form.Label>{`Prefix`}</Form.Label>
              <Form.InputContainer>
                <input
                  value={prefix}
                  onChange={(e) => {
                    setPrefix(e.target.value);
                  }}
                />
              </Form.InputContainer>
              <Form.Label
                style={{
                  fontSize: '14px',
                  paddingBottom: '15px',
                }}
              >
                <span>{'Example: reports- or reports/'}</span>
              </Form.Label>
            </>
          )}
          {/* Email Fields */}
          {/* Email Fields */}
          {/* Email Fields */}
          {emailOrS3 === 'email' && (
            <>
              {/* Email addresses */}
              {/* Email addresses */}
              {/* Email addresses */}
              <Form.Label>Addresses</Form.Label>
              <Form.InputContainer>
                <input
                  value={addresses}
                  onChange={(e) => {
                    setAddresses(e.target.value);
                  }}
                />
              </Form.InputContainer>
              {/* Frequency */}
              {/* Frequency */}
              {/* Frequency */}
              <Form.Label>Frequency</Form.Label>
              <div
                id="form__frequency-container"
                className="form__radio-container"
                style={{ marginBottom: '15px', marginTop: '3px' }}
              >
                <div className="form__radio-group">
                  <input
                    id="daily"
                    className="form__styled-radio"
                    type="radio"
                    value="daily"
                    checked={frequency === 'daily'}
                    onChange={(e) => {
                      setFrequency(e.target.value);
                    }}
                  />
                  <div className="w-spacer"></div>
                  <label htmlFor="daily">Daily</label>
                </div>
                <div className="form__radio-group">
                  <input
                    id="weekly"
                    className="form__styled-radio"
                    type="radio"
                    value="weekly"
                    checked={frequency === 'weekly'}
                    onChange={(e) => {
                      setFrequency(e.target.value);
                    }}
                  />
                  <div className="w-spacer"></div>
                  <label htmlFor="weekly">Weekly</label>
                </div>
                <div className="form__radio-group">
                  <input
                    id="monthly"
                    className="form__styled-radio"
                    type="radio"
                    value="monthly"
                    checked={frequency === 'monthly'}
                    onChange={(e) => {
                      setFrequency(e.target.value);
                    }}
                  />
                  <div className="w-spacer"></div>
                  <label htmlFor="monthly">Monthly</label>
                </div>
              </div>

              {/* Day of Week Dropdown */}
              {/* Day of Week Dropdown */}
              {/* Day of Week Dropdown */}
              {frequency === 'weekly' && (
                <>
                  <Form.Label style={{ marginTop: '3px' }}>
                    Day of Week
                  </Form.Label>
                  <Form.InputContainer
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowDayDropdown((prev) => !prev);
                    }}
                    style={{
                      position: 'relative',
                      marginBottom: '15px',
                      width: '25%',
                    }}
                  >
                    <div style={{ height: '40px' }} className="text-truncator">
                      {
                        ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][
                          dayOfWeek
                        ]
                      }
                    </div>
                    {showDayDropdown ? (
                      <Icon name="chevron-up" />
                    ) : (
                      <Icon name="chevron-down" />
                    )}
                    {showDayDropdown && (
                      <div className="form__dropdown">
                        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(
                          (day, index) => (
                            <Checkbox
                              key={index}
                              name={`day-${index}`}
                              checked={dayOfWeek === index}
                              onClick={(checked) => {
                                if (checked) {
                                  setDayOfWeek(index);
                                }
                              }}
                              label={day}
                              showCheckbox={false}
                            />
                          )
                        )}
                      </div>
                    )}
                  </Form.InputContainer>
                </>
              )}

              <Form.Label
                style={{
                  fontSize: '14px',
                  paddingBottom: '15px',
                }}
              >
                <span>
                  {`Your report will contain the past 1 ${
                    frequency === 'daily'
                      ? 'day'
                      : frequency === 'monthly'
                        ? 'month'
                        : frequency === 'weekly'
                          ? 'week'
                          : frequency
                  }'s data.${
                    frequency === 'monthly'
                      ? ' Delivered on the first day of every month.'
                      : ''
                  }`}
                </span>
              </Form.Label>

              <div
                className="form__breakout-checkbox-container"
                style={{ marginBottom: '15px' }}
              >
                <input
                  id="breakOutByDates"
                  className="form__styled-checkbox"
                  type="checkbox"
                  checked={breakOutByDates}
                  onChange={() => {
                    setBreakOutByDates((prev) => !prev);
                  }}
                />
                <label htmlFor="breakOutByDates">Break out by dates</label>
              </div>
              <div className="form__checkbox-description">
                Each row will contain the date. E.g. 1/1/23
              </div>
            </>
          )}
          {/* Pubs Dropdown */}
          {/* Pubs Dropdown */}
          {/* Pubs Dropdown */}
          <Form.Label>Site domains</Form.Label>
          <Form.InputContainer
            onClick={(e) => {
              e.stopPropagation();
              setShowPubDropdown((prev) => !prev);
            }}
            style={{ position: 'relative' }}
          >
            <div style={{ height: '40px' }} className="text-truncator">
              {publishers?.map((pub) => pub.name).join(', ')}
            </div>
            {showPubDropdown ? (
              <Icon name="chevron-up" />
            ) : (
              <Icon name="chevron-down" />
            )}
            {showPubDropdown && (
              <div className="form__dropdown">
                {availablePubs?.map((availablePub) => (
                  <Checkbox
                    key={availablePub.id}
                    name={`pub-${availablePub.id}`}
                    checked={publishers
                      ?.map((pub) => pub.id)
                      .includes(availablePub.id)}
                    onClick={(checked) => {
                      if (checked) {
                        setPublishers((prev) => [...prev, availablePub]);
                      } else {
                        setPublishers((prev) =>
                          prev.filter((pub) => pub.id !== availablePub.id)
                        );
                      }
                    }}
                    label={availablePub.name}
                  />
                ))}
              </div>
            )}
          </Form.InputContainer>
          {/* End Dropdown */}
          {/* End Dropdown */}
          {/* End Dropdown */}
          {emailOrS3 === 's3' && (
            <>
              <Form.Label>
                <div className="text-truncator">Output filename</div>
              </Form.Label>
              <Form.Label>
                {' '}
                <div className="text-truncator">
                  {`Chicory_${category}_${grouping}s_${dayjs().format(
                    'YYYY-MM-DD'
                  )}-14-51-00.csv`}
                </div>
              </Form.Label>
              <Form.Label style={{ fontSize: '14px', marginTop: '10px' }}>
                <Icon name="alert-circle" />
                <div className="w-spacer" />
                <span>{`Note: Your report will contain the past 1 day's data.`}</span>
              </Form.Label>
            </>
          )}
        </Form.Body>
        {/* Warnings */}
        {/* Warnings */}
        {/* Warnings */}
        {noBucketAccess && (
          <span
            style={{ padding: '15px 20px 0px 20px' }}
            className="warning"
          >{`We don't have access to this bucket.`}</span>
        )}
        {errors.length > 0 && (
          <div style={{ padding: '15px 20px 0px 20px' }}>
            {errors.map((error, index) => (
              <div key={index} className="warning">
                {error}
              </div>
            ))}
          </div>
        )}
        {/* Buttons */}
        {/* Buttons */}
        {/* Buttons */}
        <Form.Header>
          <Tiles
            style={{
              width: '100%',
              height: '45px',
              border: 'none',
              justifyContent: 'space-between',
            }}
          >
            <Tiles.Button
              version="white"
              style={{
                width: '48%',
                fontSize: '16px',
              }}
              onClick={() => {
                createOrUpdateMode === 'create'
                  ? dispatch(creatingSchedule(false))
                  : dispatch(editingSchedule(null));
              }}
            >
              Cancel
            </Tiles.Button>
            <Tiles.Button
              version="darkPurple"
              style={{
                width: '48%',
                fontSize: '16px',
              }}
              onClick={clickHandler}
            >
              {createOrUpdateMode === 'update' ? 'Update' : 'Create'}
            </Tiles.Button>
          </Tiles>
        </Form.Header>
      </Form>
    </div>
  );
};

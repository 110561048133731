import React from 'react';
import { useDispatch } from 'react-redux';

import { AppDispatch } from '../store';
import { Report } from '../types';

import './ConfirmSave.pcss';
import { Card } from '../components/Card';

import { Icon } from './icons';
import { Tiles } from './Tiles';
import { confirmingSchedule } from '../store/scheduleModals';

export const ConfirmSave = ({
  currentSchedule,
}: {
  currentSchedule: Report & { updatingSchedule?: boolean };
}) => {
  const dispatch = useDispatch<AppDispatch>();
  return (
    <Card id="success-confirm">
      <Card.Header>
        <Card.HeaderLeft>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50%',
              boxShadow: '0 0 10px lightgreen',
            }}
          >
            <Icon
              name="check-circle"
              style={{ height: '25px', width: '25px' }}
            />
          </div>
        </Card.HeaderLeft>
        <Card.HeaderRight>
          <Icon
            name="x"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              dispatch(confirmingSchedule(null));
            }}
          />
        </Card.HeaderRight>
      </Card.Header>
      <Card.Body
        style={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          height: '80px',
          justifyContent: 'space-evenly',
        }}
      >
        <Card.TitleSmall>Success!</Card.TitleSmall>
        <Card.TitleSmall style={{ fontWeight: '100', fontSize: '16px' }}>
          {currentSchedule?.updatingSchedule === true
            ? 'Your report has been updated.'
            : currentSchedule.method.type === 'email'
              ? 'Your report has been scheduled.'
              : 'We have access to this bucket.'}
        </Card.TitleSmall>
      </Card.Body>
      <Card.Header>
        <Tiles
          style={{
            width: '100%',
            height: '45px',
            border: 'none',
            justifyContent: 'space-between',
          }}
        >
          <Tiles.Button
            version="darkPurple"
            style={{
              width: '100%',
              fontSize: '16px',
            }}
            onClick={() => {
              dispatch(confirmingSchedule(null));
            }}
          >
            Ok
          </Tiles.Button>
        </Tiles>
      </Card.Header>
    </Card>
  );
};

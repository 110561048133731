// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchbar {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 2rem;
  padding-left: 0.5rem;
}

.search-input {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  padding-left: 0.5rem
}

.search-input::placeholder {
    font-family: 'Inter', sans-serif;
    color: #a0a4a8;
  }
`, "",{"version":3,"sources":["webpack://./src/components/Searchbar.pcss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,WAAW;EACX,YAAY;EACZ,eAAe;EACf;AAMF;;AAJE;IACE,gCAAgC;IAChC,cAAc;EAChB","sourcesContent":[".searchbar {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  height: 2rem;\n  padding-left: 0.5rem;\n}\n\n.search-input {\n  border: none;\n  outline: none;\n  width: 100%;\n  height: 100%;\n  font-size: 1rem;\n  padding-left: 0.5rem;\n\n  &::placeholder {\n    font-family: 'Inter', sans-serif;\n    color: #a0a4a8;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import Collapsible from 'react-collapsible';
import { faqsContent } from './FaqContent';
import './Faq.pcss';

interface TriggerProps {
  isOpen: boolean;
  label: string;
}

const Trigger: React.FC<TriggerProps> = ({ isOpen, label }) => (
  <div className="trigger">
    <span
      className="trigger-icon"
      style={{
        transform: isOpen ? 'rotate(45deg)' : 'rotate(-135deg)',
      }}
    />
    <span>{label}</span>
  </div>
);

const renderColumn = (faqs: typeof faqsContent) => (
  <div>
    {faqs.map((faq, index) => (
      <div className="collapsible-wrapper" key={`faq-${index}`}>
        <Collapsible
          trigger={<Trigger label={faq.label} isOpen={false} />}
          triggerWhenOpen={<Trigger label={faq.label} isOpen={true} />}
        >
          {faq.content}
        </Collapsible>
      </div>
    ))}
  </div>
);

export const Faq: React.FC = () => {
  const halfLength = Math.ceil(faqsContent.length / 2);
  const firstColumnFaqs = faqsContent.slice(0, halfLength);
  const secondColumnFaqs = faqsContent.slice(halfLength);

  return (
    <div id="main-faq">
      <div id="faq-header">
        <div id="faq-header__left">
          <div id="faq-header__welcome">FAQs</div>
          <div id="faq-header__greeting-msg">
            Everything you need to know about Partner Portal.
          </div>
        </div>
      </div>
      <div id="faq-layout">
        {renderColumn(firstColumnFaqs)}
        {renderColumn(secondColumnFaqs)}
      </div>
    </div>
  );
};

export default Faq;

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import store, { RootState } from '../store';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';

import './Earnings.pcss';
import Modal from '../components/Modal';
import { PubPicker } from '../components/PubPicker';
import { Card } from '../components/Card';
import { DatePicker } from '../components/DatePicker';
import { ConfirmSave } from '../components/ConfirmSave';
import { DataTablePagination } from '../components/DataTablePagination';
import { EditReportForm } from '../components/EditReportForm';
import { confirmingSchedule, creatingSchedule } from '../store/scheduleModals';
import { downloadCSV } from '../utils';
import { sendEvent } from '../utils/analytics';
import { ReportCategory, ReportGrouping } from '../types';
import { growl } from '../store/growl';
import { toast } from '../store/toast';
import { fetchEarnings } from '../store/earnings';

import EarningsOverTimeGraph from '../components/EarningsOverTimeGraph';
import EarningsBreakdownChart from '../components/EarningsBreakdownChart';

export const Earnings: () => React.JSX.Element = () => {
  const dispatch = useDispatch<typeof store.dispatch>();

  // Subscribe to url params
  const [searchParams] = useSearchParams();
  const fromDateInParams = searchParams.get('from');
  const toDateInParams = searchParams.get('to');

  // Pub and date range needed on every page
  const user = useSelector((state: RootState) => state.authenticatedUser);
  const { selectedPubs, availablePubs } = useSelector(
    (state: RootState) => state.pubs
  );
  const storeDates = useSelector((state: RootState) => state.dateRange);

  // Earnings data
  const earningsData = useSelector((state: RootState) => state.earnings);
  const earningsByDateWithExplicitZeros = useMemo(() => {
    const earningsObjHashedByDates = earningsData.aggregatedByDate.reduce(
      (acc, earning) => {
        acc[earning.date] = earning.earnings;
        return acc;
      },
      {} as Record<string, number>
    );
    let current = dayjs(storeDates.from);
    const end = dayjs(storeDates.to);
    const earningsByDateWithExplicitZeros = [];

    while (!current.isAfter(end)) {
      const dateAsString = current.format('YYYY-MM-DD');
      const currentEarnings = earningsObjHashedByDates[dateAsString];
      earningsByDateWithExplicitZeros.push({
        date: current.format('YYYY-MM-DD'),
        earnings: currentEarnings ?? 0,
      });
      current = current.add(1, 'day');
    }
    return earningsByDateWithExplicitZeros;
  }, [earningsData.aggregatedByDate, storeDates.from, storeDates.to]);

  // Feature flags
  //   n/a

  // Schedule Reports
  const [grouping, setGrouping] = useState<ReportGrouping>('by_date');
  const [category, setCategory] = useState<ReportCategory>('earnings');
  const currentlyConfirmingSchedule = useSelector(
    (state: RootState) => state.confirmingSchedule
  );
  const currentlyCreatingSchedule = useSelector(
    (state: RootState) => state.creatingSchedule
  );

  // Download Reports
  const [isDownloading, setIsDownloading] = useState(false);
  const [loadingNewEarningsData, setLoadingNewEarningsData] = useState(true);

  useEffect(() => {
    if (
      fromDateInParams === storeDates.from &&
      toDateInParams === storeDates.to &&
      availablePubs !== null
    ) {
      try {
        const limit = dayjs(storeDates.to).diff(storeDates.from, 'days') + 1;
        setLoadingNewEarningsData(true);
        dispatch(fetchEarnings({ limit: limit })).then(() => {
          setLoadingNewEarningsData(false);
        });
      } catch (error) {
        console.error('Earnings data fetch error.', error);
      }
    }
  }, [
    dispatch,
    storeDates.from,
    storeDates.to,
    fromDateInParams,
    toDateInParams,
    availablePubs,
    selectedPubs,
  ]);

  const download = async (
    path: string,
    fileNamePrefix: string,
    reportStyle: 'by-day' | 'by-placement' = 'by-day'
  ) => {
    setIsDownloading(true);
    dispatch(toast('Downloading your report, this may take some time.'));
    try {
      if (storeDates.from === null || storeDates.to === null) {
        throw new Error('Date range in store is unexpectedly null.');
      }
      await downloadCSV({
        path,
        fileNamePrefix,
        from: storeDates.from,
        to: storeDates.to,
        pubs: selectedPubs,
        reportStyle: reportStyle,
      });
      sendEvent({
        user: user,
        publishers: availablePubs,
        name: 'portal_report_download',
        start_date: storeDates.from,
        end_date: storeDates.to,
        current_page: 'reports_earnings',
        report_name: fileNamePrefix,
      });
    } catch (error) {
      dispatch(
        growl(
          'The data was too large to be downloaded directly. Please use the “Schedule Reports” button instead.'
        )
      );
      console.error('Revenue report download error.', error);
    }
    setIsDownloading(false);
    dispatch(toast(null));
  };

  return (
    <>
      <div id="earnings-dashboard">
        {/* header */}
        <div id="earnings-header">
          <div id="earnings-header__left">
            <div id="earnings-header__title">
              Earnings
              <div className="w-spacer" />
            </div>

            <div id="earnings-header__subtitle">
              View your earnings over time.
            </div>
          </div>
          <div id="revenue-header__right">
            <PubPicker />
            <div className="w-spacer" />
            <DatePicker />
          </div>
        </div>
        {/* 1. Charts */}
        <div
          id="earnings-section0"
          className="earnings-section"
          style={{ opacity: loadingNewEarningsData ? 0.7 : 1 }}
        >
          <Card>
            {loadingNewEarningsData && <div className="loading-overlay"></div>}
            <Card.Header>
              <Card.TitleLarge>Earnings Over Time</Card.TitleLarge>
            </Card.Header>
            <Card.Body>
              <div style={{ width: '100%', height: '225px' }}>
                <EarningsOverTimeGraph data={earningsByDateWithExplicitZeros} />
              </div>
            </Card.Body>
            <Card.Header>
              <Card.TitleLarge>Earnings Breakdown by Placement</Card.TitleLarge>
            </Card.Header>
            <Card.Body>
              <EarningsBreakdownChart
                data={earningsData.aggregatedBySubcategory}
              />
            </Card.Body>
          </Card>
        </div>
        {/* 2. By Date */}
        <div id="earnings-section1">
          <DataTablePagination
            data={earningsData.aggregatedByDate}
            title="Earnings by Day"
            view="earningsByDay"
            isDownloading={isDownloading}
            onDownload={() =>
              download(
                '/reporting/earnings',
                'Chicory_earnings_by_day',
                'by-day'
              )
            }
            onSchedule={() => {
              setCategory('earnings');
              setGrouping('by_date');
              dispatch(creatingSchedule(true));
            }}
          />
        </div>
        {/* 3. Per Product Type */}
        <div id="earnings-section2">
          <DataTablePagination
            data={earningsData.aggregatedBySubcategory}
            title="Earnings per Product Type"
            view="earningsByPlacement"
            isDownloading={isDownloading}
            onDownload={() =>
              download(
                '/reporting/earnings',
                'Chicory_earnings_by_placement',
                'by-placement'
              )
            }
            onSchedule={() => {
              setCategory('earnings');
              setGrouping('by_placement');
              dispatch(creatingSchedule(true));
            }}
          />
        </div>
      </div>
      {/* Create schedule */}
      {currentlyCreatingSchedule && (
        <Modal
          onClose={() => {
            dispatch(creatingSchedule(false));
          }}
        >
          <EditReportForm
            createOrUpdateMode={'create'}
            category={category}
            grouping={grouping}
          />
        </Modal>
      )}
      {/* Confirm saved */}
      {currentlyConfirmingSchedule && (
        <Modal
          onClose={() => {
            dispatch(confirmingSchedule(null));
          }}
        >
          <ConfirmSave currentSchedule={currentlyConfirmingSchedule} />
        </Modal>
      )}
    </>
  );
};

import dayjs from 'dayjs';
import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import DateAndCurrencyValueTooltip from './DateAndCurrencyValueTooltip';

type GraphEntry = {
  date: string;
  earnings: number;
};

const dateTickFormatter = (tick: number) => dayjs(tick).format('MM/DD/YY');

export const EarningsOverTimeGraph = ({ data }: { data: GraphEntry[] }) => {
  if (data.length === 0) {
    return (
      <div style={{ background: 'rgba(0, 0, 0, 0.1)', height: '100%' }}></div>
    );
  }

  return (
    <ResponsiveContainer>
      <AreaChart margin={{ left: -15 }} data={data}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="20%" stopColor="#6c239a" stopOpacity={0.1} />
            <stop offset="95%" stopColor="#6c239a" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="date"
          tickFormatter={dateTickFormatter}
          style={{ fontWeight: 400, fontSize: '0.75rem' }}
        />
        <YAxis
          axisLine={false}
          dataKey="earnings"
          domain={[0, 'auto']}
          style={{ fontWeight: 200, fontSize: '0.8rem' }}
          padding={{ bottom: 2 }}
          tickMargin={0}
          tickLine={false}
          tickFormatter={(amount) => {
            if (amount === 0) return '';
            if (amount < 1000) return `$${amount}`;
            return `$${amount / 1000}k`;
          }}
        />
        <CartesianGrid
          strokeDasharray="15 0"
          strokeWidth={0.2}
          stroke={'#000'}
          strokeOpacity={'100%'}
          vertical={false}
        />
        <Area
          type="monotone"
          dataKey="earnings"
          stroke="#6c239a"
          strokeWidth={2}
          fillOpacity={1}
          fill="url(#colorUv)"
        />
        <Tooltip content={<DateAndCurrencyValueTooltip />} />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default EarningsOverTimeGraph;

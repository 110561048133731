import { createReducer, createAsyncThunk } from '@reduxjs/toolkit';

import { RaByIngredient } from '../types';
import { RootState } from '.';
import { chicoryFetcher, fetchUrlFormatter, getValidPubs } from '../utils';

const initialRaByIngredient: RaByIngredient = [];

export const fetchRaByIngredient = createAsyncThunk<
  RaByIngredient,
  number,
  { state: RootState }
>('FETCH_RA_BY_INGREDIENT', async (limit, { getState }) => {
  const state = getState();

  const pubs = getValidPubs({
    selectedPubs: state.pubs.selectedPubs,
    availablePubs: state.pubs.availablePubs,
  });
  const path = '/reporting/ra/ingredient';

  try {
    if (state.dateRange.from === null || state.dateRange.to === null) {
      throw new Error('Date range in store is unexpectedly null.');
    }
    const data = await chicoryFetcher(
      fetchUrlFormatter({
        path: path,
        from: state.dateRange.from,
        to: state.dateRange.to,
        pubs: pubs,
        limit: limit,
      })
    ).then((response) => response.json());
    return data;
  } catch (error) {
    console.error('fetchRaByIngredient error.', error);
  }
});

export const raByIngredientReducer = createReducer<RaByIngredient>(
  initialRaByIngredient,
  (builder) => {
    builder.addCase(fetchRaByIngredient.fulfilled, (state, action) => {
      return action.payload;
    });
  }
);

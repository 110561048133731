import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';

import './Dashboard.pcss';
import store, { RootState } from '../store';
import { fetchAdsByPlacement } from '../store/adsByPlacement';
import { PieChart } from '../components/PieChart';
import { Card } from '../components/Card';
import { Tiles } from '../components/Tiles';
import { Icon } from '../components/icons';
import { RaMetrics } from '../components/RaMetrics';
import DataTable from '../components/DataTable';
import { DatePicker } from '../components/DatePicker';
import { fetchAdsHistorical } from '../store/adsByHistorical';
import { fetchAdsByRecipe } from '../store/adsByRecipe';
import { PubPicker } from '../components/PubPicker';
import RevenueLineChart from '../components/RevenueLineChart';
import { fetchRaOverview } from '../store/raOverview';
import dayjs from 'dayjs';
import { Tooltip } from '../components/Tooltip';
import {
  formatAsUsd,
  formatWithMorK,
  formatAsPercentNoDecimals,
} from '../utils';

const Dashboard: () => React.JSX.Element = () => {
  const dispatch = useDispatch<typeof store.dispatch>();

  const user = useSelector((state: RootState) => state.authenticatedUser);
  const storeDates = useSelector((state: RootState) => state.dateRange);
  const storeAdsByPlacement = useSelector(
    (state: RootState) => state.adsByPlacement
  );
  const storeAdsByHistorical = useSelector(
    (state: RootState) => state.adsByHistorical
  );
  const storeAdsByRecipe = useSelector((state: RootState) => state.adsByRecipe);
  const { selectedPubs, availablePubs } = useSelector(
    (state: RootState) => state.pubs
  );

  const [searchParams] = useSearchParams();
  const fromDateInParams = searchParams.get('from');
  const toDateInParams = searchParams.get('to');

  // Fetch data after dates and pubs are set
  useEffect(() => {
    if (
      fromDateInParams === storeDates.from &&
      toDateInParams === storeDates.to &&
      availablePubs !== null
    ) {
      const fetchLimit = dayjs(storeDates.to).diff(storeDates.from, 'days') + 1;

      dispatch(fetchAdsHistorical(fetchLimit));
      dispatch(fetchAdsByPlacement(10));
      dispatch(fetchAdsByRecipe(10));
      dispatch(fetchRaOverview());
    }
  }, [
    dispatch,
    storeDates.from,
    storeDates.to,
    fromDateInParams,
    toDateInParams,
    availablePubs,
    selectedPubs,
  ]);

  const [showTooltip, setShowTooltip] = useState(false);
  // Calculate year over year numbers to display % change
  const [impressionsThisPeriod, setImpressionsThisPeriod] = useState(0);
  const [impressionsPercentMsg, setImpressionsPercentMsg] =
    useState<JSX.Element | null>(null);
  const [revThisPeriod, setRevThisPeriod] = useState(0);
  const [revPercentMsg, setRevPercentMsg] = useState<JSX.Element | null>(null);

  // Feature flags
  const { showImpressions } = useSelector(
    (state: RootState) => state.featureFlags
  );

  // Calculate revenue and impression YoY comparisons
  useEffect(() => {
    if (storeAdsByHistorical) {
      const sums = storeAdsByHistorical.reduce(
        (acc, data) => {
          return {
            impThisYear: acc.impThisYear + (data.impressions || 0),
            impLastYear: acc.impLastYear + (data.impressionsOneYearAgo || 0),
            revThisYear: acc.revThisYear + +data.revenue, //string > number
            revLastYear: acc.revLastYear + +data.revenueOneYearAgo,
          };
        },
        { impThisYear: 0, impLastYear: 0, revThisYear: 0, revLastYear: 0 }
      );

      setImpressionsThisPeriod(sums.impThisYear);
      setRevThisPeriod(sums.revThisYear);

      // Impressions YoY calculation
      // Data starts 2022-11-01, so don't calculate last year's sum if 'from' is before 2023-11-01
      if (
        dayjs(storeDates.from).isAfter('2023-10-31', 'day') &&
        sums.impLastYear > 0
      ) {
        const change = (sums.impThisYear - sums.impLastYear) / sums.impLastYear;
        const formattedChange = formatAsPercentNoDecimals(Math.abs(change));
        setImpressionsPercentMsg(
          change > 0 ? (
            <div
              id="dashboard__ad-impressions--percent"
              className={`percentage-change ${
                typeof change === 'number' && change < 0 ? 'red' : 'green'
              }`}
            >
              <Icon
                name="arrow-left"
                className="rotate-90 stroke-green stroke-thin"
                style={{ marginRight: '3px' }}
              />
              {formattedChange} up YoY
            </div>
          ) : (
            <div
              id="dashboard__ad-impressions--percent"
              className={`percentage-change ${
                typeof change === 'number' && change < 0 ? 'red' : 'green'
              }`}
            >
              <Icon
                name="arrow-left"
                className="rotate-270 stroke-red stroke-thin"
                style={{ marginRight: '3px' }}
              />
              {formattedChange} down YoY
            </div>
          )
        );
      } else {
        setImpressionsPercentMsg(null);
      }

      // Revenue YoY calculation
      // Data starts 2022-11-01, YoY nums available starting 2023-11-01
      if (
        dayjs(storeDates.from).isAfter('2023-10-31', 'day') &&
        sums.revLastYear > 0
      ) {
        const change = (sums.revThisYear - sums.revLastYear) / sums.revLastYear;
        const formattedChange = formatAsPercentNoDecimals(Math.abs(change));

        setRevPercentMsg(
          change > 0 ? (
            <Card.TitleSmall
              style={{
                marginLeft: '10px',
                color: '#57bb37',
                fontWeight: '100',
              }}
            >
              <Icon
                name="arrow-left"
                className="rotate-90 stroke-green stroke-thin"
                style={{ marginRight: '3px' }}
              />
              {formattedChange} up YoY
            </Card.TitleSmall>
          ) : (
            <Card.TitleSmall
              style={{
                marginLeft: '10px',
                color: '#d92d20',
                fontWeight: '100',
              }}
            >
              <Icon
                name="arrow-left"
                className="rotate-270 stroke-red stroke-thin"
                style={{ marginRight: '3px' }}
              />
              {formattedChange} down YoY
            </Card.TitleSmall>
          )
        );
      } else {
        setRevPercentMsg(null);
      }
    }
  }, [storeAdsByHistorical, storeDates.from]);

  return (
    <div id="dashboard">
      {/* header */}
      {/* header */}
      <div id="dashboard-header">
        <div id="dashboard-header__left">
          <div id="dashboard-header__welcome">{`Welcome back, ${
            user?.name?.split(' ')[0]
          }`}</div>
          <div id="dashboard-header__greeting-msg">
            The stage is set for another awesome day!
          </div>
        </div>
        <div id="dashboard-header__right">
          <PubPicker />
          <div className="w-spacer" />
          <DatePicker />
        </div>
      </div>
      {/* section1 */}
      {/* section1 */}
      <div
        style={{ display: 'flex', alignItems: 'center', width: '200px' }}
      ></div>
      <div id="dashboard-section1">
        <div id="dashboard-section1__left">
          <Card>
            <Card.Header>
              <Card.HeaderLeft>
                <Card.TitleLarge>Revenue</Card.TitleLarge>
                <div className="w-spacer" />
                <div className="w-spacer" />
                <Card.TitleSmall style={{ color: '#067647' }}>
                  {storeAdsByHistorical && formatAsUsd(revThisPeriod)}
                </Card.TitleSmall>
                {revPercentMsg}
              </Card.HeaderLeft>
              <Card.HeaderRight>
                <Tiles>
                  <Link
                    to={{
                      pathname: '/reports/revenue',
                      search: window.location.search,
                    }}
                  >
                    <Tiles.Button version={'tile-wrapper__white'}>
                      View full report
                    </Tiles.Button>
                  </Link>
                </Tiles>
              </Card.HeaderRight>
            </Card.Header>
            <Card.Body style={{ height: '100%' }}>
              <RevenueLineChart
                data={storeAdsByHistorical}
                storeDates={storeDates}
              />
            </Card.Body>
          </Card>
        </div>
        <div id="dashboard-section1__right">
          <div id="dashboard-section1__right__top">
            {showImpressions ? (
              <Card>
                <Card.Header>
                  <Card.TitleSmall>Ad Impressions</Card.TitleSmall>
                </Card.Header>
                <Card.Body style={{ height: '40%' }}>
                  <div id="dashboard__ad-impressions--value">
                    {formatWithMorK(impressionsThisPeriod)}
                  </div>
                  {impressionsPercentMsg}
                </Card.Body>
              </Card>
            ) : (
              <Card>
                <Card.Header>
                  <Card.TitleSmall>Top Recipe</Card.TitleSmall>
                </Card.Header>
                <Card.Body style={{ height: '40%', marginBottom: '20px' }}>
                  {storeAdsByRecipe[0]?.revenue && (
                    <div id="dashboard__top-recipe">
                      <a
                        id="dashboard__top-recipe-link"
                        href={'https://' + storeAdsByRecipe[0]?.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div id="dashboard__top-recipe-link-inside">
                          <span id="dashboard__top-recipe-name">
                            {storeAdsByRecipe[0]?.name}
                          </span>
                        </div>
                      </a>
                      <div id="dashboard__top-recipe-revenue">
                        {formatAsUsd(+storeAdsByRecipe[0]?.revenue)}
                      </div>
                    </div>
                  )}
                </Card.Body>
              </Card>
            )}
          </div>
          <div id="dashboard-section1__right__bottom">
            <Card>
              <Card.Header>
                <Card.TitleSmall>Revenue by Placement</Card.TitleSmall>
              </Card.Header>
              <Card.Body style={{ height: '100%' }}>
                <PieChart data={storeAdsByPlacement} />
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
      {/* section2 */}
      {/* section2 */}
      <div id="dashboard-section2">
        <Card>
          <Card.Header>
            <Card.HeaderLeft style={{ position: 'relative' }}>
              <Card.TitleLarge style={{ marginRight: '5px' }}>
                Recipe Activation Metrics
              </Card.TitleLarge>
              <Icon
                name="help-circle"
                style={{ marginBottom: '2px', cursor: 'pointer' }}
                onClick={() => setShowTooltip((prev) => !prev)}
              />
              {showTooltip && (
                <Tooltip
                  location="raMetrics"
                  onClick={() => {
                    setShowTooltip((prev) => !prev);
                  }}
                />
              )}
            </Card.HeaderLeft>
          </Card.Header>
          <Card.Body>
            <RaMetrics />
          </Card.Body>
        </Card>
      </div>
      {/* section3 */}
      {/* section3 */}
      <div id="dashboard-section3">
        <Card>
          <Card.Header>
            <Card.HeaderLeft>
              <Card.TitleLarge>Top 10 Recipes by Revenue</Card.TitleLarge>
            </Card.HeaderLeft>
            <Card.HeaderRight>
              <Tiles>
                <Link
                  to={{
                    pathname: '/reports/revenue',
                    search: window.location.search,
                  }}
                >
                  <Tiles.Button version={'tile-wrapper__white'}>
                    View full report
                  </Tiles.Button>
                </Link>
              </Tiles>
            </Card.HeaderRight>
          </Card.Header>
          <Card.TableBody>
            <DataTable view="recipes" data={storeAdsByRecipe.slice(0, 10)} />
          </Card.TableBody>
          <Card.Header></Card.Header>
        </Card>
      </div>
    </div>
  );
};

export default Dashboard;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#manage-reports {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#manage-reports-header {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  min-height: 70px;
}

#manage-reports-header__welcome {
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 5px;
  }

#manage-reports-header__greeting-msg {
    font-size: 18px;
    color: #475467;
    font-weight: 100;
    margin-bottom: 5px;
  }

#manage-reports-header__right {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

#manage-reports-section1 {
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  min-height: 300px;
}
#manage-reports-section2 {
  min-height: 350px;
  width: 100%;
  margin-bottom: 20px
}
#manage-reports-section2 > div {
    min-height: 350px;
  }
#manage-reports-section3 {
  width: 100%;
  margin-bottom: 20px;
}

#manage-reports__ad-impressions__value {
  font-size: 30px;
  font-weight: 400;
  color: #475467;
  padding-right: 5px;
}
#manage-reports__ad-impressions__percent {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManageReports.pcss"],"names":[],"mappings":"AAKA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,mBAA2B;EAC3B,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;EACf,WAAW;EACX,gBAAgB;AAiBlB;;AAhBE;IACE,eAAe;IACf,gBAAgB;IAChB,kBAAkB;EACpB;;AACA;IACE,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,kBAAkB;EACpB;;AACA;IACE,aAAa;IACb,mBAAmB;IACnB,eAAe;EACjB;;AAGF;EACE,aAAa;EACb,mBAA2B;EAC3B,WAAW;EACX,iBAAiB;AACnB;AACA;EACE,iBAAiB;EACjB,WAAW;EACX;AAKF;AAHE;IACE,iBAAiB;EACnB;AAEF;EACE,WAAW;EACX,mBAA2B;AAC7B;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB","sourcesContent":["$verticalGap: 20px;\n$mediascreen: 990px;\n$horizontalGap: 15px;\n$gridGap: 12px;\n\n#manage-reports {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}\n\n#manage-reports-header {\n  margin-bottom: $verticalGap;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  flex-wrap: wrap;\n  width: 100%;\n  min-height: 70px;\n  &__welcome {\n    font-size: 30px;\n    font-weight: 400;\n    margin-bottom: 5px;\n  }\n  &__greeting-msg {\n    font-size: 18px;\n    color: #475467;\n    font-weight: 100;\n    margin-bottom: 5px;\n  }\n  &__right {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n  }\n}\n\n#manage-reports-section1 {\n  display: flex;\n  margin-bottom: $verticalGap;\n  width: 100%;\n  min-height: 300px;\n}\n#manage-reports-section2 {\n  min-height: 350px;\n  width: 100%;\n  margin-bottom: $verticalGap;\n\n  & > div {\n    min-height: 350px;\n  }\n}\n#manage-reports-section3 {\n  width: 100%;\n  margin-bottom: $verticalGap;\n}\n\n#manage-reports__ad-impressions__value {\n  font-size: 30px;\n  font-weight: 400;\n  color: #475467;\n  padding-right: 5px;\n}\n#manage-reports__ad-impressions__percent {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';

import './Growl.pcss';
import { Card } from '../components/Card';
import { Icon } from './icons';
import { Tiles } from './Tiles';
import { growl } from '../store/growl';

export const Growl = () => {
  const dispatch = useDispatch<AppDispatch>();
  const message = useSelector((state: RootState) => state.growl);

  return (
    <Card id="growl">
      <Card.Header>
        <Card.HeaderLeft>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50%',
              boxShadow: '0 0 10px lightgreen',
            }}
          >
            <Icon
              name="check-circle"
              style={{ height: '25px', width: '25px' }}
            />
          </div>
        </Card.HeaderLeft>
        <Card.HeaderRight>
          <Icon
            name="x"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              dispatch(growl(null));
            }}
          />
        </Card.HeaderRight>
      </Card.Header>
      <Card.Body
        style={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          height: '80px',
          justifyContent: 'space-evenly',
        }}
      >
        <Card.TitleSmall>Uh-oh!</Card.TitleSmall>
        <Card.TitleSmall style={{ fontWeight: '100', fontSize: '16px' }}>
          {message}
        </Card.TitleSmall>
      </Card.Body>
      <Card.Header>
        <Tiles
          style={{
            width: '100%',
            height: '45px',
            border: 'none',
            justifyContent: 'space-between',
          }}
        >
          <Tiles.Button
            version="darkPurple"
            style={{
              width: '100%',
              fontSize: '16px',
            }}
            onClick={() => {
              dispatch(growl(null));
            }}
          >
            Ok
          </Tiles.Button>
        </Tiles>
      </Card.Header>
    </Card>
  );
};

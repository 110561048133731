// overriding https://github.com/hypeserver/react-date-range/blob/master/src/defaultRanges.js

import dayjs from 'dayjs';

import { createStaticRanges, InputRange, Range } from 'react-date-range';

export const defineds = () => {
  const now = dayjs();
  return {
    minDate: new Date(2022, 10, 1),
    yesterday: now.subtract(1, 'day').toDate(),
    startOfToday: now.startOf('day').toDate(),
    endOfToday: now.endOf('day').toDate(),
    startOfYesterday: now.subtract(1, 'day').startOf('day').toDate(),
    endOfYesterday: now.subtract(1, 'day').endOf('day').toDate(),
    startOfWeek: now.startOf('week').toDate(),
    endOfWeek: now.endOf('week').toDate(),
    startOfLastWeek: now.subtract(1, 'week').startOf('week').toDate(),
    endOfLastWeek: now.subtract(1, 'week').endOf('week').toDate(),
    startOfMonth: now.startOf('month').toDate(),
    endOfMonth: now.endOf('month').toDate(),
    startOfLastMonth: now.subtract(1, 'month').startOf('month').toDate(),
    endOfLastMonth: now.subtract(1, 'month').endOf('month').toDate(),
  };
};

// Remove the "Today" option, use days up to yesterday
export const newStaticRanges = () => {
  const dates = defineds();
  return createStaticRanges([
    {
      label: 'Yesterday',
      range: () => ({
        startDate: dates.startOfYesterday,
        endDate: dates.endOfYesterday,
      }),
    },
    {
      label: 'This Week',
      range: () => ({
        startDate: dates.startOfWeek,
        endDate: dates.endOfYesterday, // End at yesterday
      }),
    },
    {
      label: 'Last Week',
      range: () => ({
        startDate: dates.startOfLastWeek,
        endDate: dates.endOfLastWeek,
      }),
    },
    {
      label: 'This Month',
      range: () => ({
        startDate: dates.startOfMonth,
        endDate: dates.endOfYesterday, // End at yesterday
      }),
    },
    {
      label: 'Last Month',
      range: () => ({
        startDate: dates.startOfLastMonth,
        endDate: dates.endOfLastMonth,
      }),
    },
  ]);
};

// Days up to yesterday
export const newInputRanges = (): InputRange[] => [
  {
    label: 'days up to yesterday',
    range: (value: number) => {
      const { startOfToday, endOfYesterday } = defineds();
      return {
        startDate: dayjs(startOfToday)
          .subtract(Math.max(Number(value), 1), 'day')
          .toDate(),
        endDate: endOfYesterday,
      };
    },
    getCurrentValue: (range: Range) => {
      const { endOfYesterday, endOfToday } = defineds();

      if (!dayjs(range.endDate).isSame(endOfYesterday, 'day')) return '-';
      if (!range.startDate) return '∞';
      return dayjs(endOfToday).diff(dayjs(range.startDate), 'day');
    },
  },
];

import React, { useEffect, useState, useMemo } from 'react';

import './DataTablePagination.pcss';
import { Card } from './Card';
import DataTable from './DataTable';
import { Searchbar } from './Searchbar';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import {
  fetchCurrentPartners,
  formatPartnerUserForDataTable,
} from '../store/admin/actions';
import { TablePaginator } from './TablePaginator';
import { partnerSearchFilter } from '../utils';
import { TextBubble } from './TextBubble';

const ROWS_PER_PAGE = 15;

export function AdminApprovedUsers() {
  const dispatch: AppDispatch = useDispatch();

  const approvedPartners = useSelector((state: RootState) => {
    return state.admin.partners;
  });

  const [filteredPartners, setFilteredPartners] = useState<
    typeof approvedPartners | null
  >(null);
  const [currentlyViewingUsers, setCurrentlyViewingUsers] = useState<
    typeof approvedPartners
  >([]);
  const [page, setPage] = useState(1);

  // Search functionality
  const [searchText, setSearchText] = useState('');

  const currentUsersForDataDisplay = useMemo(() => {
    return currentlyViewingUsers.map(formatPartnerUserForDataTable);
  }, [currentlyViewingUsers]);

  useEffect(() => {
    dispatch(fetchCurrentPartners());
  }, [dispatch]);

  // Handle page change or text entered for search
  useEffect(() => {
    const start = (page - 1) * ROWS_PER_PAGE;
    const end = start + ROWS_PER_PAGE;

    if (searchText) {
      const results = approvedPartners.filter((partner) => {
        return partnerSearchFilter(partner, searchText);
      });
      const view = results.slice(start, end);
      setFilteredPartners(results);
      setCurrentlyViewingUsers(view);
    } else {
      const view = approvedPartners.slice(start, end);
      setFilteredPartners(null);
      setCurrentlyViewingUsers(view);
    }
  }, [page, approvedPartners, searchText]);

  useEffect(() => {
    setPage(1);
  }, [searchText]);

  return (
    <div id="admin-current-users-table-display">
      <Card>
        <Card.Header>
          <Card.HeaderLeft>
            <Card.TitleSmall>{'Current Users'}</Card.TitleSmall>
            <TextBubble color="green">{`${approvedPartners.length} users`}</TextBubble>
          </Card.HeaderLeft>
          <Card.HeaderRight>
            {/* Search bar */}
            {/* Search bar */}
            <Searchbar setSearchText={setSearchText} searchText={searchText} />
          </Card.HeaderRight>
        </Card.Header>
        <Card.TableBody>
          <DataTable view={'currentUsers'} data={currentUsersForDataDisplay} />
        </Card.TableBody>
        <Card.Header style={{ justifyContent: 'flex-end' }}>
          <Card.HeaderRight>
            <TablePaginator
              amountOfEntries={
                filteredPartners
                  ? filteredPartners.length
                  : approvedPartners.length
              }
              currentPage={page}
              amountPerPage={ROWS_PER_PAGE}
              setPage={setPage}
            />
          </Card.HeaderRight>
        </Card.Header>
      </Card>
    </div>
  );
}

import { configureStore } from '@reduxjs/toolkit';
import { userReducer } from './userSlice';
import { dateReducer } from './dateSlice';
import { pubsReducer } from './publisherSlice';
import { adsByPlacementReducer } from './adsByPlacement';
import { adsByHistoricalReducer } from './adsByHistorical';
import { adsByRecipeReducer } from './adsByRecipe';
import { adsByIngredientReducer } from './adsByIngredient';
import { adsByPublisherReducer } from './adsByPub';
import { raByIngredientReducer } from './raByIngredient';
import { raByRecipeReducer } from './raByRecipe';
import { raByPublisherReducer } from './raByPublisher';
import { raByRetailerReducer } from './raByRetailer';
import { scheduleDataReducer } from './scheduleData';
import {
  deletingScheduleReducer,
  editingScheduledReportReducer,
  confirmingScheduleReducer,
  creatingScheduleReducer,
} from './scheduleModals';
import { RAOverviewReducer } from './raOverview';
import { growlReducer } from './growl';
import { toastReducer } from './toast';
import { featureFlagsReducer } from './featureFlags';
import { earningsReducer } from './earnings';

// Admin
import adminReducer from './admin/reducer';

const store = configureStore({
  reducer: {
    admin: adminReducer,
    authenticatedUser: userReducer,
    dateRange: dateReducer,
    pubs: pubsReducer,
    adsByPlacement: adsByPlacementReducer,
    adsByHistorical: adsByHistoricalReducer,
    adsByRecipe: adsByRecipeReducer,
    adsByIngredient: adsByIngredientReducer,
    adsByPublisher: adsByPublisherReducer,
    raOverview: RAOverviewReducer,
    raByIngredient: raByIngredientReducer,
    raByRecipe: raByRecipeReducer,
    raByPublisher: raByPublisherReducer,
    raByRetailer: raByRetailerReducer,
    scheduledReports: scheduleDataReducer,
    creatingSchedule: creatingScheduleReducer,
    editingSchedule: editingScheduledReportReducer,
    deletingSchedule: deletingScheduleReducer,
    confirmingSchedule: confirmingScheduleReducer,
    growl: growlReducer,
    toast: toastReducer,
    featureFlags: featureFlagsReducer,
    earnings: earningsReducer,
  },
  // Config middleware to allow function passing to store
  // We then pass the React useSearchParams setter to update URL without reloading page.
  middleware: (getDefaultMiddleWare) => {
    return getDefaultMiddleWare({
      serializableCheck: {
        // ignoredActions: ['FETCH_AVAILABLE_PUBS'],
        ignoredActionPaths: ['meta.arg', 'payload.setSearchParams'],
      },
    });
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// @@redux/init store creation action, this is the default state value
